<app-header title="About program"></app-header>
<section class="termsContainer pb70 px30">
  <h1 class="fs20 font-darkblue mt40">TERMS OF USE</h1>
  <p>Effective Date of these Online Terms of Use: 5/17/2013</p>
  <ul class="disc-inside px-0 my-4 pb70">
    <li>
      These Online Terms of Use govern your access to websites controlled by
      Abbott Laboratories, including its subsidiaries and affiliates (together
      referred to as "Abbott"), which link to these Online Terms of Use
      (together referred to as "Abbott websites"). These Online Terms of Use do
      not apply to Abbott websites that do not link to these Online Terms of
      Use, to residents of the United States, or to third-party websites to
      which Abbott websites may link. Your use of Abbott websites is subject to
      these Online Terms of Use and the Privacy Policy.
    </li>
    <li>
      Without prejudice to your rights under applicable law, Abbott reserves the
      right to amend these Online Terms of Use to reflect technological
      advancements, legal and regulatory changes and good business practices. If
      Abbott changes these Online Terms of Use, an updated version of these
      Online Terms of Use will reflect those changes and we will notify you of
      such changes by updating the effective date at the top of these Online
      Terms of Use. By accessing or using the Abbott websites, you agree that
      you have read, understand and agree to be bound to the current version of
      these Online Terms of Use which you may view when accessing the Abbott
      websites. If you disagree with these Online Terms of Use or are
      dissatisfied with the Abbott websites, your sole and exclusive remedy is
      to discontinue using this website.
    </li>
  </ul>
  <div class="arrow-up">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>

<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
