import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure-g-scanner',
  templateUrl: './ensure-g-scanner.component.html',
  styleUrls: ['./ensure-g-scanner.component.scss']
})
export class EnsureGScannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
