
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from '../../../../environments/environment';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { v4 as uuidv4 } from "uuid";
import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-change-mobile',
  templateUrl: './change-mobile.component.html',
  styleUrls: ['./change-mobile.component.scss']
})
export class ChangeMobileComponent implements OnInit {

  submittedNumberChangedForm: boolean = false;
  showEyeCloseIcon: boolean = true;
  showPasswordText: boolean = false;
  showEyeOpenIcon: boolean = false;
  invalidPassword: boolean;
  mobileNumber: any;
  password: any;
  invalidOtp: boolean = false;
  otp: string;
  otpColor: string;
  showCurrentPasswordText: boolean = false;
  showEyeCloseCurrentIcon: boolean = true;
  showNewPasswordText: boolean = false;
  showConfirmPasswordtext: any = false;

  showEyeOpenCurrentIcon: any = false;
  timeLeft: any;
  showSuccessScreen: boolean = false;
  showMobileSection: boolean = true;
  showPasswordSection: boolean = false;
  showOtpSection: boolean = false;
  submittedPasswordForm: boolean = false
  isPasswordPatternCorrect: boolean = false;
  showEyeCloseIconConfirm: boolean = true;
  showEyeOpenIconConfirm: boolean = false;
  timeUp: boolean = false;
  changeNumberForm: FormGroup;
  changePasswordForm: FormGroup;
  otpForm: FormGroup;
  submittedOTPForm: boolean = false;
  newMobileNumber: any;
  changePasswordValues: any;
  passwordError: boolean = false;
  beginTimer: boolean = false;
  sessionId: any;
  passwordNotMaching: boolean = false;
  strength: number;
  newToken: any;
  currentPasswordNotMatching: boolean = false;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  showResendOtp: boolean;
  enterOTP: boolean = false;
  otpNotAvailable: boolean = false;
  newSession: any;
  oldMobilePassword: string;


  // //mloginForm= new FormGroup({mobileNumber : new FormControl(),password:new FormControl()
  // })
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.changeNumberForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(`^((\\+63-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`),
        ],
      ]
    })

    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required],

    })
  }



  resendOTP() {
    this.ngOtpInput.setValue('');
    this.timeUp = false;
    this.countdown.restart()
    this.otpColor = "black"
    this.beginTimer = true;
    this.ngOtpInput.setValue("");
    this.countdown.restart()
    this.spinner.show();
    let getMobile = this.newMobileNumber
    let formData = {
      "identifierType": "MOBILE",
      "identifierValue": this.newMobileNumber,
      "deviceId": env.DEVICE_ID,
      "brand": env.BRAND,
      "password": this.oldMobilePassword,
      "confirmPassword": this.oldMobilePassword,
    }

    this.spinner.show();
    let otpGenerateData = {
      mobile: this.newMobileNumber,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      sessionId: this.newSession,
    };
    this.beginTimer = true;
    this.spinner.show();
    this.apiService
      .otpGenerate(otpGenerateData)
      .pipe(first())
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res["status"].code == 200) {
            this.spinner.hide()
            console.log("OTP generated")
          } else if (res["status"].code == 401) {
            this.apiService.logout();
          } else {
            this.apiService.showToaster(res["status"].message);
          }
        },
        (err) => {
          this.spinner.hide();
          console.log(err, "=== OTP gererate error list =======");
          this.bsService.notifyError(err, otpGenerateData);
        }
      );
  }


  onClickShowCurrentPassword() {
    this.showCurrentPasswordText = !this.showCurrentPasswordText;
    this.showEyeCloseCurrentIcon = !this.showEyeCloseCurrentIcon;
    this.showEyeOpenCurrentIcon = !this.showEyeOpenCurrentIcon
  }

  onClickShowNewPassword() {
    this.showNewPasswordText = !this.showNewPasswordText;
    this.showEyeCloseIcon = !this.showEyeCloseIcon;
    this.showEyeOpenIcon = !this.showEyeOpenIcon
  }

  onClickShowConfirmPassword() {
    this.showConfirmPasswordtext = !this.showConfirmPasswordtext
    this.showEyeCloseIconConfirm = !this.showEyeCloseIconConfirm
    this.showEyeOpenIconConfirm = !this.showEyeOpenIconConfirm
  }


  get editPasswordFormVal() {
    return this.changePasswordForm.controls;
  }

  get changeNumberFormVal() {
    return this.changeNumberForm.controls;
  }

  get changePasswordFormVal() {
    return this.changePasswordForm.controls
  }


  onClickEyeCloseIcon() {
    this.showEyeCloseIcon = false;
    this.showPasswordText = true;
    this.showEyeOpenIcon = true;
  }

  onClickEyeOpenIcon() {
    this.showPasswordText = false;
    this.showEyeCloseIcon = true;
    this.showEyeOpenIcon = false;
  }
  onOtpChange(otp) {
    console.log(otp)
    this.invalidOtp = false;
    this.otpNotAvailable = false;
    this.otp = otp;
  }
  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft <= 10000) {
      this.otpColor = 'red'
    }
  }

  onClickLogin() {
    this.router.navigate(['/profile'])
  }

  onSubmitChangeNumberForm() {
    this.submittedNumberChangedForm = true;
    if (this.changeNumberForm.invalid) {
      return;
    } else {
      this.newMobileNumber = this.changeNumberForm.value.mobileNumber
      let newToken = sessionStorage.getItem('authToken')
      this.oldMobilePassword = sessionStorage.getItem('userPassword')
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": this.newMobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "password": this.oldMobilePassword,
        "confirmPassword": this.oldMobilePassword,
      }
      sessionStorage.setItem('newMobileNumber', this.newMobileNumber)
      this.spinner.show();
      this.apiService.tokenGenerate(formData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            if (res['user'].userRegisteredForPassword == true) {
              this.apiService.showToaster('user(number) already registered')
            } else {
              // show OTP screen
              this.newSession = res['user'].sessionId
              let otpGenerateData = {
                "identifierType": "MOBILE",
                "identifierValue": this.newMobileNumber,
                "deviceId": env.DEVICE_ID,
                "brand": env.BRAND,
                "sessionId": this.newSession
              }
              this.spinner.show();
              this.apiService.otpGenerate(otpGenerateData).pipe(first())
                .subscribe(res => {
                  if (res['status'].code == 200) {
                    this.spinner.hide()
                    this.showMobileSection = false
                    this.showOtpSection = true;
                  }
                }, err => {
                  this.spinner.hide()
                  console.log(err)
                  this.bsService.notifyError(err, otpGenerateData);
                })
            }
          }
          else {
            return
          }
        }, err => {
          this.spinner.hide();
          console.log(err)
          this.bsService.notifyError(err, formData);
        })
    }
  }



  onSubmitOtpForm() {
    this.submittedOTPForm = true;
    if (this.otp == undefined || this.otp.length < 6) {
      this.enterOTP = true
      this.otpNotAvailable = true
      return;
    } else {

      // api call
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": this.newMobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.newSession,
        "otp": this.otp
      }
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe((res) => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.spinner.hide()
            sessionStorage.setItem('authToken', res['auth'].token);
            sessionStorage.setItem('authKey', res['auth'].key)

            let getauthkey = sessionStorage.getItem('authKey')
            let newToken = sessionStorage.getItem('authToken')
            let getMobile = this.newMobileNumber


            // update identity request
            if (this.newMobileNumber != null) {
              let mobileNo = sessionStorage.getItem('mobileNo');
              let newMobNumber = JSON.stringify(this.newMobileNumber)
              console.log(newMobNumber)
              let updateMobile = {
                "root": {
                  "customer": [
                    {
                      "identifier": 'mobile',
                      "old_value": '63' + mobileNo,
                      "new_value": '63' + this.newMobileNumber
                    }
                  ]
                }
              }
              newMobNumber = this.newMobileNumber
              let oldMobNumber = mobileNo
              console.log("update mobile: ", updateMobile)
              this.apiService.updateCapCustomerIdentity(updateMobile, sessionStorage.getItem('newMobileNumber'), newToken)
                .subscribe(res => {
                  this.spinner.hide()

                  if (res['status'].code == 200) {
                    this.showOtpSection = false
                    this.showSuccessScreen = true
                    sessionStorage.setItem('mobileNo', this.newMobileNumber)
                  }
                }, err => {
                  this.spinner.hide()
                  console.log(err)
                  this.bsService.notifyError(err, updateMobile);
                })
            }
          }
          else {
            this.invalidOtp = true
            this.spinner.hide()
            console.log('Hello')
            this.invalidOtp = true
          }
        }, err => {
          console.log(err)
          this.spinner.hide()
          this.bsService.notifyError(err, otpSubmitData);
        })
    }
  }

}
