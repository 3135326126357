<app-header title="Change mobile number"></app-header>

<section class="login-container px30" *ngIf="showMobileSection">
  <form
    [formGroup]="changeNumberForm"
    class="login-Form mt40"
    (ngSubmit)="onSubmitChangeNumberForm()"
  >
    <div class="position-relative">
      <div class="form-group">
        <label>Mobile number</label>
        <input
          type="number"
          formControlName="mobileNumber"
          class="form-control mobNumber"
          placeholder="9xx-xxxx-xxxx"
        />
        <span class="mobPlaceholder"
          ><img src="../../../assets/images/Flag-Philippines.png" />+63
          <img src="../../../assets/images/down-arrow.png" />
        </span>
        <img
          *ngIf="changeNumberFormVal.mobileNumber.status == 'VALID'"
          class="correct-icon"
          src="../../../assets/images/Group 4868.svg"
        />
      </div>
    </div>

    <div
      *ngIf="
        submittedNumberChangedForm && changeNumberFormVal.mobileNumber.errors
      "
      class="error"
    >
      <span *ngIf="changeNumberFormVal.mobileNumber.errors.required"
        >Please enter mobile number</span
      >
      <span *ngIf="changeNumberFormVal.mobileNumber.errors.pattern"
        >Invalid number</span
      >
    </div>

    <div class="text-center mt40">
      <button class="btn btn-primary me-2" type="submit">
        <strong>Next</strong>
      </button>
    </div>
  </form>
</section>

<section class="otpContainer" *ngIf="showOtpSection">
  <div class="text-center">
    <div class="blue mb20"><strong> OTP verification</strong></div>
    <div>Please enter the OTP to confirm changing of your mobile number.</div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12">
      <form
        [formGroup]="otpForm"
        (ngSubmit)="onSubmitOtpForm()"
        class="otpForm"
      >
        <div class="form-group text-center mt12">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{ length: 6, allowNumbersOnly: true }"
            [ngClass]=""
            #ngOtpInput
          >
          </ng-otp-input>

          <div *ngIf="invalidOtp" class="error mt20">
            The code entered is incorrect
          </div>
          <!-- <div *ngIf="enterOTP" class="error mt20">
            The code entered is incorrect -->
          <div *ngIf="otpNotAvailable" class="error mt20">please enter OTP</div>
          <span class="px-2 text-center d-block mt12">
            <div [class.red]="this.otpColor == 'red'">
              <span class="contentSmall">
                <countdown
                  #cd
                  [config]="{ leftTime: 60, format: 'mm:ss', notify: 0 }"
                  (event)="handleEvent($event)"
                ></countdown> </span
              ><span> left </span>
            </div>
          </span>

          <span class="px-2 text-center d-block mt40"
            >I didnt receive the code.<span (click)="resendOTP()" class="blue"
              >Resend OTP</span
            ></span
          >
          <!-- </div> -->

          <div class="text-center mt50">
            <button class="btn btn-primary me-2" type="submit">
              <strong>Confirm</strong>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="success-screen" *ngIf="showSuccessScreen">
  <div class="success-btn">
    <img class="success-icon" src="../../../assets/images/successIcon.svg" />
  </div>
  <div class="text-center blue">
    <h4><strong> You have successfully changed your mobile number.</strong></h4>
  </div>
  <div class="text-center mt50">
    <button class="btn btn-primary me-2" (click)="onClickLogin()">
      <strong>Back to account settings.</strong>
    </button>
  </div>
</section>
