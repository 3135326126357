<app-header title="PediaSure<sup>®</sup>   range"></app-header>
<section class="globalContainer bg-lightBlue">
  <div class="product-detail-page px15">
    <div class="bannner-section">
      <div class="imgSection">
        <img src="../../../assets/images/prodimg/3.png" />
      </div>
      <div class="titleSection v-top">
        <h2 class="fs20 color-yellow mb-0">PediaSure<sup>®</sup>  10+</h2>
        <span class="fs13">10 - 15 years old</span>
      </div>
    </div>
    <div class="product-detail-container bg-white btl20 btr20 pt-4 pb70">
      <div class="content-card">
        <h4 class="fs20 font-darkblue mt30 mb-3">
          Fuel your teen’s final growth spurt!
        </h4>
        <p class="mb-3">
          With a unique Triple Growth System and up to 2x^ key nutrients,
          PediaSure<sup>®</sup>   10+ supports increased nutritional needs of teens during
          their final growth spurt.
        </p>
        <p>
          PediaSure<sup>®</sup>   10+ is power-packed with 34 key nutrients and delivers
          complete, balanced nutrition. PediaSure<sup>®</sup>   10+ is your delicious,
          stress-free nutrition strategy to help fuel your Teen’s final growth
          spurt.
        </p>
        <h4 class="fs20 font-darkblue mt-5 mb-3">Product information</h4>
        <p class="mb-3">Fuel your teenager’s final growth spurt with PediaSure<sup>®</sup>   10+’s unique Triple Growth System.</p>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img src="../../../assets/images/growth-1.png" alt="Growth" />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Balanced Growth</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >34 key nutrients to provide complete, balanced nutrition
                </span>
              </li>
              <!-- <li>
                <span
                  >Provides at least 50% of daily requirements for key nutrients
                  to support growth</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img src="../../../assets/images/growth-2.png" alt="Growth" />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Physical Growth</h5>
            <ul class="disc-inside pl-0">
              <li><span>2x Calcium & Natural Vitamin K2^</span></li>
              <!-- <li>
                <span
                  >100% daily requirement of Vitamin D; supports Calcium
                  absorption & improves bone strength</span
                >
              </li> -->
              <li>
                <span
                  >Triple Protein Complex (Soy, Whey & Casein); 25%` more
                  high-quality protein that provides essential amino acids to
                  support muscle tissue building and growth</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img src="../../../assets/images/growth-3.png" alt="Growth" />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Stronger Growth</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span>High energy to fuel active lifestyles & growth</span>
              </li>
              <li><span>Vitamin C and Iron to help reduce fatigue</span></li>
              <li>
                <span
                  >Vitamins A, B6, B12, C, Iron, Zinc & Selenium for normal
                  immune function</span
                >
              </li>
            </ul>
          </div>
        </div>
        <p class="m-3">PediaSure<sup>®</sup>   is Gluten-free</p>
        <p class="m-3">
          ^Compared to PediaSure<sup>®</sup>   Gold for levels of 8 key nutrients Carnitine,
          Vit D3, Vit E, Vit K2, Potassium, Calcium, Phosphorus and Zinc. <br />
          `Compared to PediaSure<sup>®</sup>   Gold
        </p>
        <h4 class="fs20 font-darkblue mt-5 mb-4">Nutritional Table</h4>
        <div class="table-section">
          <table class="nutrition-table mb-4">
            <thead>
              <tr>
                <th>Nutrient</th>
                <th>Per 100ml</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Energy (Kcal)</td>
                <td>453</td>
              </tr>
              <tr>
                <td>Protein (g)</td>
                <td>16.96</td>
              </tr>
              <tr>
                <td>Total Fat (g)</td>
                <td>17.82</td>
              </tr>
              <tr>
                <td class="pl-4">Saturated fatty acids (g)</td>
                <td>2.30</td>
              </tr>
              <tr>
                <td class="pl-4">Polyunsaturated fatty acids (g)</td>
                <td>5.15</td>
              </tr>
              <tr>
                <td class="pl45">Linoleic acid (g)</td>
                <td>4.06</td>
              </tr>
              <tr>
                <td class="pl45">α-Linolenic acid (g)</td>
                <td>0.46</td>
              </tr>
              <tr>
                <td class="pl45">DHA (mg)</td>
                <td>20</td>
              </tr>

              <tr>
                <td>Carbohydrate (g)</td>
                <td>55.12</td>
              </tr>
              <tr>
                <td class="pl-4">Total sugar (g)</td>
                <td>32.8</td>
              </tr>
              <tr>
                <td class="pl45">Lactose (g)</td>
                <td>15</td>
              </tr>
              <tr>
                <td>FOS (g)</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Taurine (mg)</td>
                <td>40.6</td>
              </tr>
              <tr>
                <td>Carnitine (mg)</td>
                <td>12.2</td>
              </tr>
              <tr>
                <td>Arginine (mg)</td>
                <td>1000</td>
              </tr>
              <tr>
                <td>Inositol (mg)</td>
                <td>43.4</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Vitamins</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vitamin A (IU)</td>
                <td>1173</td>
              </tr>
              <tr>
                <td>Vitamin D3 (IU)</td>
                <td>604</td>
              </tr>
              <tr>
                <td>Vitamin E (IU)</td>
                <td>16.2</td>
              </tr>
              <tr>
                <td>Vitamin K (total) (mcg)</td>
                <td>48.1</td>
              </tr>
              <tr>
                <td class="pl-4">Vitamin K1 (phylloquinone) (mcg)</td>
                <td>32.1</td>
              </tr>
              <tr>
                <td class="pl-4">Vitamin K2 (menaquinone) (mcg)</td>
                <td>16</td>
              </tr>
              <tr>
                <td>Vitamin C (mg)</td>
                <td>50.2</td>
              </tr>
              <tr>
                <td>Folic acid (mcg)</td>
                <td>136</td>
              </tr>
              <tr>
                <td>Vitamin B1 (thiamin) (mg)</td>
                <td>1.4</td>
              </tr>
              <tr>
                <td>Vitamin B2 (riboflavin) (mg)</td>
                <td>1.26</td>
              </tr>
              <tr>
                <td>Vitamin B6 (pyridoxine) (mg)</td>
                <td>1.18</td>
              </tr>
              <tr>
                <td>Vitamin B12 (mcg)</td>
                <td>1.36</td>
              </tr>
              <tr>
                <td>Niacin (mg)</td>
                <td>9.04</td>
              </tr>
              <tr>
                <td>Pantothenic acid (mg)</td>
                <td>3.8</td>
              </tr>
              <tr>
                <td>Biotin (mcg)</td>
                <td>13.6</td>
              </tr>
              <tr>
                <td>Choline (mg)</td>
                <td>190.4</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Minerals</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sodium (mg)</td>
                <td>172</td>
              </tr>
              <tr>
                <td>Potassium (mg)</td>
                <td>942</td>
              </tr>
              <tr>
                <td>Chloride (mg)</td>
                <td>456</td>
              </tr>
              <tr>
                <td>Calcium (mg)</td>
                <td>904</td>
              </tr>
              <tr>
                <td>Phosphorus (mg)</td>
                <td>602</td>
              </tr>
              <tr>
                <td>Magnesium (mg)</td>
                <td>113</td>
              </tr>
              <tr>
                <td>Iron (mg)</td>
                <td>8</td>
              </tr>
              <tr>
                <td>Zinc (mg)</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Manganese (mg)</td>
                <td>0.68</td>
              </tr>

              <tr>
                <td>Copper (mg)</td>
                <td>0.3</td>
              </tr>
              <tr>
                <td>Iodine (mcg)</td>
                <td>47.8</td>
              </tr>
              <tr>
                <td>Selenium (mcg)</td>
                <td>15.8</td>
              </tr>
              <tr>
                <td>Chromium (mcg)</td>
                <td>14.9</td>
              </tr>

              <tr>
                <td>Molybdenum (mcg)</td>
                <td>23.4</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="arrow-up-fixed">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
