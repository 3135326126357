import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-select-brand',
  templateUrl: './select-brand.component.html',
  styleUrls: ['./select-brand.component.scss']
})
export class SelectBrandComponent implements OnInit {
  categoryCode: any;
  selectedBrand: any = "ensure";

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.categoryCode = sessionStorage.getItem('categoryCode');
  }

  selectBrand(val) {
    this.selectedBrand = val;
   
  }

  onSubmit(){
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let selectedBrand = {
      "root": {
        "customer": [
          {
            "mobile": mobile,
            extended_fields: {
              field: [
                {
                  "name":  "preferred_brand",
                  "value":  this.selectedBrand
                }
              ],
            },
          }
        ]
      }
    }
    this.apiService.updateCapCustomer(selectedBrand, mobile, token).pipe(first()).subscribe(
      (res) => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.router.navigate(['/points-added',{ selectedBrand: this.selectedBrand }])
          console.log(res,'resp==');
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err, '=== customer update error =======');
      }
    );
  }

}
