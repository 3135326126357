<app-header title="Account"></app-header>
<section class="globalContainer px15 py-4">
  <div class="accountPage px15">
    <div class="profileLinks">
      <!-- <a href="#" [routerLink]="['/profile/change-mobile']">Change Mobile Number</a> -->
      <a href="#" [routerLink]="['/profile/change-mobile']">
        <img class="mr10 v-top" src="../../assets/images/mobile.svg" /> Change
        mobile number
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a href="#" [routerLink]="['/profile/edit']">
        <img class="mr10 v-top" src="../../assets/images/edit-acc.png" />
        Edit account information
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a href="#" [routerLink]="['/profile/change-password']">
        <img class="mr10 v-top" src="../../assets/images/change-password.png" />
        Change password
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a href="#" [routerLink]="['/profile/manage-address']">
        <img class="mr10 v-top" src="../../assets/images/edit-address.png" />
        Manage address
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a (click)="openClickDeleteDialog()">
        <img class="mr10 v-top" src="../../assets/images/trash.svg" /> Delete
        Account
      </a>
      <!-- <a href="#">Delete account</a> -->
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>

  <!-- warning to permanent deletion of account -->
  <div
    bsModal
    #popupDelete="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="product-qty row">
            <div class="text-center">
              <h4 class="font-darkRed">WARNING</h4>
              <p>Account deletion is permanent.</p>
              <p class="fs26 mb-1">Confirm account deletion?</p>
            </div>
            <div class="col-12 mt-3">
              <button
                type="submit"
                class="primaryDeleteBtn mt-3 fW500 Btn"
                (click)="onClickDeleteBtn()"
              >
                <strong>Delete my account</strong>
              </button>
              <button
                type="submit"
                class="primaryBtn btn-white my-2 fW500 Btn"
                (click)="onClickClose()"
              >
                <strong>Cancel</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- enter password for permanent deletion of account -->
  <div
    bsModal
    #popupPasswordToDelete="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="product-qty row">
            <div class="text-center">
              <h4 class="font-darkRed">DELETE ACCOUNT</h4>
              <p>
                Please input your password to proceed with account deletion.
              </p>
              <div class="mt20">
                <div class="form-group d-flex">
                  <div class="input-div">
                    <input
                      [type]="showPasswordText ? 'text' : 'password'"
                      formControlName="password"
                      placeholder="Enter your password"
                    />
                  </div>
                  <div class="eye">
                    <img
                      class="eye-icon mt-40"
                      *ngIf="showEyeCloseIcon"
                      src="../../../assets/images/Group 5035.svg"
                      (click)="onClickEyeCloseIcon()"
                    />
                    <img
                    class="eye-icon mt-40"
                      *ngIf="showEyeOpenIcon"
                      src="../../../assets/images/visibility.png"
                      (click)="onClickEyeOpenIcon()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <button type="submit" class="primaryDeleteBtn mt-3 fW500 Btn">
                <strong>Delete my account</strong>
              </button>
              <button
                type="submit"
                class="primaryBtn btn-white my-2 fW500 Btn"
                (click)="onClickClose()"
              >
                <strong>Cancel</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
