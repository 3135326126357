import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../../../service/api.service";
import * as moment from "moment";
@Component({
  selector: 'app-similac-reward-details',
  templateUrl: './similac-reward-details.component.html',
  styleUrls: ['./similac-reward-details.component.scss']
})
export class SimilacRewardDetailsComponent implements OnInit {

 
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  rewardId: any;

  pointsList: any;
  voucherList: any;
  rewardDetail: any;
  displayDate: any;
  voucherIssueDate: any;
  voucherDetails = [];
  voucherExpiryDate: string;
  voucherDetailsOfEachIndex: [];
  voucherObjectsToMap: any = [];
  productName: any;
  copied: boolean = false;
  name: any;
  brand: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.rewardId = this.route.snapshot.params.id;
    this.name = this.route.snapshot.params.name;
    console.log(this.rewardId);
    this.getRedemtionDetailFinal();
    this.getCustomer();
    this.brand = sessionStorage.getItem("currentBrand");
  }

  onClickViewLinkInNewTab(link) {
    window.open(link, "_blank");
  }
  onClickCopyLink(link) {
    navigator.clipboard.writeText(link);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
    this.apiService.showToaster("Copied!");
  }

  scrollToTop() {
    var scroll = document.getElementById("scroll");
    scroll.scrollTop = 0;
  }
  onClickRewardDetailsBack() {
    if (this.brand == 'ensure') this.router.navigate(["/dashboard", { name: 'ensure' }]);
    if (this.brand == 'glucerna') this.router.navigate(["glucerna/dashboard", { name: 'glucerna' }]);
    if (this.brand == 'similac') this.router.navigate(["similac/dashboard", { name: 'similac' }]);
    if (this.brand == 'pediasure') this.router.navigate(["pediasure/dashboard", { name: 'pediasure' }]);

  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          console.log(this.customerNamePresent, "pres");
          this.loyaltyPoints = this.customerData.loyalty_points;
          // Block the user
          let block = res["customers"]["customer"][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == "block_count";
          });
          console.log(blockUser, "blockUser===");
          if (blockUser[0]?.value == "1") {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(["/block-notice"]);
          }
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
        };
    }
  }

  getRedemtionDetailFinal() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    this.spinner.show();
    this.apiService.getMarvalVouchers(mobile, token).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res["status"].code == 200) {
          this.pointsList = res?.response?.customer?.redemptions?.points?.point;
          this.voucherList = res?.userVouchers;
          this.voucherList = this.voucherList.filter(
            (ress) => ress.rewardDetails != null
          );

          let voucherFilter = this.voucherList.forEach((voucherList) => {
            let tierData = voucherList.rewardDetails.tier;

            //let tierData = 'ABC123';

            let pointFilter = this.pointsList.filter((point) => {
              return point.notes === tierData;
            });

            let rewardFilter = null;

            if (this.rewardDetail != null || this.rewardDetail != undefined) {
              rewardFilter = this.rewardDetail.filter((reward) => {
                return reward.tier === tierData;
              });
            }

            let foundReward = null;

            if (rewardFilter != null && rewardFilter.length > 0) {
              foundReward = rewardFilter[0];
            }

            let tottal_points = 0;

            let quantity = 0;

            pointFilter.forEach((pointFilter) => {
              tottal_points = tottal_points + pointFilter.points_redeemed;

              if (foundReward != null) {
                quantity =
                  quantity +
                  pointFilter.points_redeemed / foundReward.intouchPoints;
              } else {
                quantity = voucherList.vouchers.length;
              }
            });

            let transDate = voucherList.vouchers[0].issued;

            voucherList["total_points"] = tottal_points;
            voucherList["quantity"] = quantity;
            //voucherList['displayDate'] = moment(transDate).add(2.5, 'hours').format('DD MMM yyyy');
            voucherList["displayDate"] = transDate;
          });

          setTimeout(() => {
            this.voucherList.sort((a, b) =>
              moment(a.issuedDate, "DD-MM-YYYY").isBefore(
                moment(b.issuedDate, "DD-MM-YYYY")
              )
                ? 1
                : -1
            );

            this.spinner.hide();
          }, 1000);

          console.log(this.voucherList, this.rewardId);

          this.voucherList.forEach((index) => {
            this.productName = index.rewardDetails.name;
            if (this.rewardId == index.rewardDetails.rewardId) {
              this.voucherDetailsOfEachIndex = index.vouchers;
              this.voucherDetailsOfEachIndex.forEach((voucher: any) => {
                let obj = {
                  code: voucher?.code,
                  issuedDate: moment(voucher?.issued).format("D MMMM y"),
                  expiryDate: moment(voucher?.expiry).format("D MMMM y"),
                };
                // console.log(obj);
                this.voucherObjectsToMap.push(obj);
              });
            }
            console.log(this.voucherObjectsToMap);
          });

          // this.voucherList.forEach((element) => {
          //   if (this.rewardId == element.rewardDetails.rewardId) {
          //     this.rewardDetail = element.rewardDetails.name;
          //     this.displayDate = element.displayDate;
          //     this.voucherDetails = element.vouchers;
          //     this.voucherIssueDate = moment(element.vouchers.issued).format(
          //       "d MMMM y"
          //     );
          //     this.voucherExpiryDate = moment(element.vouchers.expiry).format(
          //       "d MMMM y"
          //     );
          //   }
          // });

          // console.log(this.displayDate, this.rewardDetail, this.voucherDetails);
        }
      },
      (err) => {
        console.log(err, "err--");
        this.spinner.hide();
      }
    );
  }

}
