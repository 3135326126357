<div class="pageHeader row mx-0 px30">
  <div
    class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>About Ensure Gold<sup>®</sup></h1>
  </div>
</div>
<section class="globalContainer">
  <section class="about-ensure-gold min-vh-100 bg">
    <div class="d-flex mt16">
      <div class="milk-image col-6">
        <img src="../../../../assets/images/Milk.png" />
      </div>
      <div class="milk-content col-7">
        <h4 class="fs32 font-gold">Ensure Gold<sup>®</sup></h4>
        <span class="color-white"
          >is a unique system of ingredients including Calcium HMB
          (β-hydroxy-β-methylbutyrate) and high-quality triple-protein complex
          which aids in the building and maintenance of muscle mass
        </span>
      </div>
    </div>

    <div class="row">
      <div class="about-ensure-cards font-gold mt16">
        <img src="../../../assets/images/Group 5256.svg" />
        <span class="">PROTEIN+HMB</span>
      </div>

      <div class="about-ensure-cards font-gold mt16">
        <img src="../../../assets/images/Group 5294.svg" />
        <span class="">VITAMINS AND MINERALS</span>
      </div>

      <div class="about-ensure-cards font-gold mt16">
        <img src="../../../assets/images/Group 5295.svg" />
        <span class="">OMEGA-3 AND 6</span>
      </div>

      <div class="about-ensure-cards font-gold mt16">
        <img src="../../../assets/images/Group 5254.svg" />
        <span class="">GLUTEN-FREE</span>
      </div>

      <div class="about-ensure-cards font-gold mt16">
        <img src="../../../assets/images/Group 5296.svg" />
        <span class="">LOW IN LACTOSE</span>
      </div>

      <div class="about-ensure-cards font-gold mt16">
        <img src="../../../assets/images/Group 5297.svg" />
        <span class="">LOW IN CHOLESTROL</span>
      </div>
    </div>

    <div class="arrow-image">
      <img src="../../../../assets/images/Path 23305.svg" />
    </div>
    <div class="d-flex justify-content-around">
      <div class="text-center">
        <img src="../../../../assets/images/ENSURE SVG Files-09.svg" />
      </div>
      <div class="text-center">
        <img src="../../../../assets/images/ENSURE SVG Files-08.svg" />
      </div>
      <div class="text-center">
        <img src="../../../../assets/images/ENSURE SVG Files-07.svg" />
      </div>
    </div>
    <div class="fs12 d-flex justify-content-center mx-30 mt15">
      <p class="px-30 color-white">
        Recommended number of servings per day:<br />2, or as advised by your
        healthcare professional.
      </p>
    </div>
    <div class="mx-4 fs16 pb-10 color">
      <p class="font-gold fs16">PREPARE IT RIGHT EVERY DAY!</p>
      <p class="text-left color-white">
        To prepare one 230mL serving, pour about 185mL of warm or cold water in
        a glass. Gradually add 6 leveled scoops of 60.6g of
        <span class="font-gold">Ensure Gold<sup>®</sup></span> powder, while
        stirring and mix to dissolve.
      </p>
    </div>
  </section>
</section>

<app-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="rewardInfo.name"
></app-menu>
