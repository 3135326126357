<app-header title="Customer testimonial"></app-header>
<section class="globalContainer px30 py-5 bg-lighBlue" id="top">
  <div class="testimonial-container pb30">
    <h4 class="font-darkblue mb-4 fs20">
      Hear what others say about this PediaSure<sup>®</sup>  !
    </h4>
    <div class="testimonial-card">
      <img
        src="../../../assets/images/testimonial1.png"
        class="w-100"
        alt="Banner"
      />
      <div class="content px30 py-4">
        <h5 class="username font-bold font-blue fs16">@ashlynthia</h5>
        <p class="mb-0 fs13">
          If you have a fussy eater at home like I do, why not try supplementing
          your child’s diet with a nutritional supplement like PediaSure? Not
          only does PediaSure<sup>®</sup>  complement my girls’ diets, it tastes great too!
        </p>
      </div>
    </div>
    <div class="testimonial-card">
      <img
        src="../../../assets/images/testimonial2.png"
        class="w-100"
        alt="Banner"
      />
      <div class="content px30 py-4">
        <h5 class="username font-bold font-blue fs16">@kiminpink</h5>
        <p class="mb-0 fs13">
          If you’re a mum like me who is concerned about your child’s growth and
          the high sucrose content in their daily diet, you can now opt for
          PediaSure<sup>®</sup>  Sucrose Free which includes all the goodness without the
          added sucrose! Surprisingly enough, Chloe prefers the taste of this
          flavour more!
        </p>
      </div>
    </div>
    <div class="testimonial-card">
      <img
        src="../../../assets/images/testimonial3.png"
        class="w-100"
        alt="Banner"
      />
      <div class="content px30 py-4">
        <h5 class="username font-bold font-blue fs16">@claramummee</h5>
        <p class="mb-0 fs13">
          After taking PediaSure<sup>®</sup>  for 8 weeks, she has grown 3.1cm taller and
          1.2kg heavier. The growth is rather impressive.
        </p>
      </div>
    </div>

    <div class="testimonial-card">
      <img
        src="../../../assets/images/testimonial4.png"
        class="w-100"
        alt="Banner"
      />
      <div class="content px30 py-4">
        <h5 class="username font-bold font-blue fs16">@staceyimelda</h5>
        <p class="mb-0 fs13">
          I started to measure my son for 8 weeks. He has grown taller by 2cm
          and gained weight by 2.2kg. I trust PediaSure.
        </p>
      </div>
    </div>
    <div class="arrow-up-fixed">
      <a (click)="onArrowUp()">
        <i class="fa fa-arrow-up"></i>
      </a>
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
