<section class="globalContainer min-vh-100">
    <div>
      <div class="img1">
        <img
          class="text-center"
          src="../../../assets/images/pediasure/pediasure_50_pts.png"
          alt="Points added"
        />
      </div>
      <div class="text text-center">
        <img class="d-block m-auto mb-3 img-150" src="../../../assets/images/pediasure/pediasure_plus_brand_pts.png" />
        <span
          ><span class="font-blue fW600">50 points</span> points will be added
          to your account once verified.</span
        >
      </div>
      <div class="text text-center my-3">
        <p>
          Verification may take up to 5 working days. Please keep your receipt for
          2 weeks in case we may need your proof of purchase.
        </p>
      </div>
      <button
        type="button"
        class="btn btn-white mt-2"
        [routerLink]="['/site-switcher']"
      >
      Proceed to dashboard
      </button>
  
      <button type="button" class="primaryBtn btn-white my-2"> Review transaction history </button>
  
     
      <p class="text-center text-DOH mt-5">Per DOH-FDA CFRR Promo Permit 0009 s. 2023</p>
    </div>
  </section>
  