<app-header title="Eligible Ensure Gold<sup>®</sup> Products"></app-header>
<section>
  <section class="product-benifits min-vh-100 pb70 bg-path">
    <div class="benefits mt-5">
     <h2>Scan these Ensure Gold® <br /> products to earn points!</h2>
    </div>
    <article>
        <div class="row bb">
            <div class="col-6 pl-0">
                <label>product</label>
            </div>
            <div class="col-6 pr-0 text-right">
                <label>points</label>
            </div>
        </div>
        <div class="row bb">
            <div class="col-3">
               <img src="../../../assets/images/ensure6.png" />
            </div>
            <div class="col-6 flexAllign">
                <strong>Ensure Gold<sup>®</sup> 1.6kg</strong>
                <small>Flavor: Vanilla</small>
            </div>
            <div class="col-3 flexAllign text-right">
                <span>100 points</span>
            </div>
        </div>
        <div class="row bb">
            <div class="col-3">
               <img src="../../../assets/images/ensure5.png" />
            </div>
            <div class="col-6 flexAllign">
                <strong>Ensure Gold<sup>®</sup> 850g</strong>
                <small>Flavor: Vanilla</small>
            </div>
            <div class="col-3 flexAllign text-right">
                <span>70 points</span>
            </div>
        </div>
        <div class="row bb">
            <div class="col-3">
               <img src="../../../assets/images/ensure5.png" />
            </div>
            <div class="col-6 flexAllign">
                <strong>Ensure Gold<sup>®</sup> 400g</strong>
                <small>Flavor: Vanilla</small>
            </div>
            <div class="col-3 flexAllign text-right">
                <span>30 points</span>
            </div>
            <p class="color-lightgrey fs10 text-center ensure-text">
                Per DOH-FDA CFRR Permit No. 0782 s. 2023
            </p>
        </div>
    </article>
  </section>
  <app-menu
    [customerNamePresent]="customerNamePresent"
    [brand]="rewardInfo.name"
  ></app-menu>
</section>
