<section class="globalContainer">
  <div class="progress-bar-container">
    <div class="badge-card">
      <img src="../../../assets/images/pediasure/PediaSure_reward_points.png" />
      <p class="fs16 mt-3">
        <span class="txt-color">25 points</span> added to your account for
        earning a Growth Badge!
      </p>
      <button
        [routerLink]="['/pediasure/dashboard']"
        type="submit"
        class="progress-badge-btn fW500 Btn mt-4"
      >
        <strong>Back to Dashboard</strong>
      </button>
    </div>
  </div>
</section>
