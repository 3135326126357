<div class="pageHeader row mx-0 px30">
  <div
    class="
      col-12
      justify-content-center
      d-flex
      position-relative
      header-height
      align-items-center
      d-flex
    "
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>About Glucerna<sup>®</sup></h1>
  </div>
</div>
<section class="globalContainer">
  <section class="about-glucerna min-vh-100 bg">
    <div class="top-heading">
      <!-- <img class="why-should-i-choose" src="../../../assets/images/glucerna/WHY-SHOULD-I-CHOOSE.png" /> -->
      <span class="fs-20 fw-bold">WHY SHOULD I CHOOSE</span>
      <br />
      <div class="logo-div mt-2">
        <img
          class="glucerna-logo"
          src="../../../assets/images/glucerna/glucerna-logo.svg"
        />
        <span>?</span>
      </div>
    </div>
    <div class="product-details d-flex mx-10">
      <div class="product-image col-5">
        <img
          class="vanilla-img-80"
          src="../../../../assets/images/glucerna/GLUCERNA-900G-VANILLA-NEW.png"
        />
      </div>
      <div class="product-content col-7">
        <span class="color-white"> How can Glucerna help me? </span>
      </div>
    </div>
    <div class="product-description">
      <div class="text-content-dark">
        <p>
          Four in every 5 Filipinos with diabetes have poor blood sugar
          control<sup class="glucerna-sup-text">1</sup>. Poor blood sugar
          control is associated with higher risk of diabetes complications like
          vision problems, heart disease, kidney disease, lower limb amputation
          and stroke?<sup class="glucerna-sup-text">2-4</sup>. Good blood sugar
          control depends on three main factors: medication, exercise and
          nutrition, all of which play a synergistic role in the successful
          management of diabetes<sup class="glucerna-sup-text">4</sup>.
        </p>
        <p class="mt-2">
          The new and improved Glucerna is a specialized nutrition
          scientifically formulated with a new blend of ingredients to support
          the dietary management of diabetics. It also provides 28 essential
          vitamins and minerals and can be used as a snack or nutritional
          supplement, as part of a necessary diabetic meal plan. Glucerna, a
          pioneer in scientific evidence, has been studied for 30 years in over
          5,000 individuals<sup class="glucerna-sup-text">5</sup>.
        </p>
      </div>
      <div class="text-content-light">
        1 Jimeno CA et al. Phil J Intern Med. 2012;50(1):15-22.
        <br />
        2 Huang ES et al. Diabetes Care. 2011;34(6):1329-1336. <br />
        3 U. K. Prospective Diabetes Study Group. 1998;352(9131):837-853. <br />
        4 American Diabetes Association. Diabetes Care. 2021;44(Supplement 1):
        S1-S232.<br />
        5 Based on systematic review of published literature (up until December
        2020).
      </div>
    </div>
  </section>
  <div class="bottom-bg"></div>
</section>

<app-glucerna-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="rewardInfo.name"
></app-glucerna-menu>
