import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../service/api.service";
import { BugsnagService } from "../services/bugsnag.service";

@Component({
  selector: "app-reward-redemption-successful",
  templateUrl: "./reward-redemption-successful.component.html",
  styleUrls: ["./reward-redemption-successful.component.scss"],
})
export class RewardRedemptionSuccessfulComponent implements OnInit {
  name: any;
  image: any;
  quantity: any;
  loyaltyPoints: any;
  intouchPoints: any;
  brand: string;
  customerData: any;
  remainingPoints: any;
  couponData: any;
  finalURL: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    console.log(this.route.snapshot.params);
    this.name = this.route.snapshot.params.name;
    this.image = this.route.snapshot.params.image;
    this.quantity = this.route.snapshot.params.quantity;
    this.intouchPoints = this.route.snapshot.params.intouchPoints;
    this.brand = sessionStorage.getItem("currentBrand");
    this.getCustomer();
    this.getCustomerCoupons()
  }
  onClickBackToDashboard() {
    this.router.navigate(["/dashboard", { name: 'ensure' }]);
  }
  onClickViewRewardDetails() {
    this.router.navigate(["/reward/details", { id: this.finalURL, name: this.name }]);
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log("customer not loggged in")
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe(res => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);

          this.loyaltyPoints = this.customerData.loyalty_points.toLocaleString();
        } else {
          this.spinner.hide();


        }

      }, err => {
        this.spinner.hide();
        console.log(err)
        this.bsService.notifyError(err);
      })
    }

  }

  getCustomerCoupons() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    this.spinner.show();
    this.apiService.getCapCustomerCoupons(mobile, token).subscribe(
      (res) => {
        this.spinner.hide();
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.couponData = res["customers"]["customer"][0]?.coupons?.coupon;
          // this.couponLenghth = res["customers"]["customer"][0]?.coupons;
          console.log(this.couponData, ' this.couponData ==========')
          // console.log( this.couponLenghth.length, ' this.couponLenghth ==========')
          let code = this.couponData[0]?.code
          let url = this.couponData[0]?.custom_properties?.custom_property[0]?.value
          this.finalURL = url + code

        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      }
    );
  }

}
