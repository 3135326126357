import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-scan-success',
  templateUrl: './signup-scan-success.component.html',
  styleUrls: ['./signup-scan-success.component.scss']
})
export class SignupScanSuccessComponent implements OnInit {

  scannedProductCategory: string
  iSprintData: any
  category: any;

  constructor() { }

  ngOnInit(): void {
    this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.category = this.iSprintData?.category_code?.toLowerCase()
    sessionStorage.setItem('categoryCode', this.category)
    if (this.category.startsWith('ensure')) this.category = "ensure"
    if (this.category.startsWith('pediasure')) this.category = "pediasure"
    if (this.category.startsWith('glucerna')) this.category = "glucerna"
    if (this.category.startsWith('similac')) this.category = "similac"
  }

}
