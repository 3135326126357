import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {
  aboutClicked = false;
  aboutPopup = false;
  @ViewChild('aboutPopupModal', { static: true }) aboutPopupModal: ModalDirective

  constructor() { }

  ngOnInit(): void {
  }

  onClickAbout() {
    this.aboutClicked = true;
    this.aboutPopup = true;
    this.aboutPopupModal.show()
  }

}
