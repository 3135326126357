<section class="globalContainer px15 py-4 bg-white">
  <div class="disclaimer mt-5 text-center px30">
    <div class="success-img">
      <img src="../../../assets/images/new/success.png" alt="Success" />
      <p>
        <strong>+{{ skuPoint }}</strong>
        <span>POINTS</span>
      </p>
    </div>
    <img
      *ngIf="category == 'ensure'"
      src="../../../assets/images/new/ensurelogo.png"
      alt="Pediasure"
      class="mt-2 mb-4"
    />
    <img
      *ngIf="category == 'glucerna'"
      src="../../../assets/images/glucerna/glucerna_brand_logo.png"
      alt="Pediasure"
      class="mt-2 mb-4"
    />

    <img
      *ngIf="category == 'pediasure'"
      src="../../../assets/images/pediasure/PediaSure Plus logo purple.svg"
      alt="Pediasure"
      class="mt-2 mb-4"
    />
    <img
    *ngIf="category == 'similac'"
    src="../../../assets/images/similac/SimilacLogo.svg"
    alt="Pediasure"
    class="mt-2 mb-4"
  />
    <!-- <h4 class="font-darkblue fW600 fs32 mb-1 mt-4">POINTS</h4> -->
    <!-- <p class="mb-4">
      <strong class="light-blue">{{ skuPoint }} Points</strong> will be added to
      your account once verified.
    </p>
    <p class="mb-5">
      Verification may take up to 3 working days. Please keep your receipt for 2
      weeks in case we may need your proof of purchase.
    </p> -->
    <div class="text text-center">
    <p class="mb-4"><span>{{ skuPoint }} points</span> will be added to your account once verified.</p>
    <h6 class="font-blue fW600 text-center mb-3">WHAT’S NEXT?</h6>
    <ul>
      <li>
        Verification may take up to <span class="font-blue fW600"> 3 working days</span>.
      </li>
      <li>
        Please <span class="font-blue fW600"> keep your receipt for 2 weeks </span> in case we may need your proof of purchase.
      </li>
    </ul>
    </div>
    <div class="btnSection">
      <button
        type="button"
        class="primaryBtn mb-3"
        (click)="onClickDashborad()"
      >
        Proceed to dashboard
      </button>
      <button
        type="button"
        class="primaryBtn btn-white mt-2s"
        (click)="onClickTransactionHistory()"
      >
        Review transaction history
      </button>
    </div>
    <p class="text-center bottom">Per DOH-FDA CFRR Promo Permit No. 0782 s. 2023</p>

  </div>
</section>
