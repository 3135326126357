<section class="select-brand-container">
  <div class="">
    <div class="text-center">
      <img src="../../assets/images/ABBOTT LOGO-01.svg" class="w-125" />
      <img
        src="../../assets/images/Group 5170 (3).svg"
        class="w-75"
        alt="Rewards"
      />
      <p class="mb-3 txt">
        <strong class="blue">
          You got <span class="light-blue">50 points</span> for signing
          up!</strong
        >
      </p>
      <p class="txt1">
        Choose the brand where you wish to credit the points to:
      </p>
      <select
        name="brand"
        (change)="selectBrand($event.target.value)"
        id="brand"
        class="select mt pl10 form-control"
      >
        <option value="ensure" class="pl20">Ensure Gold<sup>®</sup></option>
        <option value="glucerna" class="pl20">Glucerna<sup>®</sup></option>
        <!--Only for UAT release-->
        <option value="pediasure" class="pl20">
          Pediasure Plus<sup>®</sup>
        </option>
        <option value="similac" class="pl20">
          Similac<sup>®</sup>GainSchool<sup>®</sup>
        </option>
        <!-- <option value="saab">Saab</option>
          <option value="mercedes">Mercedes</option>
          <option value="audi">Audi</option> -->
      </select>
      <button
        type="button"
        class="primaryBtn btn-white mt-4"
        (click)="onSubmit()"
      >
        Confirm
      </button>
    </div>
    <p class="text-center bottom">Per DOH-FDA CFRR Promo Permit 0009 s. 2023</p>
  </div>
</section>
