<app-header title="My Profile"></app-header>

<section class="globalContainer px15 py-5 BG-lightblue min-vh-100 ">
  <div class="profilePage px15">
    <div class="profileCard text-center bg-white">
      <div class="profileImgRoundedBlockOut">
        <div class="imgBlock position-relative inline-block">
          <img
            *ngIf="!finalUserImage"
            src="../../assets/images/profile.png"
            alt="Profile"
          />
          <img *ngIf="finalUserImage" [src]="finalUserImage" alt="Profile" />

          <!-- <a href="#" class="editProfilePic">
            <img src="../../assets/images/edit.png" />
            <input
              id="upload"
              type="file"
              name="profile"
              (change)="fileChangeEvent($event)"
            />
          </a> -->
          <div class="changePic mt-2 BG-lightblue" >
            <div class="imageUploadWrap">
              <label class="editProfilePic" for="upload">
                <img class="edit-icon" src="../../assets/images/edit.png" />
                <input
                  id="upload"
                  type="file"
                  name="profile"
                  (change)="fileChangeEvent($event)"
                />
              </label>
              <ngx-popup
                [(ngModel)]="visible"
                [ngModelOptions]="{ standalone: true }"
              >
                <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio"
                  [aspectRatio]="3 / 3"
                  [resizeToWidth]="256"
                  [cropperMinWidth]="128"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
                >
                </image-cropper>
                <button (click)="uploadImage()">Click here to upload</button>
              </ngx-popup>
            </div>
          </div>
        </div>
      </div>

      <p class="profileName fs20">{{ customerName }}</p>
      <p class="profileNum">+63 {{ groupedPhonNo }}</p>
    </div>
    <div class="profileLinks">
      <!-- <a href="#" [routerLink]="['/profile/transaction-history']"
        ><img class="mr10 v-top" src="../../assets/images/transaction.png" />
        Transaction history
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a> -->
      <a href="#" [routerLink]="['/profile/account']">
        <img
          class="mr10 v-top"
          src="../../assets/images/account-blue.png"
        />Account
        <img class="float-right" src="../../assets/images/arrow-right.png" />
      </a>
      <a (click)="onClickLogout()">
        <img class="mr10 v-top" src="../../assets/images/logout.png" />Log
        out</a
      >
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
