<section class="brand-container">
  <div class="user-name text-right">
    <div *ngIf="customerNamePresent">
      <p class="fs20 mb-0">
        <span [routerLink]="['/profile']">{{ customerName }}</span>
      </p>
      <div class="d-flex justify-content-end">
        <a  [routerLink]="['/profile']" class="my-profile fs10 mx-1">My Profile </a>
        <img src="../../assets/images/chevron-right-1px.svg" />
      </div>
    </div>
    <div *ngIf="!customerNamePresent">
      <div
        class="d-flex justify-content-end signup"
        [routerLink]="['/login-signup']"
      >
        <span class="user-name fs20 mx-1">Sign up / login </span>
        <img src="../../assets/images/chevron-right-1px.svg" />
      </div>
    </div>
  </div>
  <div>
    <div class="image">
      <img src="../../../assets/images/ABBOTT LOGO-01.png" />
    </div>

    <div class="image-container mt-4">
      <div class="brand-box row justify-content-start">
        <div class="col-6">
          <div class="ensure" (click)="onClickEnsure()">
            <div class="content" *ngIf="loyaltyPoints">
              <p class="text-center">Reward Points</p>
              <strong class="text-center">{{ loyaltyPoints }}</strong>
            </div>
          </div>
        </div>

        <!-- below div should be only shown when glucerna is DISABLED -->
        <div class="col-6">
          <div class="glucerna-enabled glucerna" (click)="onClickGlucerna()">
            <div class="content" *ngIf="loyaltyPoints">
              <p class="text-center">Reward Points</p>
              <strong class="text-center">{{ loyaltyPoints }}</strong>
            </div>
          </div>
        </div>
        <!--Only for UAT release-->
        <div class="col-6">
          <div class="pediasure" (click)="onClickPediasure()">
            <div class="content" *ngIf="loyaltyPoints">
              <p class="text-center">Reward Points</p>
              <strong class="text-center">{{ loyaltyPoints }}</strong>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="similac" (click)="onClickSimilac()">
            <div class="content" *ngIf="loyaltyPoints">
              <p class="text-center">Reward Points</p>
              <strong class="text-center points">{{ loyaltyPoints }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  </div>
  <p class="text-center bottom">
    Per DOH-FDA CFRR Promo Permit 0009 s. 2022
  </p>
  <div class="white-box">
    <a [routerLink]="['/about/family']" class="text-decoration-none"
      >About Abbott Alaga Rewards</a
    >
    <img src="../../assets/images/dot.svg" />
    <a [routerLink]="['/contact-us']" class="text-decoration-none"
      >Contact Us</a
    >
  </div>

  <!-- glucerna popup -->
  <div
    bsModal
    #glucernarewards="bs-modal"
    class="modal fade customModalContainer m-left-right"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm mx-auto modal-dialog-centered px-3">
      <div
        class="
          modal-content modal-content-glucerna-bg
          text-center
          br10
          px30
          py-3
        "
      >
        <div class="text-white">
          <h4 class="sub-head my-4">
            Glucerna Rewards is now part of Abbott Alaga Rewards!
          </h4>

          <p>
            If you have an existing Glucerna Rewards account, your reward points
            have been automatically transferred to Abbott Alaga Rewards under
            Glucerna® reward points.<br /><br />If you need any assistance, feel
            free to <br />
            <span>
              <a [routerLink]="['/contact-us']" class="btn-link-fce"
                >Contact Us</a
              > </span
            >.
          </p>
        </div>

        <div class="text-center">
          <button
            [routerLink]="['/glucerna/dashboard', { name: 'glucerna' }]"
            class="primaryBtn fW100 continue-btn mt-4"
            type="submit"
          >
            Got it
          </button>
        </div>

        <div class="form-check ps-0 mt-3">
          <input
            type="checkbox"
            class="form-check-input"
            class="check3"
            (change)="checkValue($event)"
          />
          <label class="form-input-label text-white ms-2"
            >Do not show this message again</label
          >
        </div>
      </div>
    </div>
  </div>
</section>
