import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare let Email: any;

@Component({
  selector: 'app-glucerna-select-store',
  templateUrl: './glucerna-select-store.component.html',
  styleUrls: ['./glucerna-select-store.component.scss']
})
export class GlucernaSelectStoreComponent implements OnInit {


  contactForm: FormGroup
  submittedContactForm: boolean = false;
  emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      privacyPolicy: [false, Validators.requiredTrue],
    })
  }

  get contactFormVal() {
    return this.contactForm.controls;
  }

  onSubmitContactForm() {
    this.submittedContactForm = true
    if (this.contactForm.invalid) {
      return;
    } else {
      Email.send({
        Host: 'smtp.elasticemail.com',
        Username: 'mamathakavety29@gmail.com',
        Password: '69D58625A9DA98DB83B660B67E11979CA295',
        // receive enquires on this email
        To: 'mamatha.k@spurtreetech.in',
        // email id entered by user
        From: this.contactForm.controls.email.value,
        Subject: this.contactForm.controls.subject.value,
        Body: this.contactForm.controls.message.value
      }).then(message => {
        if (message == 'OK') {
          this.contactForm.reset()
          alert('Your email has been sent, Thankyou for contacting')
          this.router.navigate(['/dashboard'])
        } else {
          alert('There is error at sending message')
        }
      });

    }
  }
}
