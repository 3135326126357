// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // 'https://apac-s3wrapper.capillarytech.com/auth/v1/token/generate'
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',

  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  //CMS_URL:    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  CMS_URL: 'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/luckydrawuat',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
  LICENSEKEY:"AVNDnD9OGY6BOVF31y8j0MUgFeG0LUVfsE1ns9RnMgPhCyY+4y7vegpZXx3heTao1Ect4WsKgPcsb2VUNk/IXs5lm679bhd/a0TgT2dgei9yaW4E4WMoUgNuxNdcVksrcRzuyxNk+WVFcbysemfXWgIVhkn4bdSffm2GuRVvSj5tWJt0BmZvPSNvXdtFBUXUhEIUdk06jphuIH5afMRQNDdQsNjTVZhXIZQN0BVrzSYjz5oqS3FyFtmLNg+3BwLqjcbYZujijdDH/R7DfUvP6GUDaMVry4Wy69FNB99CyzJ/fddXoKFqr8rEH9OLOS4NHgaY4LB6FmpTtF/bg0JPb8moRcxuPl1nnfJqN9amjUjalqqLuLSoYfLhn0MGooUI8gOaJzkynCAbk+a/G9qKSgeuaxvXgyY5lfgtP5oNwVND8XLsLJ18eAE9+818ZLluN6nkGwntGcVS+l88q3MlIIQ1EGqLH9ujbO7nLisNNPusOC2+P1mm95f6j/Ngr1d9Si0HzVvgeNOK+skUdihoNj6SI0ZmDy5ohX2mnfUZt/Dd/azyllhvsr2RplnIcjER5dNUEg1P9ccPIGTIHx5YAtuRtLYkh8DhzLot3zeB1v82XICKMLGMZ68OC2X+bIo5zAz8gbSR8cCII4iRjOBzWwgRsUWEe+dLMU3fcscqOcbSHpTMb0jFCtOlhsunL5PggnPwmxwovGBjm52m0RwkCqZssV/svN9J/QWEDseFxucNs7lYRtnp7oUhCAuTr1XgTKjNpoTqgdJ8u/3PvUcsHxLOPeuYfLuNyw+w824hQnVGZMte4Cu2k7JHK4PTZrj7czpg16BYoq06FvgRDFfLfFHBCgDlvWJWN3ycrHpynWEL1rYQK1gt6m8dzac=",
  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  //OAUTH_TOKEN:'https://cap-api-eu.sttarter.com/v3',
  OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',
  ACCOUNT_PASSWORD:'Test@123',
  ACCOUNT_CONFIRM_PASSWORD:'Test@123',
  PASSWORD:'test@123',
  CONFIRM_PASSWORD:'test@123',
  BRAND: 'ABBOTTPHAARUAT',
  // BRAND: 'ABBOTTPHAARDEMO',
  //  DEVICE_ID: '1234551231',
  DEVICE_ID: '1234567',
  IDENTIFIER_TYPE: 'MOBILE',
  //TILL_ID: 'abbott.sg.demo.admin',
  //TILL_ID: 'abbottphdemo',
  TILL_ID: 'abbott.ph.demo.1',
  COUNTRY_CODE: 'PH-AAR',
  LANGUAGE: 'en_US',
  MAXMOBNUM: 10,
  MINMOBNUM: 10,
  COUPON_BASE_URL: 'https://gft.ph/',
  bugsnag_version: '13022024',
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"
};

// https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/skupoints

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
