import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-daily-logs-points-confirmation',
  templateUrl: './daily-logs-points-confirmation.component.html',
  styleUrls: ['./daily-logs-points-confirmation.component.scss']
})
export class DailyLogsPointsConfirmationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  onClickBackToDashboard() {
    this.router.navigate(["/pediasure/dashboard", { name: 'pediasure' }]);
  }

}
