import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-points-success-pediasure',
  templateUrl: './points-success-pediasure.component.html',
  styleUrls: ['./points-success-pediasure.component.scss']
})
export class PointsSuccessPediasureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
