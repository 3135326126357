import { Component,Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() text = "";
  @Input() customClass = "";
  @Input() title = "";
  constructor(private location: Location) {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
  }

  onClickBack() {
    this.location.back();
  }
}
