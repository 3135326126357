<div class="pageHeader row mx-0 px30">
    <div
      class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
    >
      <a (click)="onClickBack()">
        <i class="fa fa-angle-left"></i>
      </a>
      <h1>How to Drink</h1>
    </div>
  </div>
  <section class="globalContainer">

    <section class="about-glucerna min-vh-100 bg">
        
      <div class="top-heading">
            <!-- <img class="why-should-i-choose" src="../../../assets/images/glucerna/HOW-CAN-I-INCLUDE.png" /> -->
            <span class="fs-20 fw-bold mb-1">HOW CAN I INCLUDE</span>
            <br>
           
                <img class="glucerna-logo mt-2" src="../../../assets/images/glucerna/glucerna-logo.svg" />
                
                <br>
   
            <!-- <img  src="../../../assets/images/glucerna/IN-MY-DAILY-DIET.png" /> -->
            <span class="fs-20 fw-bold">IN MY DAILY DIET?</span>
      </div>
      <div class="product-details d-flex mx-10">
        <div class="product-image col-5">
            <img src="../../../../assets/images/glucerna/GLUCERNA-900G-VANILLA.png" />
          </div>
          <div class="product-content col-7">
            <span class="color-white">
                Prepare it right every day!
            </span>
          </div>

      </div>
      <div class="product-description">
        <div class="text-content-dark">
          <p><img class="img-fluid p-3 pt-2 m-auto" src="../../../assets/images/glucerna/glucerna-how-to-drink-200ml.png" /></p>
            <p>
                <span>INDICATION:</span> Glucerna is a nutritional formula for the special dietary use of diabetics.
            </p>
             <p>
                <span>INSTRUCTION FOR USE:</span> To prepare a 237 mL feeding, put 200 mL of cold water in a glass. Gradually add contents of sachet (52.1 g of powder) while stirring and mix until dissolved.
             </p>
             <p>
                <span>STORAGE:</span> Store unopened box and sachet at room temperature but not under refrigeration. Reconstituted Glucerna® should be used promptly or covered, refrigerated and used within 24 hours.
             </p>
             
        </div>
      
      </div>
    </section>
    <div class="bottom-bg"></div>
  </section>
  
  <app-glucerna-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="rewardInfo.name"
></app-glucerna-menu>
  