<section class="globalContainer">
    <div class="daily-logs-points-confirmation px30 text-center min-vh-100">
        <div class="daily-logs-points-content ">
            <img class="point-added-icon" src="../../../assets/images/pediasure/1-point-added-successfully.svg" />

            <p class="mt-2"><span>1 point</span> added to your account for logging in your child's good growth behaviors today!</p>
            <button type="submit" class="submit-btn btn btn-lg btn-block mt-4" (click)="onClickBackToDashboard()" >Back to dashboard</button>
        </div>

    </div>
</section>