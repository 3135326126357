<app-header title="PediaSure<sup>®</sup>   range"></app-header>
<section class="globalContainer px15 py-5 bg-lighBlue pb70">
  <div class="productListContainer px15 pb70">
    <h4 class="fs20 font-darkblue mb-3">Check out our products</h4>
    <div class="list-content">
      <p>
        From never missing a growth moment as toddler or child, to fueling final
        growth spurt during adolescence, PediaSure<sup>®</sup>   gets the science of growth
        on your side.
      </p>
    </div>
    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="../../../assets/images/prodimg/1.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure<sup>®</sup>   GOLD</h4>
          <span class="agePreference">1-10 years old</span>
          <p>
            Complete, Balanced Nutrition to support growth and development.
            Available in 3 delicious flavours!
          </p>
          <a href="#" [routerLink]="['/pediasure-gold']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="../../../assets/images/prodimg/2.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure<sup>®</sup>   GOLD Sucrose Free</h4>
          <span class="agePreference">1-10 years old</span>
          <p>
            Same nutrition goodness of PediaSure<sup>®</sup>  but now sucrose free! Available
            in delicious original flavor.
          </p>
          <a href="#" [routerLink]="['/pediasure-gold-sucrose-free']"
            >LEARN MORE</a
          >
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img class="max-100"src="../../../assets/images/prodimg/3.png" />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure<sup>®</sup>   10+</h4>
          <span class="agePreference">10-15 years old</span>
          <p>
            Complete, Balanced Nutrition for teens. Your strategy to help fuel
            your teen’s final growth spurt.
          </p>
          <a href="#" [routerLink]="['/product-detail']">LEARN MORE</a>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="../../../assets/images/prodimg/4.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure<sup>®</sup>   GOLD Ready-to-Drink</h4>
          <span class="agePreference">1-10 years old</span>
          <p>
            Complete, Balanced Nutrition on-the-go! Available in Classic Vanilla
            and Premium Chocolate flavours.
          </p>
          <a href="#" [routerLink]="['/pediasure-ready-to-drink']"
            >LEARN MORE</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
