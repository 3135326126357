<!-- <div id="loadingMessage"></div> 
<canvas id="canvas"  hidden></canvas>
<div id="output" hidden>
  <div id="outputMessage">No QR code detected.</div>
  <div hidden><b>Data:</b> <span id="outputData"></span></div>
</div> -->




<div class="wrapper">

  <section class="container" id="demo-content">
    <!-- <h1 class="title">Scan 1D/2D Code from Video Camera</h1>

    <p>
      <a class="button-small button-outline" href="../../index.html">HOME 🏡</a>
    </p>

    <p>This example shows how to scan any supported 1D/2D code with ZXing javascript library from the device video
      camera. If more
      than one video input devices are available (for example front and back camera) the example shows how to read
      them and use a select to change the input device.</p>

    <div>
      <a class="button" id="startButton">Start</a>
      <a class="button" id="resetButton">Reset</a>
    </div> -->

    <div>
      <video id="video" width="320" height="400"></video>
    </div>

    <!-- <div id="sourceSelectPanel" style="display:none">
      <label for="sourceSelect">Change video source:</label>
      <select id="sourceSelect" style="max-width:400px">
      </select>
    </div> -->

    <label>Result:</label>
    <pre><code id="result"></code></pre>

    <!-- <p>See the <a href="https://github.com/zxing-js/library/tree/master/docs/examples/multi-camera/">source code</a>
      for this example.</p>
  </section>

  <footer class="footer">
    <section class="container">
      <p>ZXing TypeScript Demo. Licensed under the <a target="_blank"
          href="https://github.com/zxing-js/library#license" title="MIT">MIT</a>.</p>
    </section>
  </footer> -->
</section>
</div>