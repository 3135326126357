import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { BugsnagService } from "src/app/services/bugsnag.service";

@Component({
  selector: "app-family-rewards",
  templateUrl: "./family-rewards.component.html",
  styleUrls: ["./family-rewards.component.scss"],
})
export class FamilyRewardsComponent implements OnInit {
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent: any = false;
  togg = true;
  iSprintData: any;
  category: any;
  brand: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.getCustomer()
    this.brand = this.route.snapshot.params.name
  }
  onArrowUp() {
    window.scrollTo({ top: 0 });
  }
  toggleDivSize() {
    this.togg = !this.togg;
  }

  isEmpty(value: any) {
    if (value == undefined || null || '') return 'true'
    if (value == 'true') return 'true'
    if (value == 'false') return 'false'
    return value;
  }

  onClickLoginSignup() {
    let showGlucernaPopup = this.isEmpty(sessionStorage.getItem('showGlucernaPopup'))
    if (this.brand == 'glucerna') {
      if (showGlucernaPopup == 'true') this.router.navigate(['glucerna/announcements'])
      else this.router.navigate(['/login-signup'])
    } else {
      this.router.navigate(['/login-signup'])
    }
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }
}
