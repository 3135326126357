import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgOtpInputModule } from "ng-otp-input";
import { ProfileComponent } from "./profile/profile.component";
import { AccountComponent } from "./profile/account/account.component";
import { EditaccountComponent } from "./profile/account/editaccount/editaccount.component";
import { ChangeMobileComponent } from "./profile/account/change-mobile/change-mobile.component";
import { FamilyRewardsComponent } from "./about-rewards-programme/family-rewards/family-rewards.component";
import { FaqComponent } from "./about-rewards-programme/faq/faq.component";
import { HeaderComponent } from "./shared/header/header.component";
import { BottomMenuComponent } from "./about-rewards-programme/bottom-menu/bottom-menu.component";
import { ChangePasswordComponent } from "./profile/account/change-password/change-password.component";
import { ManageAddressComponent } from "./profile/account/manage-address/manage-address.component";
import { TransactionHistoryComponent } from "./profile/account/transaction-history/transaction-history.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { ProductListComponent } from "./products/product-list/product-list.component";
import { ProductDetailComponent } from "./products/product-detail/product-detail.component";
import { OtpComponent } from "./login-signup/otp/otp.component";
import { ForgotPasswordComponent } from "./login-signup/forgot-password/forgot-password.component";
import { CountdownModule } from "ngx-countdown";
import { TestimonialComponent } from "./products/testimonial/testimonial.component";
import { BuyLocalComponent } from "./products/buy-local/buy-local.component";
import { LandingComponent } from "./rewards/landing/landing.component";
import { RewardsBrandSelectionComponent } from "./rewards/landing/rewards-brand-selection/rewards-brand-selection.component";
import { RewardsCheckoutComponent } from "./rewards-checkout/rewards-checkout.component";
import { CameraComponent } from "./scan/camera/camera.component";
import { SuccessComponent } from "./scan/success/success.component";
import { DashboardComponent } from "./scan/dashboard/dashboard.component";
import { DisclaimerComponent } from "./scan/disclaimer/disclaimer.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { LoginComponent } from "./login-signup/login/login.component";
import { SignUpComponent } from "./login-signup/sign-up/sign-up.component";
import { LoginSignupComponent } from "./login-signup/login-signup/login-signup.component";
import { SignupSuccessComponent } from "./login-signup/signup-success/signup-success.component";
import { MenuComponent } from "./shared/menu/menu.component";
import { InitialDashboardComponent } from "./initial-dashboard/initial-dashboard.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import { QrCodeScannerComponent } from "./qr-code-scanner/qr-code-scanner.component";
import { TermsConditionsComponent } from "./about-rewards-programme/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./about-rewards-programme/privacy-policy/privacy-policy.component";
import { PediasureGoldDetailComponent } from "./products/pediasure-gold-detail/pediasure-gold-detail.component";
import { PediasureReadyToDrinkComponent } from "./products/pediasure-ready-to-drink/pediasure-ready-to-drink.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { FormsModule } from "@angular/forms";
import { PopupModule } from "@ciri/ngx-popup";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PediasureGoldSucroseFreeComponent } from "./products/pediasure-gold-sucrose-free/pediasure-gold-sucrose-free.component";
import { ValidateReceiptComponent } from "./validate-receipt/validate-receipt.component";
import { WebcamModule } from "ngx-webcam";
import { TestQrComponent } from "./test-qr/test-qr.component";
import { BlockNoticeComponent } from "./block-notice/block-notice.component";
// import { StrengthMeterModule } from "ngx-strength-meter";
import { Ng9PasswordStrengthBarComponent } from "./password-strength/password-strength.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { SiteSwitcherComponent } from "./site-switcher/site-switcher.component";
import { AboutEnsureGoldComponent } from "./ensure/about-ensure-gold/about-ensure-gold.component";
import { ProductBenefitsComponent } from "./ensure/product-benefits/product-benefits.component";
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RewardCheckoutComponent } from './reward-checkout/reward-checkout.component';
import { PointsAddedComponent } from './points-added/points-added.component';
import { SelectBrandComponent } from './select-brand/select-brand.component';
import { SelectScannerComponent } from './select-scanner/select-scanner.component';
import { EnsureGScannerComponent } from './ensure-g-scanner/ensure-g-scanner.component';
import { SignupScanSuccessComponent } from './signup-scan-success/signup-scan-success.component';
import { RewardDetailsComponent } from './reward-details/reward-details.component';
import { RewardRedemptionSuccessfulComponent } from './reward-redemption-successful/reward-redemption-successful.component';
import { AboutGlucernaComponent } from './glucerna/about-glucerna/about-glucerna.component';
import { ProductFeaturesComponent } from './glucerna/product-features/product-features.component';
import { HowToDrinkComponent } from './glucerna/how-to-drink/how-to-drink.component';
import { HowMuchComponent } from './glucerna/how-much/how-much.component';
import { GlucernaMenuComponent } from './glucerna/menu-glucerna/glucerna-menu.component';
import { DashboardGlucernaComponent } from './glucerna/dashboard-glucerna/dashboard-glucerna.component'
import { AnnouncementsComponent } from './glucerna/announcements/announcements.component';
import { GlucernaTransactionHistoryComponent } from './glucerna/glucerna-transaction-history/glucerna-transaction-history.component';
import { GlucernaRewardsDetailsComponent } from './glucerna/rewards/glucerna-rewards-details/glucerna-rewards-details.component';
import { GlucernaRewardsLandingComponent } from './glucerna/rewards/glucerna-rewards-landing/glucerna-rewards-landing.component';
import { GlucernaSuccessComponent } from './glucerna/scan/glucerna-success/glucerna-success.component';
import { GlucernaSelectStoreComponent } from './glucerna/scan/glucerna-select-store/glucerna-select-store.component';
import { GlucernaRewardRedemptionSuccessfulComponent } from './glucerna/rewards/glucerna-reward-redemption-successful/glucerna-reward-redemption-successful.component';
import { ProgressBadgePediasureComponent } from './pediasure/progress-badge-pediasure/progress-badge-pediasure.component';
import { ProgressBadgeClaimedPediasureComponent } from './pediasure/progress-badge-claimed-pediasure/progress-badge-claimed-pediasure.component';
import { PointsSuccessPediasureComponent } from './pediasure/points-success-pediasure/points-success-pediasure.component';
import { PediasureRewardRedemptionSuccessfulComponent } from './pediasure/rewards/pediasure-reward-redemption-successful/pediasure-reward-redemption-successful.component';
import { PediasureRewardsLandingComponent } from './pediasure/rewards/pediasure-rewards-landing/pediasure-rewards-landing.component';
import { PediasureRewardsDetailsComponent } from './pediasure/rewards/pediasure-rewards-details/pediasure-rewards-details.component';
import { PediasureTransactionHistoryComponent } from './pediasure/pediasure-transaction-history/pediasure-transaction-history.component';
import { DashboardPediasureComponent } from './pediasure/dashboard-pediasure/dashboard-pediasure.component';
import { AboutPediasureComponent } from './pediasure/about-pediasure/about-pediasure.component';
import { DailyLogPediasureComponent } from './pediasure/daily-log-pediasure/daily-log-pediasure.component';
import { DailyLogsPointsConfirmationComponent } from './pediasure/daily-logs-points-confirmation/daily-logs-points-confirmation.component';
import { MenuPediasureComponent } from './pediasure/menu-pediasure/menu-pediasure.component';
import { GlucernaRewardsCheckoutComponent } from './glucerna/rewards/glucerna-rewards-checkout/glucerna-rewards-checkout.component';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { PediasureRewardsCheckoutComponent } from './pediasure/pediasure-rewards-checkout/pediasure-rewards-checkout.component'
import { environment as env } from '../environments/environment';
import { AlphabetOnlyDirective } from '../app/login-signup/sign-up/alphabet-only.directive';
import { SimilacDashboardComponent } from './similac/similac-dashboard/similac-dashboard.component';
import { MenuSimilacComponent } from './similac/menu-similac/menu-similac.component';
import { SimilacRewardLandingComponent } from './similac/rewards/similac-reward-landing/similac-reward-landing.component';
import { SimilacRewardDetailsComponent } from './similac/rewards/similac-reward-details/similac-reward-details.component';
import { SimilacRewardCheckoutComponent } from './similac/rewards/similac-reward-checkout/similac-reward-checkout.component';
import { SimilacRewardRedemptionSuccessfulComponent } from './similac/rewards/similac-reward-redemption-successful/similac-reward-redemption-successful.component';
import { SimilacTransactionHistoryComponent } from "./similac/similac-transaction-history/similac-transaction-history.component";
import { SelectStoreComponent } from "./similac/select-store/select-store.component";
import { AboutSimilacComponent } from "./similac/about-similac/about-similac.component";
import { EligibleEnsureGoldComponent } from './ensure/eligible-ensure-gold/eligible-ensure-gold.component';

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

Bugsnag.start({
  apiKey: 'cde8cc47602faa85346b77665aac05c2',
  appVersion: env.bugsnag_version,
  collectUserIp: false
})

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    AccountComponent,
    EditaccountComponent,
    ChangeMobileComponent,
    FamilyRewardsComponent,
    FaqComponent,
    HeaderComponent,
    BottomMenuComponent,
    ChangePasswordComponent,
    ManageAddressComponent,
    TransactionHistoryComponent,
    ComingSoonComponent,
    ProductListComponent,
    ProductDetailComponent,
    OtpComponent,
    ForgotPasswordComponent,
    TestimonialComponent,
    BuyLocalComponent,
    LandingComponent,
    RewardsBrandSelectionComponent,
    RewardsCheckoutComponent,
    CameraComponent,
    SuccessComponent,
    DashboardComponent,
    DisclaimerComponent,
    WelcomeComponent,
    LoginComponent,
    SignUpComponent,
    LoginSignupComponent,
    SignupSuccessComponent,
    MenuComponent,
    InitialDashboardComponent,
    QrCodeScannerComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    PediasureGoldDetailComponent,
    PediasureReadyToDrinkComponent,
    PediasureGoldSucroseFreeComponent,
    ValidateReceiptComponent,
    TestQrComponent,
    BlockNoticeComponent,
    Ng9PasswordStrengthBarComponent,
    SiteSwitcherComponent,
    AboutEnsureGoldComponent,
    ProductBenefitsComponent,
    ContactUsComponent,
    RewardCheckoutComponent,
    PointsAddedComponent,
    SelectBrandComponent,
    SelectScannerComponent,
    EnsureGScannerComponent,
    SignupScanSuccessComponent,
    RewardDetailsComponent,
    RewardRedemptionSuccessfulComponent,
    AboutGlucernaComponent,
    ProductFeaturesComponent,
    HowToDrinkComponent,
    HowMuchComponent,
    GlucernaMenuComponent,
    DashboardGlucernaComponent,
    AnnouncementsComponent,
    GlucernaTransactionHistoryComponent,
    GlucernaRewardsDetailsComponent,
    GlucernaRewardsLandingComponent,
    GlucernaSuccessComponent,
    GlucernaSelectStoreComponent,
    GlucernaRewardRedemptionSuccessfulComponent,
    ProgressBadgePediasureComponent,
    ProgressBadgeClaimedPediasureComponent,
    PointsSuccessPediasureComponent,
    PediasureRewardRedemptionSuccessfulComponent,
    PediasureRewardsLandingComponent,
    PediasureRewardsDetailsComponent,
    PediasureTransactionHistoryComponent,
    DashboardPediasureComponent,
    AboutPediasureComponent,
    DailyLogPediasureComponent,
    DailyLogsPointsConfirmationComponent,
    MenuPediasureComponent,
    GlucernaRewardsCheckoutComponent,
    PediasureRewardsCheckoutComponent,
    AlphabetOnlyDirective,
    SimilacDashboardComponent,
    MenuSimilacComponent,
    SimilacRewardLandingComponent,
    SimilacRewardDetailsComponent,
    SimilacRewardCheckoutComponent,
    SimilacRewardRedemptionSuccessfulComponent,
    SimilacTransactionHistoryComponent,
    SelectStoreComponent,
    AboutSimilacComponent,
    EligibleEnsureGoldComponent
  ],
  exports:[MenuSimilacComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ImageCropperModule,
    //StrengthMeterModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    NgOtpInputModule,
    CountdownModule,
    PopupModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    WebcamModule,
  ],
  providers: [HttpClientModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
