import { Component, OnInit,ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { first } from "rxjs/operators";
import { ApiService } from "src/app/service/api.service";
import { BugsnagService } from "src/app/services/bugsnag.service";
import { environment as env } from "../../../environments/environment";
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.scss"],
})
export class DisclaimerComponent implements OnInit {
  customerData: any;
  customerName: string;
  suspiciousCount: number = 0;
  iSprintData: any;
  latitude: string;
  longitude: string;
  confidence: string;
  scanCode: string;
  prodImage: any;
  weight: any;
  isWeightEmpty: boolean;
  categoryCode: string;
  country: any;
  @ViewChild('popupModal', { static: true }) popupModal: ModalDirective;


  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    // this.isprintData =  JSON.parse(sessionStorage.getItem('iSprintData'));
  }

  ngOnInit(): void {
    this.getCustomer();
    this.getPosition();

    this.iSprintData = sessionStorage.getItem("iSprintData");
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData, "this.iSprintData ");
    this.scanCode = sessionStorage.getItem("scannedCode");
    this.categoryCode = sessionStorage.getItem('categoryCode');
    console.log(this.scanCode, "this.scanCode");

    this.weight = this.iSprintData?.size;
    //this.weight = '05X0200GRS';
    this.isWeightEmpty = this.isEmpty(this.weight);
    if (this.isWeightEmpty) this.weight = this.convertWeightToKilograms(this.weight)
    else {
      this.weight = 0;
    }
    console.log("weight: ", this.weight);
    this.weight = JSON.stringify(this.weight);
    sessionStorage.setItem("productWeight", this.weight);
    this.spinner.show();
    this.apiService.getGpasProductImage(this.iSprintData?.sku).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.prodImage = res.data[0]?.productimage;
        console.log(this.prodImage, "getGpasProductImage");
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      }
    );
  }

  isEmpty(weight) {
    if (weight == "" || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }

  convertWeightToKilograms(weight) {
    let grams = 0;
    if (weight.includes("x")) {
      const parts = weight.split("x");
      grams = parseFloat(parts.reduce((a, b) => a * parseFloat(b.trim()), 1));
    }
    else if (weight.includes("X")) {
      const parts = weight.split("X");
      grams = parseFloat(parts.reduce((a, b) => a * parseFloat(b.trim()), 1));
    }
    else if (weight.includes("GRS")) {
      grams = parseFloat(weight.replace("GRS", ""));
    }
    else if (weight.includes("GS")) {
      grams = parseFloat(weight.replace("GS", ""));
    }
    else if (weight.includes("G") && !weight.includes("GS") && !weight.includes("GRS")) {
      grams = parseFloat(weight.replace("G", ""));
    }
    const kilograms = grams / 1000;
    return kilograms;
  }


  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
    });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  onClickContinue() {
    //gpass
    if (this.customerName) {
      let mobile = sessionStorage.getItem("mobileNo");

      let token = sessionStorage.getItem("authToken");
      this.country = this.iSprintData?.country?.toLowerCase();
      if (this.iSprintData?.is_isprint) {
        let parsedPrimaryValue = this.parseFunction(
          this.iSprintData.product_name
        ).en_US;
        let prdname = parsedPrimaryValue;

        let formData = {
          scan_code: this.scanCode,
          mobile: mobile,
          cap_device_id: env.DEVICE_ID,
          customer_name: this.customerName,
          suspicious_count: this.suspiciousCount,
          product_name: prdname,
          product_image:
            this.iSprintData?.product_image == undefined ||
              this.iSprintData?.product_image == null
              ? "null"
              : this.iSprintData?.product_image,
          confidence: this.confidence,
          child_count: "1",
          // "child_count": this.kidsLength,
          weight:
            this.weight == undefined || this.weight == null ? "0" : this.weight,

          custom_fields: [
            {
              name: "translat",
              value: this.latitude,
            },
            {
              name: "translong",
              value: this.longitude,
            },
            {
              name: "productname",
              value: prdname ? prdname : "PediaSure",
            },
            {
              name: "categorycode",
              value: prdname ? prdname : "PediaSure",
            },
            {
              name: "sfproductcode",
              value: this.iSprintData.sku ? this.iSprintData.sku : "Null",
            },
          ],
        };
        console.log(formData, "form data");
        this.spinner.show();
        this.apiService
          .postGpassEarnPoints(formData, token)
          .pipe(first())
          .subscribe(
            (res: any) => {
              this.spinner.hide();
              if (res["status"] == "success") {
                console.log(res);
                this.router.navigate(["scan/success"]);
              }
            },
            (err) => {
              this.spinner.hide();
              console.log(err);
              if (err?.error?.code == 420) {
                alert(err?.error?.message);
              } else {
                alert(err?.error?.message);
                console.log(err?.error?.message);
              }
              if (this.categoryCode.startsWith('ensure')) this.router.navigate(["dashboard"]);
              else if (this.categoryCode.startsWith('glucerna')) this.router.navigate(['glucerna/dashboard'])
              else if (this.categoryCode.startsWith('pediasure')) this.router.navigate(['/pediasure/dashboard'])
              else if (this.categoryCode.startsWith('similac')) this.router.navigate(['/similac/dashboard'])

              this.bsService.notifyError(err, formData);
            }
          );

      } else {
        
        this.apiService
          .getGpasProductImage(this.iSprintData.sku)
          .subscribe((res: any) => {
            console.log(res.data, "getGpasProductImage");
            this.prodImage = res.data[0]?.image;
          }, err => {
            this.bsService.notifyError(err);
            this.spinner.hide()
          });
        let formData = {
          scan_code: this.scanCode,
          mobile: mobile,
          customer_name: this.customerName,
          suspicious_count: this.suspiciousCount,
          product_name:
            this.iSprintData.brand +
            " " +
            this.iSprintData.flavor +
            " " +
            this.iSprintData.size,
          product_image:
            this.prodImage == undefined || this.prodImage == null
              ? "null"
              : this.prodImage,
          latitude: this.latitude,
          longitude: this.longitude,
          confidence: this.confidence,
          //   "child_count": this.kidsLength,
          weight:
            this.weight == undefined || this.weight == null ? "0" : this.weight,
          child_count: "1",
          custom_fields: [
            {
              name: "productname",
              value:
                this.iSprintData.brand +
                " " +
                this.iSprintData.flavor +
                " " +
                this.iSprintData.size,
            },
            {
              name: "sfproductcode",
              value: this.iSprintData.sku ? this.iSprintData.sku : "Null",
            },
          ],
          //"weight": this.weight,
        };
        console.log(formData, "gaps form data");
        this.spinner.show();
        let country = this.iSprintData?.country?.toLowerCase().trim() || this.iSprintData?.country_of_origin.toLowerCase().trim()
        let countries = this.iSprintData?.countries || []
          if (country == 'philippines') {
            this.apiService
            .postGpassEarnPoints(formData, token)
            .pipe(first())
            .subscribe(
              (res: any) => {
                this.spinner.hide();
                console.log(res);
                if (res["status"] == "success") {
                  this.router.navigate(["scan/success"]);
                }
              
              },
              (err) => {
                this.spinner.hide();
                console.log(err);
                if (err?.error?.code == 420) {
                  alert(err?.error?.message);
                } else {
                  alert(err?.error?.message);
                  console.log(err?.error?.message);
                }
                if (this.categoryCode.startsWith('ensure')) this.router.navigate(["dashboard"]);
                else if (this.categoryCode.startsWith('glucerna')) this.router.navigate(['glucerna/dashboard'])
                else if (this.categoryCode.startsWith('pediasure')) this.router.navigate(['/pediasure/dashboard'])
                else if (this.categoryCode.startsWith('similac')) this.router.navigate(['/similac/dashboard'])
                this.bsService.notifyError(err, formData);
              }
          );
          }else if(country == 'shared' && countries.includes('philippines')){
                this.apiService
              .postGpassEarnPoints(formData, token)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  this.spinner.hide();
                  console.log(res);
                  if (res["status"] == "success") {
                    this.router.navigate(["scan/success"]);
                  }
                
                },
                (err) => {
                  this.spinner.hide();
                  console.log(err);
                  if (err?.error?.code == 420) {
                    alert(err?.error?.message);
                  } else {
                    alert(err?.error?.message);
                    console.log(err?.error?.message);
                  }
                  if (this.categoryCode.startsWith('ensure')) this.router.navigate(["dashboard"]);
                  else if (this.categoryCode.startsWith('glucerna')) this.router.navigate(['glucerna/dashboard'])
                  else if (this.categoryCode.startsWith('pediasure')) this.router.navigate(['/pediasure/dashboard'])
                  else if (this.categoryCode.startsWith('similac')) this.router.navigate(['/similac/dashboard'])
                  this.bsService.notifyError(err, formData);
                }
            );
          

          }else{
          // show popup  invalid qr code
          this.spinner.hide()
          this.popupModal.show()
         }
      }
    }

  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        // Block the user
        let block = res["customers"]["customer"][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == "block_count";
        });
        console.log(blockUser, "blockUser===");
        if (blockUser[0]?.value == "1") {
          sessionStorage.clear();
          this.router.navigate(["/block-notice"]);
        }
        this.spinner.hide();
        this.customerData = res["customers"]["customer"][0];
        console.log(this.customerData);
        this.customerName =
          this.customerData.firstname + " " + this.customerData.lastname;
        console.log(this.customerName);
        this.customerData.custom_fields.field.forEach((element) => {
          if (element.name === "suspicious_count") {
            this.suspiciousCount = parseInt(element.value);
          }
        });
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      }
    );
    console.log(this.suspiciousCount, "suspiciousCount");
  }

  onClickExit() {
    if (this.categoryCode.startsWith('ensure')) this.router.navigate(["dashboard"]);
    else if (this.categoryCode.startsWith('glucerna')) this.router.navigate(['glucerna/dashboard'])
    else if (this.categoryCode.startsWith('pediasure')) this.router.navigate(['/pediasure/dashboard'])
    else if (this.categoryCode.startsWith('similac')) this.router.navigate(['/similac/dashboard'])
  }
  onClickOK() {
    this.popupModal.hide()
  }
}
