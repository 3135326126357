<app-header title="Reward checkout"></app-header>
<section class="">
  <section class="p-4" *ngIf="!showSuccessScreen">
    <div>
      <p class="fs20 fW900">Redemption cart</p>
    </div>
    <div class="">
      <div class="white-bg-card">
        <div class="yello-bg-card d-flex">
          <div class="prod-img">
            <div class="reward-points">
              <p class="color-white m-0 text-center">
                {{ rewardInfo?.intouchPoints }} pts
              </p>
            </div>
            <div>
              <img
                [src]="rewardInfo?.imageUrl"
                *ngIf="rewardInfo?.imageUrl != null"
              />
              <img
                class="gift-away-img"
                src="../../../../assets/images/GALogoColored.png"
                *ngIf="rewardInfo?.imageUrl == null"
              />
            </div>
          </div>
          <div class="col-9 prod-content">
            <p class="mb-0 mt-0 fW900">{{ rewardInfo?.name }}</p>
            <!-- <p class="mb-0 mt-0">
              Ensure Gold<sup>®</sup> P200 Giftaway Voucher
            </p> -->
            <div class="d-flex mt-0">
              <span class="fs8 mt-2 fW900">QUANTITY</span>
              <span class="plus-minus-signs ms-3"
                ><img
                  (click)="onDecrement(count)"
                  [class.disabled]="disabled === true"
                  src="../../../../assets/images/Group 5356.png"
                  alt=""
                  width="20"
                  height="20"
              /></span>
              <span class="fs13 mt-1 ms-3">{{ count }}</span>
              <span class="plus-minus-signs ms-3" (click)="onIncrement(count)"
                ><img
                  src="../../../../assets/images/Group 5355.png"
                  alt=""
                  width="20"
                  height="20"
              /></span>
            </div>
          </div>
        </div>
        <div class="text-right">
          <p class="text-muted fs12 mb-0 d-inline" (click)="onClickRemove()">Remove</p>
        </div>
      </div>
      <div class="text-right mt-4">
        <p class="fs16">Total points to be used</p>
        <p class="font-blue fs24 fW900">
          {{ count * rewardInfo.intouchPoints }} pts
        </p>
      </div>
      <div class="mt-4">
        <button class="primaryBtn-lightBlue-bg fs20" (click)="onClickConfirm()">
          Confirm
        </button>
      </div>
    </div>
  </section>
  <div  *ngIf="insufficientPoints" class="error"
    >You dont have enough points to redeem this reward</div
  >
  <!-- remove item from cart -->
  <div
    bsModal
    #popupRemoveItemFromCart="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="">
            <div class="text-left content fW900">
              <div class="text-muted fW500">
                <p class="fs16">Remove this item from your cart?</p>
              </div>
            </div>
            <div class="yello-bg-card d-flex">
              <div class="prod-img">
                <div class="reward-points">
                  <p class="color-white m-0 text-center">
                    {{ rewardInfo.intouchPoints }} pts
                  </p>
                </div>
                <img
                  class="gift-away-img"
                  src="../../assets/images/GALogoColored.png"
                />
              </div>
              <div class="col-9 prod-content">
                <p class="mb-0 mt-0">{{ rewardInfo.name }}</p>
                <div class="d-flex mt-0 font-darkblue fW900 fs12 mt-2">
                  <span class="">x</span>
                  <!-- <span class="">{{ rewardInfo.quantity }}</span> -->
                  <span class="">{{ count }}</span>
                </div>
              </div>
            </div>
            <div class="mt25">
              <button
                type="submit"
                class="primaryBtn-lightBlue-bg fW500 Btn mt-3"
                (click)="onClickYes()"
              >
                <strong>Yes</strong>
              </button>
              <button
                type="submit"
                class="primaryBtn btn-white fW500 Btn mt-2"
                (click)="onClickClose()"
              >
                <strong>No</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- redeemption summary section -->
  <!-- <section class="p-4 pb100" *ngIf="showSuccessScreen">
    <div class="text-center">
      <img src="../../assets/images/Group 5532.svg" />
      <p class="font-darkblue fs32 mb-0">Redemption successful!</p>
      <p class="fs20 mb-0 lh29">
        Total points used: <span class="font-blue ms-1 fW600">
          {{ count * rewardInfo.intouchPoints }} 
       </span>
      </p>
      <p class="fs20 lh29">
        Your remaining points:<span class="font-blue ms-1 fW600">{{
          loyaltyPoints
        }}</span>
      </p>
      <!-- <p class="fs16">
        Your reward has been emailed to your registered email address.
      </p> -->
  <!-- <div>
        <p class="fs20 fW900 text-left mb-0">Redemption summary</p>
      </div>
      <div class="">
        <div class="white-bg-card-success-screen">
          <div class="yello-bg-card d-flex">
            <div class="prod-img">
              <div class="reward-points">
                <p class="color-white m-0">
                  {{ rewardInfo.intouchPoints }} pts
                </p>
              </div>
              <img class="ht" src="../../assets/images/png.png" />
            </div>
            <div class="col-9 prod-content">
              <p class="mb-0 mt-0 fW600">
                Ensure Gold<sup>®</sup> P200 Giftaway Voucher
              </p>
              <div class="d-flex mt-0 font-darkblue fW900 fs12 mt-3">
                <span class="">x</span>
                <span class="">{{ count }}</span>
              </div>
            </div>
          </div> -->
  <!-- <div class="yello-bg-card d-flex">
            <div class="prod-img">
              <div class="reward-points">
                <p class="color-white m-0">
                  {{ rewardInfo.intouchPoints }} pts
                </p>
              </div>
              <img src="../../../../assets/images/Ensure Gold.png" />
            </div>
            <div class="col-9 prod-content">
              <p class="mb-0 mt-0 text-left">
                {{ rewardInfo.name }}
              </p>
              <div class="d-flex mt-0">
                <div class="col-9 prod-content">
                  <p class="mb-0 mt-0">Ensure Gold<sup>®</sup> P200 Giftaway Voucher</p>
                  <div class="d-flex mt-0 font-darkblue fW900 fs12 mt-2">
                    <span class="">x</span>
                    <span class="">2</span>
                  </div>
                </div> -->
  <!-- <span class="fs8 mt-2 fW900">QUANTITY</span> -->
  <!-- <span class="plus-minus-signs ms-3"
                  ><img
                    src="../../../../assets/images/Group 5355.png"
                    alt=""
                    width="20"
                    height="20"
                /></span>
                <span class="fs13 mt-1 ms-3">{{ rewardInfo.quantity }}</span>
                <span class="plus-minus-signs ms-3"
                  ><img
                    src="../../../../assets/images/Group 5356.png"
                    alt=""
                    width="20"
                    height="20"
                />
              </span> -->
  <!-- </div>
            </div> -->
  <!-- </div> -->
  <!-- </div>
      </div>
      <p class="text-left mt-4">To check and/or use this reward, please click the
      <span class="bg-bl fW600 ">view reward details</span> button below.</p> -->
  <!-- <div class="text-right mt-4">
        <p class="fs16 mb-0">Total points used</p>
        <p class="font-blue fs24 fW900">
          {{ count * rewardInfo.intouchPoints }} pts
        </p>
      </div> -->
  <!-- <div class="mt-4">
        <button
        class="primaryBtn-lightBlue-bg fs20"
        [routerLink]="['/reward/details']"
      >
      View reward details
      </button>
      <div class="mt-2">
        <button
          class="primaryBtn-bg fs20"
          [routerLink]="['/dashboard']"
        >
          Back to dashboard
        </button>
      </div>
      <p class="text-center bottom">Per DOH-FDA CFRR Promo Permit XXXX s 2021</p>
      </div>
    </div> -->
  <!-- </section> -->
</section>
<app-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="brand"
  [rewardVisited]="rewardVisited"
></app-menu>
