<div class="pageHeader row mx-0 px30">
  <div
    class="
      col-12
      justify-content-center
      d-flex
      position-relative
      header-height
      align-items-center
      d-flex
    "
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>Daily Log</h1>
  </div>
</div>
<section class="globalContainer">
  <section class="daily-logs min-vh-100 bg">
    <div class="container">
      <div class="daily-logs-container">
        <div class="member-profile-section">
          <div class="row justify-content-center align-items-center">
            <div class="col-4 text-center justify-content-center">
              <img
                class="member-profile-icon"
                src="../../../assets/images/pediasure/member-profile-icon.svg"
              />
            </div>
            <div class="col-8 align-items-center justify-content-center">
              <h3><span class="memebr-profile-name">Miguel</span>’s</h3>
              <label>Daily Log</label>
            </div>
          </div>
          <p class="mt-3">
            Log your child's daily good growth behaviors! Observe and check
            <span>all four behaviors</span> to earn
            <span>one point daily.</span>
          </p>
        </div>
        <div class="daily-log-form">
          <p>
            Today is
            <span class="Todays-date">{{ myDate | date: "longDate" }}</span>
          </p>
          <!-- -------------------------- -->

          <form>
            <div *ngFor="let log of dailyLogs">
              <div
                style="pointer-events: none"
                class="form-group form-check form-item"
                [ngClass]="log.checked ? 'checked' : 'unchecked'"
              >
                <input
                  style="pointer-events: auto"
                  (click)="onClickCheck(log.orderid)"
                  type="checkbox"
                  class="form-check-input custom-checkbox check4"
                  id="drinkTwoGlasses"
                />
                <label class="form-check-label" for="drinkTwoGlasses"
                  >{{ log.values }}<sup *ngIf="log.orderid == 0">®</sup></label
                >
                <img class="item-icon mw-img-45" [src]="log.images" />
              </div>
            </div>

            <button
              type="submit"
              class="submit-btn btn btn-lg btn-block mt-4"
              (click)="onClickCheckFields()"
            >
              Submit today’s log
            </button>
          </form>

          <!-- ---------------------------------- -->
        </div>
      </div>
    </div>
  </section>
  <div class="bottom-bg"></div>
</section>

<div
  bsModal
  #notLoginOrSignup="bs-modal"
  class="modal fade customModalContainer px30 mt-30vh"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content br10 px15 py-3">
      <div class="modal-body pt-3 text-center">
        To access this feature, please log in or sign up to
        <a
          href="#"
          [routerLink]="['/about/family']"
          class="col-blue underline fW600"
        >
          Abbott Alaga Rewards</a
        >
      </div>
      <div class="text-center m-3">
        <button
          (click)="onClickLoginSignup()"
          class="primaryBtn mb-2 fW100"
          type="submit"
        >
          Log in / sign up now
        </button>
        <button
          (click)="onClickCancel()"
          class="primaryBtn btn-white fW100"
          type="submit"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<!-- =========== Progress Badges Popup started =========== -->

<div
  class="modal bg-modal-progress-badge2 px20"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content badge-modal-bg2">
      <div class="modal-body p-4">
        <div class="text-center content text-white">
          <div
            class="
              d-flex
              flex-column
              fs19
              text-center
              fW900
              badge-title-color
              mb-3
            "
          >
            <label>WEEK 4:</label> <span>BETTER APPETITE</span>
          </div>
          <img
            src="../../../assets/images/pediasure/badges/less_sick_days_unlocked.png"
          />
          <div class="fs16 mt-3">
            <p>
              Please confirm that your child has a better appetite after
              <span class="txt-color">4 weeks (28 days)</span> of drinking
              <span class="txt-color">PediaSure Plus®.</span>
            </p>
          </div>
        </div>

        <!-- <div class="text-center content text-white d-none">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 8:</label> <span>WEIGHT GAIN</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/weight_gain_unlocked.png" />
            <div class="fs16 mt-3">
              <p> Please confirm that your child's weight has increased after <span class="txt-color">8 weeks
                  (56 days)</span> of drinking <span class="txt-color fw-bold">PediaSure Plus®.</span></p>
            </div>
          </div>
         
          
          <div class="text-center content text-white d-none">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 12:</label> <span>HEIGHT GAIN</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/height_gain_unlocked.png" />
            <div class="fs16 mt-3">
              <p> Please confirm that your child's height has increased after <span class="txt-color">12 weeks
                  (84 days)</span> of drinking <span class="txt-color fw-bold">PediaSure Plus®.</span></p>
            </div>
          </div>
         
          
           <div class="text-center content text-white d-none">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 16:</label> <span>HEIGHT GAIN</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/better_appetite_unlocked.png" />
            <div class="fs16 mt-3">
              <p> Please confirm that your child has less sick days after <span class="txt-color">16 weeks
                  (112 days)</span> of drinking <span class="txt-color fw-bold">PediaSure Plus®.</span></p>
            </div>
          </div> -->

        <button type="submit" class="progress-badge-btn fW500 Btn mt-4">
          <strong>Confirm and claim this badge</strong>
        </button>
        <button
          type="submit"
          class="progress-badge-btn cancel-btn fW500 Btn mt-2"
        >
          <strong>Cancel</strong>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ============ progress badges popup end =========== -->

<app-menu-pediasure></app-menu-pediasure>

<div
  bsModal
  #CheckAllFields="bs-modal"
  class="modal fade customModalContainer px20 pediasure-popup bg-none"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content br10 px15 py-3">
      <label class="pt-3 text-center">
        <img
          class="alert-icon"
          src="../../../assets/images/pediasure/pediasue-alert-icon.svg"
        />INCOMPLETE LOGS!</label
      >
      <div class="modal-body pt-3 text-center">
        You need to complete the following first in order to submit your daily
        log:
      </div>
      <div class="daily-log-form" *ngFor="let log of dailyLogs">
        <div
          class="form-group form-check form-item"
          *ngIf="!log.checked"
          (click)="onClickCheckId(log.orderid)"
        >
          <label class="form-check-label" for="drinkTwoGlasses"
            >{{ log.values }}<sup *ngIf="log.orderid == 0">®</sup></label
          >
          <img class="item-icon mw-img-45" [src]="log.images" />
        </div>
      </div>

      <div class="text-center m-3">
        <button
          (click)="onClickOk()"
          class="ok-btn btn btn-lg btn-block mt-4"
          type="submit"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
