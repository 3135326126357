<section class="rewards-container bg-lighBlue min-vh-100">
  <div class="dasboard-profile-card pb-4 rewards-profileBg bbr20 bbl20">
    <div class="row mx-0 pu">
      <div class="d-flex justify-content-between mt20">
        <div class="mt-80 d-flex">
          <img
            src="../../../../assets/images/Group 5651.svg"
            (click)="onClickBack()"
          />
          <img
            class="ps-1"
            src="../../../../assets/images/glucerna/glucerna_profile_logo.png"
            alt="brand"
          />
        </div>
        <div class="text-right pe-3">
          <p class="pu mb-0 color-yellow">{{ customerName }}</p>
          <div class="d-flex justify-content-end">
            <a
              class="text-right color-white fs10 text-decoration-none me-2"
              routerLink="/profile"
              >My Profile
            </a>
            <img
              class=""
              src="../../../../assets/images/chevron-right-yellow.svg"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-5 px-25 mb-0">
        <div class="text-left">
          <p>Rewards</p>
        </div>
        <div class="color-white text-right pr-0 fs20">
          <p class="mb-0 fs10">Reward points</p>
          <p class="">{{ loyaltyPoints }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-4 my-1">
    <div class="d-flex my-2 col-10 mt-4">
      <p class="mb-0 fs20 fW600">Select your reward</p>
      <img
        (click)="onClickIicon()"
        class="pt4"
        src="../../../../assets/images/Group 5399.svg"
        alt=""
        width="30"
        height="30"
      />
    </div>
    <!-- <div class="mt20 col-2">
        <img src="../../assets/images/Group 5401.svg" width="50" height="50" />
      </div> -->

    <!-- Manual rewards -->

    <div class="" *ngFor="let product of productList; let i = index">
      <div class="white-bg-card mt-4">
        <div class="yello-bg-card d-flex">
          <div class="prod-img">
            <div class="reward-points">
              <p class="color-white m-0 text-center">
                {{ product.intouchPoints }} pts
              </p>
            </div>
            <img [src]="product?.imageUrl" />
          </div>
          <div class="col-9 prod-content">
            <p class="mb-0 mt-0 fW900">{{ product.name }}</p>
            <div class="d-flex mt-0">
              <span class="fs8 mt-2 fW900">QUANTITY</span>
              <span class="plus-minus-signs ms-3"
                ><img
                  (click)="onDecrement(i)"
                  [class.disabled]="product.disabled === true"
                  src="../../../../assets/images/Group 5356.svg"
                  alt=""
                  width="20"
                  height="20"
              /></span>
              <span class="fs13 mt-1 ms-3">{{ product.quantity }}</span>
              <span class="plus-minus-signs ms-3"
                ><img
                  (click)="onIncrement(i)"
                  src="../../../../assets/images/Group 5355.png"
                  alt=""
                  width="20"
                  height="20"
              /></span>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <button
            class="btn btn-primary fs12 get-reward-button"
            (click)="onClickGetReward(product)"
          >
            Get this reward
          </button>
        </div>
      </div>
    </div>

    <!-- Automated Rewards -->
    <!-- <div class="p-0 mb-100" *ngIf="couponData?.length > 0"> 
        <div class="white-card mt-4" *ngFor="let coupon of couponData">
          <div class="gold-bg-img">
            <img src="../../assets/images/gold-bg-card-img.png" />
  
            <div class="gift-away-icon">
              <div class="text-center">
                <p class="reward-points mb-0">200 pts</p>
              </div>
              <img
                src="../../assets/images/GALogoColored.png"
                width="50px"
                height="38px"
                class="mt-2"
              />
            </div>
  
            <div class="gift-content">
              <h6 class="font-darkBlue fW600 mb-3">₱200 Giftaway Voucher</h6>
              <p class="font-blue bg-white fs12">
                <img class="px-1" src="../../assets/images/link-icon.svg"/><span
                  class="fW600"
                  >{{coupon?.custom_properties?.custom_property[0]?.value}}{{coupon?.code}}</span
                >
              </p>
            </div>
          </div>
          <div class="text-dark text-center mt-2 fs12">
            Visit the link above to claim your voucher.
          </div>
          <div class="d-flex justify-content-around buttons mt-2">
            <button
              class="primaryBtn btn view-link"
              (click)="
                onClickViewLinkInNewTab(coupon?.custom_properties?.custom_property[0]?.value+coupon?.code)
              "
            >
              View link in new tab
              <img class="mb-1 px-1" src="../../assets/images/visit-link.png" />
            </button>
            <button
              class="primaryBtn btn-white copy-link fW900"
              (click)="onClickCopyLink(coupon?.custom_properties?.custom_property[0]?.value+coupon?.code)"
            >
              Copy link
            </button>
          </div>
        </div>
      </div>
      <div class="p-0" *ngIf="couponLenghth?.length == undefined"> 
        <h4>No Coupons are available</h4>
      </div> -->
  </div>

  <!-- redeeming rewards pop up -->
  <div
    bsModal
    #popupRedeem="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="">
            <div class="text-center content">
              <h4 class="font-darkblue fs20 text-center fW900">
                REDEEMING REWARDS
              </h4>

              <div class="">
                <div class="fs16">
                  For every purchase of of the following participating
                  <span class="font-darkblue fW600"> Glucerna<sup>®</sup></span>
                  product, you will earn equivalent points.

                  <ul class="font-darkblue fW600 text-left my-3">
                    <li>Glucerna<sup>®</sup> 900g - 125 points</li>
                    <li>Glucerna<sup>®</sup> 400g - 50 points</li>
                    <li>Glucerna<sup>®</sup> Tetra 6-Pack - 50 points</li>
                  </ul>
                  Accumulate a total of
                  <span class="font-darkblue fW600">200 points</span> to redeem
                  a <span class="font-darkblue fW600">P100 voucher!</span>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="primaryBtn-lightBlue-bg fW500 Btn mt-4"
              (click)="onClickClose()"
            >
              <strong>Close</strong>
            </button>
            <div class="mt-3 text-center">
              <p class="bottom">Per DOH-FDA CFRR Promo Permit 0009 s. 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sorry you don't have enough points - pop up -->
  <div
    bsModal
    #popupRewardAddedToCart="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="">
            <div class="text-left content fW900">
              <!-- <h4 class="font-darkblue fs20 text-center fW900">
                  REDEEMING REWARDS
                </h4> -->

              <div class="">
                <p class="fs16">
                  Sorry, you don’t have enough points to redeem this reward.
                </p>
              </div>
            </div>
            <div class="yello-bg-card d-flex">
              <div class="prod-img">
                <div class="reward-points">
                  <p class="color-white m-0 text-center">
                    {{ rewardInfo?.intouchPoints }} pts
                  </p>
                </div>

                <img
                  class="online-img"
                  [src]="rewardInfo?.imageUrl"
                  *ngIf="rewardInfo?.imageUrl != null"
                />
                <img
                  class="gift-img"
                  src="../../../../assets/images/GALogoColored.png"
                  *ngIf="rewardInfo?.imageUrl == null"
                />
              </div>
              <div class="col-9 prod-content">
                <p class="mb-0 mt-0">{{ rewardInfo?.name }}</p>
                <div class="d-flex mt-0 font-darkblue fW900 fs12">
                  <span class="mt5">x</span>
                  <span class="mt5">{{ rewardInfo?.quantity }}</span>
                </div>
              </div>
            </div>
            <div class="mt25">
              <div class="d-flex fs16 justify-content-between">
                <p class="">Points required</p>
                <p class="font-blue fW900">{{ pointsRequired }} pts</p>
              </div>
              <div class="d-flex fs16 justify-content-between">
                <p class="">Current points</p>
                <p class="font-blue fW900">{{ loyaltyPoints }} pts</p>
              </div>
              <div class="d-flex fs16 justify-content-between">
                <p class="">Additional points needed</p>
                <p class="font-red fW900">{{ additionalPointsNeeded }} pts</p>
              </div>
            </div>
            <button
              type="submit"
              class="primaryBtn-lightBlue-bg fW500 Btn mt-4"
              (click)="onClickClose()"
            >
              <strong>Close</strong>
            </button>
            <!-- <div class="mt-3 text-center">
                <p class="fs10 mb-1 color-grey">
                  Per DOH-FDA CFRR Promo Permit XXXX s 2021
                </p>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- remove item from cart -->
  <div
    bsModal
    #popupRemoveItemFromCart="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="">
            <div class="text-left content fW900">
              <div class="text-muted fW500">
                <p class="fs16">Remove this item from your cart?</p>
              </div>
            </div>
            <div class="yello-bg-card d-flex">
              <div class="prod-img">
                <div class="reward-points">
                  <p class="color-white m-0 text-center">200 pts</p>
                </div>
                <img src="../../../../assets/images/Ensure Gold.png" />
              </div>
              <div class="col-9 prod-content">
                <p class="mb-0 mt-0">
                  Glucerna Gold<sup>®</sup> P100 Giftaway Voucher
                </p>
                <div class="d-flex mt-0 font-darkblue fW900 fs12 mt-2">
                  <span class="">x</span>
                  <span class="">2</span>
                </div>
              </div>
            </div>
            <div class="mt25">
              <button
                type="submit"
                class="primaryBtn-lightBlue-bg fW500 Btn mt-3"
                (click)="onClickClose()"
              >
                <strong>Yes</strong>
              </button>
              <button
                type="submit"
                class="primaryBtn btn-white fW500 Btn mt-2"
                (click)="onClickClose()"
              >
                <strong>No</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="globalContainer bg-lighBlue pb70">
    <div class="dashboard-container pb70">
      <div class="dasboard-profile-card pb-4 rewards-profileBg">
        <div class="row mx-0 px30">
          <div class="col-6 point-text pl-0">
            <p class="fs20 fW600 mt-3">Rewards</p>
          </div>
          <div class="col-6 text-right pr-0">
            <p class="point-text">Total Reward Points</p>
            <p class="points">{{ loyaltyPoints }}</p>
          </div>
        </div>
      </div>
      <div class="rewards-landing my-4 px30 pb-5">
        <p class="color-black fs20">
          Select {{ productName }}<sup>®</sup> product to redeem
        </p>
        <div *ngFor="let product of productList; let i = index">
          <div class="rewards-brands-card row mx-0 br10 bg-white mb-4">
            <div class="col-4 pl-0">
              <div class="reward-points text-center">
                <p class="color-white m-0">{{ product.intouchPoints }} pts</p>
              </div>
              <div class="brand-image text-center">
                <img
                  src="../../../../assets/images/pediasure-brand.png"
                  alt="Brand Image"
                />
                <img [src]="product?.imageUrl" alt="Brand Image" />
              </div>
            </div>
            <div class="col-8 brand-qty">
              <h4 class="fs16 font-darkblue mb-3 fW600">{{ product.name }}</h4>
              <div class="quantity">
                <p class="fs12 color-lightgrey mb-2">Quantity</p>
                <div class="position-relative">
                  <input
                    disabled
                    type="number"
                    [(ngModel)]="product.quantity"
                    value="{{ product.quantity }}"
                    name="qty"
                    class="w-100 fs13 qty"
                  />
                  <img
                    class="increment"
                    (click)="onIncrement(i)"
                    src="../../../../assets/images/arrow-up.png"
                  />
                  <img
                    class="decrement"
                    [class.disabled]="product.disabled === true"
                    (click)="onDecrement(i)"
                    src="../../../../assets/images/arrow-down.png"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <button
                class="primaryBtn Btn btn-white fs13 fW500 hl35"
                (click)="onClickGetReward(product)"
                type="button"
              >
                Get this reward
              </button>
            </div>
          </div>
        </div>
  
        <div class="rewards-brands-card row mx-0 br10 bg-white mb-4">
          <div class="col-4 pl-0">
            <div class="reward-points text-center">
              <p class="color-white m-0">232 pts</p>
            </div>
            <div class="brand-image text-center">
              <img
                src="../../../../assets/images/pediasure-brand.png"
                alt="Brand Image"
              />
            </div>
          </div>
          <div class="col-8 brand-qty">
            <h4 class="fs16 font-blue mb-3">PediaSure<sup>®</sup>   GOLD Vanilla 850g</h4>
            <div class="quantity">
              <p class="fs12 color-lightgrey mb-2">Quantity</p>
              <div class="position-relative">
                <input
                  type="number"
                  value="1"
                  name="qty"
                  class="w-100 fs13 qty"
                />
                <img
                  class="increment"
                  src="../../../../assets/images/arrow-up.png"
                />
                <img
                  class="decrement"
                  src="../../../../assets/images/arrow-down.png"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <button
              class="primaryBtn Btn btn-white fs13 fW500 hl35"
              [routerLink]="['/rewards/checkout']"
              type="button"
            >
              Get this reward
            </button>
          </div>
        </div>
      </div>
    </div>
  </section> -->
<app-glucerna-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="brand"
  [rewardVisited]="rewardVisited"
></app-glucerna-menu>
