<section class="globalContainer">
  <div class="dashboard-container profileBG">
    <div class="dasboard-profile-card pb-4">
      <div class="row mx-3 pb-4 pe-3">
        <div class="col-6">
          <img
            (click)="openHamberger()"
            src="../../../assets/images/similac/Group 5176.png"
            alt="img"
          />
        </div>
        <div class="col-6 text-right pr-0">
          <article>
            <div class="top" *ngIf="customerNamePresent">
              <p class="profile-name logLinkBig">
                <strong> {{ customerName }} </strong>
              </p>
              <div class="d-flex justify-content-end align-items-baseline">
                <p class="smallTxt" routerLink="/profile">My Profile</p>
                <img
                  class="ms-2"
                  src="../../../assets/images/similac/chevron-right-1px-dark.svg"
                />
              </div>
            </div>
            <div *ngIf="!customerNamePresent">
              <a
                style="color: #009cde"
                *ngIf="!customerNamePresent"
                class="logLink"
                (click)="onClickAccoutLogin()"
                ><strong>Sign up / login </strong>
                <span class="right-chev"
                  ><img
                    class="ms-2 right-chev"
                    src="../../../assets/images/similac/chevron-right-1px-dark.svg"
                /></span>
              </a>
            </div>
          </article>
        </div>
      </div>

      <div class="row mx-0 px30">
        <div class="col-6 point-text pl-0">
          <img
            class="mt-4"
            src="../../../assets/images/similac/SimilacLogo.svg"
            alt="logo"
          />
        </div>
        <div class="col-6 text-right pr-0">
          <!-- <p class="point-text mt-3 mb-2">
              Reward points
            </p>
            <p class="points">4,100 </p>  -->

          <p class="point-text mt-3 mb-2" *ngIf="customerNamePresent">
            Reward points
          </p>
          <p class="points mt-1" *ngIf="customerNamePresent">
            {{ loyaltyPoints }}
          </p>
        </div>
      </div>
    </div>
    <div class="dashboard-page bg-lightBlue btl30 btr30">
      <p class="fs20">
        <strong class="blue"
          >More about Similac<sup>®</sup> GainSchool<sup>®</sup></strong
        >
      </p>
      <div class="dashboard-card d-flex" (click)="onClickAboutSimilac()">
        <img src="../../../assets/images/Group 5577.svg" alt="Heart" />
        <p>About Similac<sup>®</sup> GainSchool<sup>®</sup></p>
      </div>
      <!-- <div class="dashboard-card d-flex" (click)="onClickProductBenefits()">
        <img src="../../../assets/images/similac/Group 5790.svg" alt="Heart" />
        <p>Product List</p>
      </div> -->
      <!-- <div class="dashboard-card d-flex mercury">
          <img src="../../../assets/images/Group 5579.svg" alt="Local" />
          <p>Mercury Drug E-card</p>
        </div> -->
    </div>
  </div>
</section>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-similac-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="currentBrand"
  [rewardVisited]="false"
></app-similac-menu>

<div
  bsModal
  #hambergermenu="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="modal-blue-bg">
          <div class="d-flex justify-content-between">
            <img
              (click)="closeHamberger()"
              src="../../../assets/images/Group 5176.svg"
            />

            <div class="" *ngIf="customerNamePresent">
              <p class="user-name fs20 m-0">{{ customerName }}</p>
              <div
                class="d-flex justify-content-end"
                [routerLink]="['/profile']"
              >
                <a class="my-profile fs10 mx-1 text-right">My Profile </a>
                <img src="../../../assets/images/chevron-right-1px.svg" />
              </div>
            </div>
            <div *ngIf="!customerNamePresent">
              <a
                *ngIf="!customerNamePresent"
                class="logLink"
                (click)="onClickAccoutLogin()"
                ><strong>Sign up / login > </strong></a
              >
            </div>
          </div>
          <div class="mt-4">
            <div class="text-left mx-3">
              <img src="../../../assets/images/ABBOTT LOGO-01.png" />
            </div>

            <div class="image-container mt-4">
              <div class="brand-box row justify-content-start">
                <div class="col-6">
                  <div class="ensure" (click)="onClickBrand('ensure')">
                    <div class="content" *ngIf="loyaltyPoints">
                      <p class="text-center">Reward Points</p>
                      <strong class="text-center">{{ loyaltyPoints }}</strong>
                    </div>
                  </div>
                </div>

                <!-- below div should be only shown when glucerna is DISABLED -->
                <div class="col-6">
                  <div
                    class="glucerna-enabled glucerna"
                    (click)="onClickBrand('glucerna')"
                  >
                    <div class="content" *ngIf="loyaltyPoints">
                      <p class="text-center">Reward Points</p>
                      <strong class="text-center">{{ loyaltyPoints }}</strong>
                    </div>
                  </div>
                </div>
                <!--Only for UAT release-->

                <div class="col-6">
                  <div class="pediasure" (click)="onClickBrand('pediasure')">
                    <div class="content" *ngIf="loyaltyPoints">
                      <p class="text-center">Reward Points</p>
                      <strong class="text-center">{{ loyaltyPoints }}</strong>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="similac" (click)="onClickBrand('similac')">
                    <div class="content" *ngIf="loyaltyPoints">
                      <p class="text-center">Reward Points</p>
                      <strong class="text-center">{{ loyaltyPoints }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center bottom">
              Per DOH-FDA CFRR Promo Permit 0009 s. 2022
            </p>
          </div>
        </div>
        <div class="modal-white-bg">
          <a [routerLink]="['/about/family']">About Abbott Alaga Rewards</a>
          <img src="../../assets/images/dot.svg" />
          <a [routerLink]="['/contact-us']"> Contact Us</a>
        </div>

        <div
          bsModal
          #glucernarewards="bs-modal"
          class="modal fade customModalContainer m-left-right"
          tabindex="-1"
          role="dialog"
          aria-labelledby="dialog-child-name"
        >
          <div class="modal-dialog modal-sm mx-auto modal-dialog-centered px-3">
            <div
              class="modal-content modal-content-glucerna-bg text-center br10 px30 py-3"
            >
              <div class="text-white">
                <h4 class="sub-head my-4">
                  Glucerna Rewards is now part of Abbott Alaga Rewards!
                </h4>

                <p>
                  If you have an existing Glucerna Rewards account, your reward
                  points have been automatically transferred to Abbott Alaga
                  Rewards under Glucerna® reward points.<br /><br />If you need
                  any assistance, feel free to
                  <span
                    ><a [routerLink]="['/contact-us']" class="btn-link-fce"
                      >Contact Us</a
                    ></span
                  >
                  .
                </p>
              </div>

              <div class="text-center">
                <button
                  class="primaryBtn fW100 continue-btn mt-4"
                  type="submit"
                  (click)="onClickGotIt()"
                >
                  Got it
                </button>
              </div>

              <div class="form-check ps-0 mt-3">
                <input
                  type="checkbox"
                  class="form-check-input"
                  class="check3"
                  (change)="checkValue($event)"
                />
                <label class="form-input-label text-white ms-2"
                  >Do not show this message again</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Pending transaction modal -->
<div
  bsModal
  #pendingTransPopup="bs-modal"
  class="modal fade customModalContainer px30 mt-30vh"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content pending br10 px15 py-3">
      <div class="modal-body pt-3 m-3 mb-0 text-left">
        <strong><img src="../../../assets/images/Group 5399.svg" alt=""> notice</strong>
       <p>Your recent scans need further action. please upload receipts for these scans from your <span> transaction history.</span></p> 
       
      </div>
      <div class="text-center m-3">
        <button
          [routerLink]="['/similac/transaction-history']"
          class="primaryBtn mb-2a fW100"
          type="submit"
        >
        Check transaction history now
        </button>
        <button
          (click)="onClickCancelpopup()"
          class="primaryBtn btn-white fW100"
          type="submit"
        >
          Check later
        </button>
      </div>
    </div>
  </div>
</div>