<section class="globalContainer pt-5 bg-Blue">
  <!-- <a (click)="onClickBack()" style="color: #fff; padding: 10px">
    <i style="padding: 5px" class="fa fa-angle-left"></i><span>Back</span>
  </a> -->
  <div class="comingSoonContainer mt-15vh px15">
    <div class="comingSoonCard text-center px15 text-white">
      <figure class="comingSoon-containter">
        <img class="comingSoon-logo" src="../assets/images/abbottlogo.png" alt="logo" />
      </figure>
      <h3>Coming Soon!</h3>
      <p>
        Thank you for your interest. Want to be informed when we launch? Please
        send us your email and we'll let you know.
      </p>

      <form
        [formGroup]="emailForm"
        (ngSubmit)="onSubmitEmailForm()"
        class="formContainer"
      >
        <div class="field">
          <input type="email" formControlName="email" placeholder="Email" class="email-input"/>
        </div>

        <div
          *ngIf="submittedEmailForm && emailFormVal.email.errors"
          class="error"
        >
          <span *ngIf="emailFormVal.email.errors.required"
            >Email is required</span
          >
          <span *ngIf="emailFormVal.email.errors.email">Invalid Email</span>
        </div>

        <div class="field">
          <button
            style="background-color: #fff"
            type="submit"
            class="primaryBtn Btn btn-white"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="abbott-rewards">
    <div class="rewards-inner">
      <!-- <img src="../assets/images/rewards.png" /> -->
      <div class="reward-content">
        <!-- <p>
          * Abbott Alaga Rewards program is restricted to consumers residing in
          Phillipines only.
        </p> -->
      </div>
    </div>
  </div>
</section>
