<app-header title="Scan"></app-header>
<section class="scan-container min-vh-60">
  <p class="mt-5">Where did you buy your product?</p>
  <div class="fieldset">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">
      <div class="in-field mt12">
        <select class="form-select mb-3" id="select1">
          <option>Physical Store</option>
        </select>
        <select class="form-select mb-3" id="select1">
          <option>Metro Manila</option>
        </select>
        
      </div>

      <!-- pills -->
      <div class="d-flex flex-wrap">
        <a href="#" class="btn-pills">Landmark</a>
        <a href="#" class="btn-pills active">Mercury Drug</a>
        <a href="#" class="btn-pills">Metro</a>
        <a href="#" class="btn-pills">Robinsons Supermarket</a>
        <a href="#" class="btn-pills">Southstar Drug</a>
        <a href="#" class="btn-pills">Watsons</a>
        <a href="#" class="btn-pills">Walter Mart</a>
      </div>

      <button type="submit" class="primary-btn mt30">Next</button>
    </form>
  </div>
</section>
