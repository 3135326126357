<app-header title="Contact Us"></app-header>
<section class="contact-us-container min-vh-60">
  <div class="fieldset">
    <div class="in-field">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">
        <div class="in-field mt12">
          <label for="email">Email</label>
          <input
            formControlName="email"
            name="email"
            type="email"
            class="pl20"
            required
            [pattern]="emailPattern"
            placeholder="jdc@gmail.com"
          />
        </div>

        <div *ngIf="submittedContactForm && contactFormVal.email.errors" class="error">
          <span *ngIf="contactFormVal.email.errors.required"
            >Please enter an email address</span
          >
          <span *ngIf="contactFormVal.email.errors.pattern">Invalid email</span>
        </div>
        <!-- <div *ngIf="contactFormVal.errors"> 
          <div *ngIf="contactFormVal.errors.required">
              Email required.
          </div> 		   
          <div *ngIf="contactFormVal.errors.pattern">
              Official Email not valid.
          </div> 
       </div>  -->

        <h1></h1>
        <h1></h1>
        <div class="in-field">
          <label for="subject">Subject</label>
          <input
            formControlName="subject"
            name="subject"
            type="subject"
            class="pl20 mt-2"
            placeholder="Ensure E-card Inquiry"
          />
        </div>
        <div *ngIf="submittedContactForm && contactFormVal.subject.errors" class="error">
          <span *ngIf="contactFormVal.subject.errors.required"
            >Please enter a subject.</span
          >
        </div>

        <div class="in-field">
          <label for="message">Message</label>
          <div class="msg-field">
            <textarea class="msg-field1" formControlName="message" id="message" name="message"></textarea>
          </div>
        </div>
        <div *ngIf="submittedContactForm && contactForm.get('message').hasError('required')" class="error">
          <span>Please enter some messages.</span>
        </div>
        
        
        <div class="row">
          <input
            formControlName="privacyPolicy"
            type="checkbox"
            id="I-agree"
            name="privacyPolicy"
            class="col-1 check-box"
            value="I-agree"
          />
          <p class="col-10 tx2">
            I agree to Abbott’s collection and use of the details provided in
            this form for marketing purposes in accordance with its
            <a [routerLink]="['/about/privacy-policy']">Privacy Policy</a>,
            which I have read and understood.
          </p>
        </div>
        <div *ngIf="submittedContactForm && contactFormVal.privacyPolicy.errors" class="error">
          <span *ngIf="contactFormVal.privacyPolicy.errors.required"
            >Please fill the requied fields.</span
          >
        </div>
        
        <button type="submit" class="primary-btn mt12">Send message</button>
      </form>
    </div>
  </div>
</section>
