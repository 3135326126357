<div class="pageHeader row mx-0 px30">
  <div
    class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
  >
    <a>
      <i class="fa fa-angle-left" (click)="onClickBack()"></i>
    </a>
    <h1>About Similac<sup>®</sup> GainSchool<sup>®</sup></h1>
  </div>
</div>
<section class="globalContainer">
  <section class="about-similac-gainschool min-vh-100 bg">
    <div class="d-flex mt16">
      <div class="gainschool-image col-12">
        <img
          class="gainschool"
          src="../../../../assets/images/similac/SimilacAboutLogo.png"
        />
        <img
          class="ripple"
          src="../../../../assets/images/similac/SimilacRipples.png"
        />
        <p class="ln-1">
          WITH
          <strong>PRODI-G™</strong> NUTRIENT SYSTEM
        </p>
        <p class="ln-2">DHA + LUTEIN + NVE + HMO + NUCLEOTIDES</p>
      </div>
    </div>
    <section class="bottom-description">
      <div class="row">
        <div class="info-section col-12">
          <div class="row">
            <div class="col-4 text-right bottom-gap">
              <img
                class="header-img"
                src="../../../../assets/images/similac/BrainSupport.png"
              />
            </div>
            <div class="col-8 header-text bottom-gap">
              <h3 class="fs24 boldness"><strong>BRAIN</strong></h3>
              <h4 class="fs12">SUPPORT NUTRIENTS</h4>
            </div>
            <div class="col-12 px20">
              <p>
                <b>Similac<sup>®</sup> GainSchool<sup>®</sup></b> has the unique
                <b>PRODI-G™</b> System.
              </p>
            </div>
            <div class="px30">
              <ul>
                <li>
                  Gangliosides, important lipids abundantly found in the brain<sup class="color-lightblue text-underline">2</sup>.
                </li>
                <li>
                  Contains 10 key <b>PRODI-G™</b> nutrients including DHA, AA,
                  Taurine, Choline, Iron, Zinc, Iodine, Folic Acid, and Vitamins
                  B<sub>1</sub> and B<sub>12</sub>.
                </li>
                <ul>
                  <li>
                    Source of Choline which helps support overall mental
                    function.
                  </li>
                  <li>
                    High in Vitamins B<sub>1</sub>, B<sub>2</sub>, B<sub>12</sub>, C, Magnesium and is a source
                    of Vitamin B6 which contribute to the normal functioning of
                    the nervous system.
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="info-section col-12">
          <div class="row">
            <div class="col-4 text-right bottom-gap">
              <img
                class="header-img"
                src="../../../../assets/images/similac/GrowthSupport.png"
              />
            </div>
            <div class="col-8 header-text bottom-gap">
              <h3 class="fs24 boldness"><strong>GROWTH</strong></h3>
              <h4 class="fs12">SUPPORT NUTRIENTS</h4>
            </div>
            <div class="col-12 px20">
              <p>
                <b>Similac<sup>®</sup> GainSchool<sup>®</sup></b> has essential
                nutrients to support growth:
              </p>
            </div>
            <div class="px30">
              <ul>
                <li>
                  Source of good quality protein to help in tissue building,
                  growth, and development.
                </li>
                <li>
                  High in Calcium to support development of strong bones and
                  teeth.
                </li>
                <li>
                  High in Vitamin D which helps support calcium absorption to
                  improve bone strength.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="info-section col-12">
          <div class="row">
            <div class="col-4 text-right bottom-gap">
              <img
                class="header-img"
                src="../../../../assets/images/similac/ImmunitySupport.png"
              />
            </div>
            <div class="col-8 header-text bottom-gap">
              <h3 class="fs24 boldness"><strong>IMMUNITY</strong></h3>
              <h4 class="fs12">SUPPORT NUTRIENTS</h4>
            </div>
            <div class="col-12 px20">
              <p>
                <b>Similac<sup>®</sup> GainSchool<sup>®</sup></b>
                contains 5HMO blend (2'-FL, LNT, 3-FL, 6'-SL, 3'-SL), which are
                important human milk oligosaccharides found in nature.<sup
                  class="color-lightblue text-underline"
                  >3-4</sup
                >
              </p>
            </div>
            <div class="px30">
              <ul>
                <li>
                  <b>Similac<sup>®</sup> GainSchool<sup>®</sup></b>
                  also provides 8.9 mg of Nucleotides per serving.
                </li>
                <li>
                  <b>Similac<sup>®</sup> GainSchool<sup>®</sup></b> is high in
                  Iron, Zinc, Vitamins A, B<sub>12</sub>, C, D and is a source of Selenium
                  which contribute to the normal functioning of your child's
                  immune system.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </section>
    <section class="legend-container">
      <div class="px50">
        <p class="legend-text">
          <span class="legend-no"><strong>2</strong></span
          >Schnaar RL, et al. Physiol Rev. 2014; 94(2): 461-518.
        </p>
        <p class="legend-text">
          <span class="legend-no"><strong>3</strong></span
          >Hill DR, Chow JM and Buck RH. Nutrients 2021; 13:3364.
        </p>
        <p class="legend-text">
          <span class="legend-no"><strong>4</strong></span
          >Kulinich A and Liu L. Carbohydr Res. 2016;432:62-70.
        </p>
      </div>
    </section>
  </section>
</section>

<app-similac-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="rewardInfo.name"
></app-similac-menu>
