import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-signup-success',
  templateUrl: './signup-success.component.html',
  styleUrls: ['./signup-success.component.scss'],
})
export class SignupSuccessComponent implements OnInit {
  scanCode: any;
  iSprintData: any;
  customerData: any;
  customerName: string;
  suspiciousCount: number;
  isScanCodeAvailable: any;
  invalidQR: any;
  customerNamePresent = false;
  category: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.scanCode = sessionStorage.getItem('scannedCode');
    this.isScanCodeAvailable = this.isEmpty(this.scanCode);
    this.invalidQR = sessionStorage.getItem('invalidQR')
    console.log(this.invalidQR, 'this.invalidQR')
    console.log(this.isScanCodeAvailable, 'this.isScanCodeAvailable')
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    this.category = this.iSprintData?.category_code?.toLowerCase()
    sessionStorage.setItem('categoryCode', this.category)
    if (this.category.startsWith('ensure')) this.category = "ensure"
    if (this.category.startsWith('pediasure')) this.category = "pediasure"
    if (this.category.startsWith('glucerna')) this.category = "glucerna"
    if (this.category.startsWith('similac')) this.category = "similac"
  }

  isEmpty(scanCode) {
    if (scanCode == '' || scanCode == null || scanCode == undefined)
      return false;
    else return true;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      // Block the user
      let block = res['customers']['customer'][0].custom_fields.field;
      let blockUser = block.filter((filt) => {
        return filt.name == 'block_count';
      });
      console.log(blockUser, 'blockUser===');
      if (blockUser[0]?.value == '1') {
        sessionStorage.clear();
        this.router.navigate(['/block-notice']);
      }
      this.spinner.hide();
      this.customerData = res['customers']['customer'][0];
      console.log(this.customerData);
      this.customerName =
        this.customerData.firstname + ' ' + this.customerData.lastname;
      console.log(this.customerName);
      this.customerData.custom_fields.field.forEach((element) => {
        if (element.name === 'suspicious_count') {
          this.suspiciousCount = parseInt(element.value);
        }
      });
    }), err => {
      console.log(err)
      this.spinner.hide();
      this.bsService.notifyError(err);
    };
    console.log(this.suspiciousCount, 'suspiciousCount');
  }

  onClickContinue() {
    this.router.navigate(['disclaimer']);
  }

  onClickScanNow() {
    this.router.navigate(['qr-scanner']);
  }

  onClickDashboard() {
    //this.router.navigate(['dashboard']);
    this.router.navigate(['site-switcher']);
  }
}
