<app-header title="About program"></app-header>
<section class="faqContainer familyBG pb70 min-vh-100">
  <h4 class="color-yellow mt-5 mb-4 fs20 px30">Frequently Asked Questions</h4>
  <div class="accordion pb70" id="faqAccordion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          About Abbott Alaga Rewards
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body">
          <div class="accordion" id="faqInnerAccordion-1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-1">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-1"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-1"
                >
                  What is Abbott Alaga Rewards program about?
                </button>
              </h2>
              <div
                id="collapseInner-1-1"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-1"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Abbott Alaga Rewards program is a loyalty program where
                    members can accumulate reward points with every purchase of
                    a participating product. Simply scan the unique QR code
                    printed under the can. The accumulated reward points can be
                    used to redeem full sized Abbott products across our
                    maternal, Child (Stage 3/4) and adult nutrition brands.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-2"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-2"
                >
                  Why should I join Abbott Alaga Rewards program?
                </button>
              </h2>
              <div
                id="collapseInner-1-2"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-2"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    We value you as a regular user of our products. When you are
                    part of the Abbott Alaga Rewards program, you will be
                    instantly rewarded with 50 reward points upon sign up, and
                    more importantly you can scan and receive reward points with
                    every purchase of a participating product! With your
                    accumulated points, you can redeem full sized Abbott
                    products.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-3"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-3"
                >
                  What perks can I enjoy by participating in the Abbott Alaga
                  Rewards program?
                </button>
              </h2>
              <div
                id="collapseInner-1-3"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-3"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Instantly receive 50 reward points upon sign up! With your
                    accumulated reward points, you can redeem full sized Abbott
                    products across our maternal, Child (Stage 3/4) and adult
                    nutrition brands.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-4">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-4"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-4"
                >
                  How does the reward point system work?
                </button>
              </h2>
              <div
                id="collapseInner-1-4"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-4"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    For all 850g, 1.6kg and 2.4kg products, reward points will
                    be awarded based on the official retail selling point of the
                    participating product. 1 retail dollar is equivalent to 1
                    reward point, e.g., a PediaSure<sup>®</sup> product that
                    cost $46.30 will give you 46 reward points.
                  </p>
                  <p>
                    For 400g products, regardless of official retail selling
                    price, one will earn 20 reward points with every scan.
                  </p>
                  <p>
                    Note that you are required to keep the original receipts of
                    all your purchases, up to when the reward points are
                    succesfully credited and reflected in your rewards system.
                    These original receipts might be needed for verification
                    purpose on an ad hoc basis.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-5">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-5"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-5"
                >
                  Is joining the Abbott Alaga Rewards program free?
                </button>
              </h2>
              <div
                id="collapseInner-1-5"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-5"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Yes, joining the Abbott Alaga Rewards program is free.
                    There is no fee involved to join the program. Simply
                    register with your mobile number to start being part of the
                    Abbott Alaga Rewards program.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-6">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-6"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-6"
                >
                  I bought the product on a discounted price during promotion.
                  How will this affect the number of reward points will I get?
                </button>
              </h2>
              <div
                id="collapseInner-1-6"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-6"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Even if the product is purchased at a discounted price, you
                    will be awarded reward points based on the product's
                    official retail selling price.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-7">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-7"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-7"
                >
                  Do I need to register as a member before I can accumulate
                  rewards or redeem products?
                </button>
              </h2>
              <div
                id="collapseInner-1-7"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-7"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Yes. You will have to be a registered member of the Abbott
                    Family Rewards program before you can start accumulating
                    reward points and to make redemptions.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-8">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-8"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-8"
                >
                  <span>
                    Why is PediaSure<sup>®</sup> the only brand eligible for
                    rewards points under Abbott Alaga Rewards program?</span
                  >
                </button>
              </h2>
              <div
                id="collapseInner-1-8"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-8"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Other Abbott brands will be coming on board the Abbott
                    Family Rewards program in different phases. For now,
                    PediaSure<sup>®</sup> range is the only participating brand.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-9">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-9"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-9"
                >
                  <span>
                    Why only selected PediaSure<sup>®</sup> products have QR
                    codes printed?</span
                  >
                </button>
              </h2>
              <div
                id="collapseInner-1-9"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-9"
                data-bs-parent="#faqInnerAccordion-1"
              >
                <div class="accordion-body">
                  <p>
                    Within each participating brand, different products are
                    coming on board the Abbott Alaga Rewards program in
                    different phases.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          QR Code Scanning
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body">
          <div class="accordion" id="faqInnerAccordion-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-1">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-1"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-1"
                >
                  Where do I find the QR code for scanning?
                </button>
              </h2>
              <div
                id="collapseInner-1-1"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-1"
                data-bs-parent="#faqInnerAccordion-2"
              >
                <div class="accordion-body">
                  <p>
                    For all products with a can/ tin packaging, you will find
                    the QR code printed on the bottom of the can/tin.
                  </p>
                  <p>
                    For products with a box packaging (eg, PediaSure<sup>®</sup>
                    2.4kg), you will find the QR code printed on the box's top
                    flap.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-2"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-2"
                >
                  I am a smartphone user, how can I scan the QR code?
                </button>
              </h2>
              <div
                id="collapseInner-1-2"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-2"
                data-bs-parent="#faqInnerAccordion-2"
              >
                <div class="accordion-body">
                  <p>
                    All smartphones have the ability to scan QR codes via their
                    camera application. Where relevant, check your phone setting
                    to ensure that the QR code reading feature is enabled in the
                    camera.
                  </p>
                  <p>
                    To start scanning, simply open the 'Camera' application, and
                    position the camera over the QR code of the can.
                    Ensure<sup>®</sup>that the QR code is centered and brought
                    on close up. Upon successful scanning, you will see a link
                    pop up from your application. Click on the link to open the
                    Abbott Alaga Rewards program website on your browser.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-3"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-3"
                >
                  I am an Android phone user, how can I scan the QR code?
                </button>
              </h2>
              <div
                id="collapseInner-1-3"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-3"
                data-bs-parent="#faqInnerAccordion-2"
              >
                <div class="accordion-body">
                  <p>
                    All Android devices using software version 9 or above have
                    the ability to scan a QR code without downloading another
                    app. Where relevant, check your phone setting to ensure that
                    the QR code reading feature is enabled in the camera
                    settings.
                  </p>
                  <p>
                    If you own an Android device with software below version 9,
                    you can consider downloading a 'QR code scanner' from the
                    Google Play Store.
                  </p>
                  <p>
                    To start scanning, simply open the 'Camera' or 'QR code
                    scanner' application, and position the camera over the QR
                    code of the can. Ensure<sup>®</sup>that the QR code is
                    centered and brought on close up. Upon successful scanning,
                    you will see a link pop up from your application. Click on
                    the link to open the Abbott Alaga Rewards program website
                    on your browser.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-4">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-4"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-4"
                >
                  All QR codes look the same. How will you know which product I
                  have purchased and thus give me the correct reward points?
                </button>
              </h2>
              <div
                id="collapseInner-1-4"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-4"
                data-bs-parent="#faqInnerAccordion-2"
              >
                <div class="accordion-body">
                  <p>
                    Each QR code that is printed on every participating product
                    will be unique and tagged to the specific product's pack
                    size and price information. We thus ensure that you are
                    being awarded the right number of reward points based on the
                    official retail price of the product.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-5">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-5"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-5"
                >
                  The QR code is printed on the outside of the can where anyone
                  can easily scan it. How will you know if the scan came from an
                  authentic buyer?
                </button>
              </h2>
              <div
                id="collapseInner-1-5"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-5"
                data-bs-parent="#faqInnerAccordion-2"
              >
                <div class="accordion-body">
                  <p>
                    Every scan will be verified via our backend verification
                    system to help detect any suspicious activities. In the
                    event where suspicious activities are detected (e.g. unique
                    QR code is scanned multiple times), our verification system
                    will trigger a notification for the user to upload a proof
                    of purchase/receipt in our Abbott Alaga Rewards program for
                    verification.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInner-1-6">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseInner-1-6"
                  aria-expanded="true"
                  aria-controls="collapseInner-1-6"
                >
                  Is there a maximum number of scans I can make per month?
                </button>
              </h2>
              <div
                id="collapseInner-1-6"
                class="accordion-collapse collapse"
                aria-labelledby="headingInner-1-6"
                data-bs-parent="#faqInnerAccordion-2"
              >
                <div class="accordion-body">
                  <p>
                    The total number of scans you can make is limited to 6kg
                    worth of products per month. (E.g. 7 cans of 850g)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          Reward Redemption
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body">
          <div class="accordion-body">
            <div class="accordion" id="faqInnerAccordion-3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-1"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-1"
                  >
                    How do I start making my first redemption?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-1"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-1"
                  data-bs-parent="#faqInnerAccordion-3"
                >
                  <div class="accordion-body">
                    <p>
                      To make a redemption, log in to the Abbott Alaga Rewards
                      program and select 'Rewards' tab. At the 'Rewards' page,
                      you will see the catalogue of Abbott products available
                      for redemption. Note that the list of products available
                      for redemption are based on stock availability at any
                      point in time.
                    </p>
                    <p>
                      From the catalogue, select the desired brand and
                      product(s) you wish to redeem and input the desired
                      quantity based on the number of reward points you have
                      accumulated. Select 'Get this Reward' to add the item to
                      your redemption cart. Click 'Proceed to delivery'.
                    </p>
                    <p>
                      At delivery page, input the address of your desired
                      delivery location (limited to within Singapore*). Select
                      'Confirm redemption' once done. You will see a
                      confirmation page with details of your redemption. Your
                      redeemed product(s) will be delivered to you within 2-3
                      weeks. You will receive an SMS notification on the day of
                      your scheduled delivery. *Exclude off-shore islands,
                      military camps and other restricted areas. Please
                      <a
                        href="../../../assets/Restricted_Postal.pdf"
                        target="_blank"
                        >click on</a
                      >
                      to refer to list of restricted areas postal code."
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-2"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-2"
                  >
                    How long do I have to wait before I receive my redeemed
                    product?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-2"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-2"
                  data-bs-parent="#faqInnerAccordion-3"
                >
                  <div class="accordion-body">
                    <p>
                      Upon successful check out, your redeemed product(s) will
                      be delivered to you within 2-3 weeks. You will receive an
                      SMS notification on the day of your scheduled delivery.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-3"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-3"
                  >
                    How will I receive my redeemed product?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-3"
                  data-bs-parent="#faqInnerAccordion-3"
                >
                  <div class="accordion-body">
                    <p>
                      Your redeemed product(s) will be delivered to your desired
                      location (limited to Singapore*) within 2-3 weeks. You
                      will receive an SMS notification on the day of your
                      scheduled delivery. *Exclude off-shore islands, military
                      camps and other restricted areas. "
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-4"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-4"
                  >
                    Can I redeem products that are not in the rewards catalogue?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-4"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-4"
                  data-bs-parent="#faqInnerAccordion-3"
                >
                  <div class="accordion-body">
                    <p>
                      Only products listed in the rewards catalogue will be
                      available for redemption. Note that the list of products
                      available for redemption are based on stock availability
                      at any point in time.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-5"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-5"
                  >
                    Is there a limit to the number of products I can redeem per
                    redemption?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-5"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-5"
                  data-bs-parent="#faqInnerAccordion-3"
                >
                  <div class="accordion-body">
                    <p>
                      The quantity and product you can redeem depends on the
                      number of reward points you have. There is no limit to the
                      number of products that you can redeem as long as you have
                      sufficient reward points within your account.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-6">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-6"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-6"
                  >
                    Why is it that only 850g products are available within the
                    rewards catalogue?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-6"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-6"
                  data-bs-parent="#faqInnerAccordion-3"
                >
                  <div class="accordion-body">
                    <p>
                      Currently, only the 850g pack size are available within
                      the rewards catalogue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFour">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
          aria-expanded="false"
          aria-controls="collapseFour"
        >
          Registration and Login
        </button>
      </h2>
      <div
        id="collapseFour"
        class="accordion-collapse collapse"
        aria-labelledby="headingFour"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body">
          <div class="accordion-body">
            <div class="accordion" id="faqInnerAccordion-4">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-1"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-1"
                  >
                    How do I register for the Abbott Alaga Rewards program?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-1"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-1"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      To register as a new user for Abbott Alaga Rewards
                      Program, simply visit the Abbott Alaga Rewards program
                      website and click 'Create Account'. You will be requested
                      to go through the registration process using your mobile
                      number and input other key personal details.
                    </p>
                    <p>
                      You can visit the Abbott Alaga Rewards program website
                      via abbottfamily.com.sg, bit.ly/SGAbbottFamilyRewards or
                      simply scan the QR code printed at the bottom of your
                      purchased product using your phone camera or a QR code
                      scanner.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-2"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-2"
                  >
                    I have previously requested for Abbott samples. Do I need to
                    register again to the Abbott Alaga Rewards program?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-2"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-2"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      If you are an existing Abbott Family member, you will
                      still need to sign up on the Abbott Alaga Rewards program
                      website. During registration, please use the same mobile
                      number. Upon successful registration, you will be ready to
                      start accumulating reward points and redeeming products.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-3"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-3"
                  >
                    What information is needed for registration?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-3"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      A valid Singapore registered mobile number will be
                      required as the first step of the registration process.
                      Subsequent steps will require you to input key information
                      such as name and email address. You will also be required
                      to set a password for the account for future log in.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-4"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-4"
                  >
                    What happens if I forget my password?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-4"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-4"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      On the Abbott Alaga Rewards program login page, please
                      click on 'Forgot password?' button. An email or SMS will
                      be sent to help you reset your password.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-5"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-5"
                  >
                    What do I do if my registered mobile number is used by
                    another person?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-5"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-5"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      Please reach out to us via family.sg@abbott.com so that we
                      can get more information for further verification.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-6">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-6"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-6"
                  >
                    Can I register without a mobile number?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-6"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-6"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      A valid Singapore registered mobile number will be
                      required as part of the registration process. You will be
                      unable to register if you do not have a valid mobile
                      number. You can consider using the mobile number of a
                      family member who is residing in the same residential
                      address.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-7">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-7"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-7"
                  >
                    Why do I need to provide my address?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-7"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-7"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      You will be asked for your address during rewards
                      redemption in order for your redeemed product to be
                      delivered to you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-8">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-8"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-8"
                  >
                    Why do I need to provide my email when I register?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-8"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-8"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      Providing your email adress is important as we will be
                      sharing updates on your Abbott Alaga Rewards program
                      account (Eg. Points expiry, redemptions) via email.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-9">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-9"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-9"
                  >
                    Why is my Abbott Alaga Rewards program account deactivated?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-9"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-9"
                  data-bs-parent="#faqInnerAccordion-4"
                >
                  <div class="accordion-body">
                    <p>
                      Please contact the Abbott Family Careline at 6278 6220 or
                      family.sg@abbott.com to get assistance on your account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFive">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
          aria-expanded="false"
          aria-controls="collapseFive"
        >
          Reward Points
        </button>
      </h2>
      <div
        id="collapseFive"
        class="accordion-collapse collapse"
        aria-labelledby="headingFive"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body">
          <div class="accordion-body">
            <div class="accordion" id="faqInnerAccordion-5">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-1"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-1"
                  >
                    I've been asked to upload a proof of purchase (receipt). How
                    do I do that?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-1"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-1"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Log in to your Abbott Alaga Rewards program account and
                      select 'Transaction history', Select the corresponding
                      transaction that has requested for the receipt - status
                      will reflect 'Pending Receipt Request'. Click 'VALIDATE'
                      under the transaction to take a photo of your receipt.
                      Click 'Validate' button once done. Note to ensure that a
                      full complete photo of your original receipt is required.
                      Please check to ensure that the image of your entire
                      receipt is clear and readable. Any receipt photo that is
                      not readable, blurred or not showing the full receipt,
                      will be considered as an invalid proof of purchase. You
                      will be require to re-upload the receipt.
                    </p>

                    <p>
                      In the event you are unable to produce the receipt, you
                      will not be awarded any reward points.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-2"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-2"
                  >
                    Why am I being asked for receipt verification?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-2"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-2"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Proof of purchase will be asked for in the event that any
                      suspicious activity has been detected. Suspicious
                      activities might be due to multiple scans of the unique QR
                      code on your product by other consumers.
                    </p>

                    <p>
                      The request for proof of purchase (receipt) is to ensure
                      that reward points are being awarded to authentic users.
                      Simply refer to the 'I've been asked to upload a proof of
                      purchase (receipt). How do I do that?' question for
                      instructions on how to upload.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-3"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-3"
                  >
                    Why do I need to keep my purchase receipt?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-3"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Proof of purchase will be asked for in the event that any
                      suspicious activity has been detected. Suspicious
                      activities might be due to multiple scans of the unique QR
                      code on your product by other consumers.
                    </p>
                    <p>
                      We are unable to verify the authenticity of the QR code
                      scan if you do not have the proof of purchase (receipt).
                      We are thus unable to award you with the reward points.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-4"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-4"
                  >
                    How do I accumulate reward points?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-4"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-4"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Accumulate rewards points with every purchase of a
                      participating product! Refer to the 'How does the reward
                      point system work?' question to understand more.
                    </p>

                    <p>
                      To start earning your reward points, log in to the Abbott
                      Family Rewards program, select the 'Scan' button located
                      at the bottom to scan the QR code on the product. To scan,
                      simply position your phone over the QR code located at the
                      bottom of your purchased can. Ensure<sup>®</sup>that the
                      QR code is centered and close up. Upon successful
                      scanning, you will see a confirmation message. Note that
                      your reward points will require 3 working days to be
                      successfully credited and you can check on its status
                      under the 'Transaction History' page. Do remember to keep
                      your original receipt in the event that verification is
                      needed.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-5"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-5"
                  >
                    Where can I check on the number of points I have
                    accumulated?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-5"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-5"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      To check on your reward points, log in to the Abbott
                      Family Rewards program and you will be able to see the
                      total number of available points you have under 'Total
                      Reward Points' located at top right hand corner. Note that
                      'expired' points will be excluded.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-6">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-6"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-6"
                  >
                    Why do I need to wait 5 days before my reward points get
                    credited/reflected in the system?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-6"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-6"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Up to 3 working days are required to process the QR code
                      scan(s) and reward points.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-7">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-7"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-7"
                  >
                    Does my reward points expire?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-7"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-7"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>Yes, reward points expire after 12 months.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-8">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-8"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-8"
                  >
                    Will I get notified if my points are expiring?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-8"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-8"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Yes, a notification will be sent if you have 10 points
                      expiring in 14 days.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingInner-1-9">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseInner-1-9"
                    aria-expanded="true"
                    aria-controls="collapseInner-1-9"
                  >
                    I've been asked to upload a proof of purchase (receipt).
                    What can I do if I do not have the receipt anymore?
                  </button>
                </h2>
                <div
                  id="collapseInner-1-9"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingInner-1-9"
                  data-bs-parent="#faqInnerAccordion-5"
                >
                  <div class="accordion-body">
                    <p>
                      Proof of purchase is asked in the event that any
                      suspicious activity has been detected.
                    </p>
                    <p>
                      We are unable to verify the authenticity of the QR code
                      scan if you do not have the proof of purchase (receipt).
                      We are thus unable to award you with the reward points.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
