<app-header title="Log in"></app-header>

<section class="globalContainer bg-white">
  <div class="block-notice text-center">
    <h5 class="font-red mb-3 fs20">NOTICE</h5>
    <p class="fs16">
      Your account is temporarily blocked as the system has detected suspicious
      activities. Please contact Abbot Nutrition hotlines at
      <a href="tel:0289951555" class="font-blue fW600">02-8-995-1555</a> (Manila) 
      <a href="tel:1800109951555" class="font-blue fW600">1-800-10-995-1555</a> (Toll Free Province).
    </p>
    <div class="btnSection mt50">
      <button
        type="button"
        class="primaryBtn"
        [routerLink]="['/site-switcher']"
      >
        Close
      </button>
    </div>
  </div>
</section>