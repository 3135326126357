<section class="p-4 pb100">
    <div class="text-center">
      <img src="../../../../assets/images/Group 5532.svg" />
      <p class="font-darkblue fs32 mb-0">Redemption successful!</p>
      <p class="fs20 mb-0 lh29">
        Total points used:
        <span class="font-blue ms-1 fW600">
          {{ quantity * intouchPoints }}
        </span>
      </p>
      <p class="fs20 lh29">
        Your remaining points:<span class="font-blue ms-1 fW600">{{
          loyaltyPoints
        }}</span>
      </p>
      <!-- <p class="fs16">
          Your reward has been emailed to your registered email address.
        </p> -->
      <div>
        <p class="fs20 fW900 text-left mb-0">Redemption summary</p>
      </div>
      <div class="">
        <div class="white-bg-card">
          <div class="yello-bg-card d-flex">
            <div class="prod-img">
              <div class="reward-points">
                <p class="color-white m-0 text-center">{{ intouchPoints }} pts</p>
              </div>
              <img
                class="ht"
                [src]="image"
                *ngIf="image == null || image == undefined"
              />
              <img
                class="ht"
                src="../../../../assets/images/GALogoColored.png"
                *ngIf="image != null || image != undefined"
              />
            </div>
            <div class="col-9 prod-content">
              <p class="mb-0 mt-0 fW600 ml">
                {{ name }}
              </p>
              <div class="d-flex mt-0 font-darkblue fW900 fs12 mt-3">
                <span class="">x</span>
                <span class="">{{ quantity }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="yello-bg-card d-flex">
              <div class="prod-img">
                <div class="reward-points">
                  <p class="color-white m-0">
                    {{ rewardInfo.intouchPoints }} pts
                  </p>
                </div>
                <img src="../../../../assets/images/Ensure Gold.png" />
              </div>
              <div class="col-9 prod-content">
                <p class="mb-0 mt-0 text-left">
                  {{ rewardInfo.name }}
                </p>
                <div class="d-flex mt-0">
                  <div class="col-9 prod-content">
                    <p class="mb-0 mt-0">Ensure Gold<sup>®</sup> P200 Giftaway Voucher</p>
                    <div class="d-flex mt-0 font-darkblue fW900 fs12 mt-2">
                      <span class="">x</span>
                      <span class="">2</span>
                    </div>
                  </div> -->
          <!-- <span class="fs8 mt-2 fW900">QUANTITY</span> -->
          <!-- <span class="plus-minus-signs ms-3"
                    ><img
                      src="../../../../assets/images/Group 5355.png"
                      alt=""
                      width="20"
                      height="20"
                  /></span>
                  <span class="fs13 mt-1 ms-3">{{ rewardInfo.quantity }}</span>
                  <span class="plus-minus-signs ms-3"
                    ><img
                      src="../../../../assets/images/Group 5356.png"
                      alt=""
                      width="20"
                      height="20"
                  />
                </span> -->
          <!-- </div>
              </div> -->
          <!-- </div> -->
        </div>
      </div>
      <p class="text-left mt-4">
        To check and/or use this reward, please click the
        <span class="bg-bl fW600" (click)="onClickViewRewardDetails()">view reward details</span> button below.
      </p>
      <!-- <div class="text-right mt-4">
          <p class="fs16 mb-0">Total points used</p>
          <p class="font-blue fs24 fW900">
            {{ count * rewardInfo.intouchPoints }} pts
          </p>
        </div> -->
      <div class="mt-4">
        <button
          class="primaryBtn-lightBlue-bg fs20"
          (click)="onClickViewRewardDetails()"
        >
          View reward details
        </button>
        <div class="mt-2">
          <button class="primaryBtn-bg fs20" (click)="onClickBackToDashboard()">
            Back to dashboard
          </button>
        </div>
        <p class="text-center bottom">
          Per DOH-FDA CFRR Promo Permit 0009 s. 2022
        </p>
      </div>
    </div>
  </section>
  