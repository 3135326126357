import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { Toast } from "ngx-toastr";
import { first } from "rxjs/operators";
import { ApiService } from "src/app/service/api.service";
import { Location } from "@angular/common";

@Component({
  selector: 'app-similac-transaction-history',
  templateUrl: './similac-transaction-history.component.html',
  styleUrls: ['./similac-transaction-history.component.scss']
})
export class SimilacTransactionHistoryComponent implements OnInit {

  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent: any = false;
  pointsList: any;
  totalLength: any;
  productList = [];
  checkedStatusListVochers = [];
  tempArray = [];
  tempArrayVochers = [];
  vochers = [];
  filteredVochersList = [];
  newArray = [];
  newArrayVochers = [];
  rewardDetail: any;
  voucherList: any;
  REFERENCE;
  TODAY;
  YESTERDAY;
  filteredArray = [];
  checkedStatusList = [];
  filteredProductList = [];
  @ViewChild("button", { static: true }) button: ElementRef;
  tempArrayByStatus: any[];
  checkedDateFilterList = [];
  customerLastName: string;
  brand: any;
  transactionsVisited: boolean = false;
  couponData: any;
  couponLenghth: any
  similacProductList = [];
  coupons = [];
  couponList = [];
  filteredCoupons = [];
  showProductFilter: boolean = false;
  showVocherFilter: boolean = false;
  userVouchers=[];
  hideFilterButton: boolean = false;
  hideVoucherFilterButton: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private location: Location
  ) { }

  ngAfterViewInit(): void {
    console.log(this.button);
  }

  handleDropdown(e) {
    console.log(e);
    e.preventDefault();
  }

  ngOnInit(): void {
    console.log(this.route.snapshot.params.name);
    this.REFERENCE = moment();
    this.TODAY = this.REFERENCE.clone().startOf("day");
    this.YESTERDAY = this.REFERENCE.clone().subtract(1, "days").startOf("day");
    this.brand = sessionStorage.getItem("currentBrand")?.toLowerCase();
    this.transactionsVisited = true;
    this.getCustomer();
    this.getCustomerCoupons();
    this.getAllPoints();
    // this.getRewardList();
    this.getRedemtionDetailFinal();
  }

  onClickBack() {
    // this.location.back();
    this.router.navigate(["/similac/dashboard", { name: this.brand }]);
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          let customerLast = this.customerData.lastname;
          customerLast = customerLast.split(" ");
          if (customerLast.length > 1) {
            let customerLastName = customerLast[0] + " " + customerLast[1];
            this.customerLastName = customerLastName;
            this.customerName =
              this.customerData.firstname + " " + customerLastName;
          } else {
            this.customerName =
              this.customerData.firstname + " " + this.customerData.lastname;
          }

          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;

          // Block the user
          let block = res["customers"]["customer"][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == "block_count";
          });
          console.log(blockUser, "blockUser===");
          if (blockUser[0]?.value == "1") {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(["/block-notice"]);
          }
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  getAllPoints() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");

    this.spinner.show();
    this.apiService
      .getProductsList(mobile,token)
      .pipe(first())
      .subscribe((res) => {
        this.spinner.hide();
        this.pointsList = res["result"];
        this.totalLength = this.pointsList.length;
        // this.productList.push(
        //   {
        //     color: "#30C878",
        //     image: 'null',
        //     name: "Harry potter",
        //     points: "0 pts",
        //     productCode: 21,
        //     productStatus: undefined,
        //     status: "success",
        //     submissionDate: "21-8-2021",
        //     30: "30Days"
        //   },
        //   {
        //     color: "#30C878",
        //     image: 'null',
        //     name: "spider-man",
        //     points: "0 pts",
        //     productCode: 22,
        //     productStatus: undefined,
        //     status: "pending",
        //     submissionDate: "21-11-2021",
        //     60: '60Days'

        //   },
        //   {
        //     color: "#30C878",
        //     image: 'null',
        //     name: "Ron",
        //     points: "0 pts",
        //     productCode: 23,
        //     productStatus: undefined,
        //     status: "invalid",
        //     submissionDate: "30-10-2021",
        //     90: '90Days'

        //   },
        //   {
        //     color: "#30C878",
        //     image: 'null',
        //     name: "Hermoine",
        //     points: "0 pts",
        //     productCode: 24,
        //     productStatus: undefined,
        //     status: "awaiting",
        //     submissionDate: "30-7-2021",
        //     yearAgo: "yearAgo"

        //   }
        // )
        console.log("poinysList: ", this.pointsList);
        // this.pointsList.push({
        //   id: 100,
        //   product_name: 'Mamatha',
        //   product_image: null,
        //   receipt_status: 'awaiting',
        //   productCode: 1000,
        //   submission_date: moment("2022-02-04T07:13:09.000Z").format(),
        //   points: 1000,
        //   status: 'awaiting',
        // })
        this.pointsList.forEach((product: any) => {
          let productItem = {
            id: product?.id,
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format(),
            points: product.points,
            productStatus: product.status,
            color: "",
            30: "",
            90: "",
            yearAgo: "",
          };

          // productItem.submissionDate = "06-01-2022"
          productItem["30"] = this.isThirtyDaysAgo(productItem.submissionDate);
          productItem["yearAgo"] = this.isYearAgo(productItem.submissionDate);
          productItem["90"] = this.isNinetyDaysAgo(productItem.submissionDate);

          if (this.isToday(productItem.submissionDate)) {
            productItem.submissionDate = "Today";
          } else if (this.isYesterday(productItem.submissionDate)) {
            productItem.submissionDate = "Yesterday";
          } else {
            productItem.submissionDate = moment(
              productItem.submissionDate
            ).format("DD/MM/YYYY");
          }
          // productItem.status = 'awaiting'

          if (productItem.status == "approved") {
            productItem.status = "SUCCESS";
            productItem.color = "#30C878";
            if (productItem.points == null) {
              productItem.points = "";
            } else {
              productItem.points = "+" + productItem.points + " " + "pts";
            }
          } else if (productItem.status == "pending") {
            productItem.status = "PENDING RECEIPT REQUEST";
            productItem.points = "";
            productItem.color = "#8b8f91";
          } else if (productItem.status == "rejected") {
            productItem.status = "INVALID";
            productItem.points = "";

            productItem.color = "#fe2121";
          } else if (productItem.status == "awaiting") {
            productItem.status = "PENDING POINTS CONFIRMATION";
            productItem.color = "#EFBE35";
            productItem.points = "";
          }

          this.productList.push(productItem);
        });
        this.productList = this.productList.filter(
          (v, i, a) => a.findIndex((t) => t.productCode === v.productCode) === i
        );

        this.filteredProductList = this.productList.reverse();
        this.filteredProductList.forEach((product, i) => {
          let name = (product.name).toLowerCase()
          if (name.includes('similac')) this.similacProductList.push(product)
        })
        this.filteredProductList = this.similacProductList
        console.log("filtered: ",this.filteredProductList)
        // this.showProductFilter = this.filteredProductList.length > 0 ? true : false
        this.hideFilterButton = this.filteredProductList.length > 0 ? false : true

      }),
      (err) => {
        console.log(err);
        this.spinner.hide();
      };
  }

  isToday(momentDate) {
    let today = moment(this.TODAY).format("");
    return moment(momentDate).isSame(today, "d");
  }
  isYesterday(momentDate) {
    let yesterday = moment(this.YESTERDAY).format("");
    return moment(momentDate).isSame(yesterday, "d");
  }

  isSixtyDaysAgo(submissionDate) {
    var today = moment().format();
    var sixtyDaysBefore = moment().subtract(60, "days").format();
    return moment(submissionDate).isBetween(
      moment(sixtyDaysBefore),
      moment(today)
    )
      ? "60Days"
      : undefined;
  }

  isYearAgo(submissionDate) {
    var today = moment().format();
    var yearAgo = moment().subtract(1, "year").format();
    return moment(submissionDate).isBetween(moment(yearAgo), moment(today))
      ? undefined
      : "yearAgo";
  }

  isNinetyDaysAgo(submissionDate) {
    var today = moment().format();
    var ninetyDaysBefore = moment().subtract(90, "days").format();
    return moment(submissionDate).isBetween(
      moment(ninetyDaysBefore),
      moment(today)
    )
      ? "90Days"
      : undefined;
  }

  isThirtyDaysAgo(submissionDate) {
    var today = moment().format();
    var thirtyDaysBefore = moment().subtract(30, "days").format();
    return moment(submissionDate).isBetween(
      moment(thirtyDaysBefore),
      moment(today)
    )
      ? "30Days"
      : undefined;
  }

  onApplyFilters() {
    this.tempArray = [];
    this.tempArrayByStatus = [];
    this.newArray = this.similacProductList;
    console.log(this.checkedStatusList);

    if (this.checkedStatusList.length > 0) {
      this.checkedStatusList.forEach((value) => {
        this.newArray.forEach((product) => {
          if (product.status == value) {
            this.tempArray.push(product);
            if (this.checkedDateFilterList.length > 0) {
              this.checkedDateFilterList.forEach((value) => {
                if (
                  product[30] == value ||
                  product["yearAgo"] == value ||
                  product[90] == value
                ) {
                  this.tempArray.push(product);
                } else {
                  this.tempArray.pop();
                }
              });
            }
          }
        });
      });
    } else {
      this.checkedDateFilterList.forEach((val) => {
        this.newArray.forEach((product) => {
          if (
            product[30] == val ||
            product["yearAgo"] == val ||
            product[90] == val
          ) {
            this.tempArray.push(product);
          }
        });
      });
    }

    this.filteredProductList = this.tempArray;
    console.log("fil: ", this.filteredProductList);
    if (
      this.checkedStatusList.length == 0 &&
      this.checkedDateFilterList.length == 0
    ) {
      this.filteredProductList = this.similacProductList;
    }
    this.filteredProductList = this.filteredProductList.filter(
      (v, i, a) => a.findIndex((t) => t.productCode === v.productCode) === i
    );

    //    this.filteredProductList = this.tempArray
  }
  changeSelection(event) {
    if (event.target.checked) {
      if (
        event.target.value == "30Days" ||
        event.target.value == "90Days" ||
        event.target.value == "yearAgo"
      ) {
        this.checkedDateFilterList.push(event.target.value);
      } else {
        this.checkedStatusList.push(event.target.value);
      }
      this.updateShowProductFilter()
    } else {
      if (
        event.target.value == "30Days" ||
        event.target.value == "90Days" ||
        event.target.value == "yearAgo"
      ) {
        this.checkedDateFilterList = this.checkedDateFilterList.filter(
          (e) => e != event.target.value
        );
      } else {
        this.checkedStatusList = this.checkedStatusList.filter(
          (e) => e != event.target.value
        );
      }
      this.updateShowProductFilter()
    }
  }

  onClickExitFilter() {
    this.hideFilterButton = false
    console.log(this.checkedStatusList);
    var inputs = document.querySelectorAll(".check3");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i]["checked"] = false;
    }
    this.checkedStatusList = [];
    this.checkedDateFilterList = [];
    this.filteredProductList = this.similacProductList;
  }

  onClickExitFilterVocher() {
    this.hideVoucherFilterButton = false
    console.log(this.checkedStatusListVochers);
    var inputs = document.querySelectorAll(".check4");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i]["checked"] = false;
    }
    this.checkedStatusListVochers = [];
    this.filteredCoupons = this.couponList;
  }

  getRedemtionDetailFinal() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    this.spinner.show();
    this.apiService.getMarvalVouchers(mobile, token).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res["status"].code == 200) {
          this.pointsList = res?.response?.customer?.redemptions?.points?.point;
          this.voucherList = res?.userVouchers;
          this.userVouchers = res?.userVouchers[0]?.vouchers || []
          
          this.voucherList = this.voucherList.filter(
            (ress) => ress.rewardDetails != null
          );
          let voucherFilter = this.voucherList.forEach((voucherList) => {
            let tierData = voucherList.rewardDetails.tier;
            //let tierData = 'ABC123';
            let pointFilter = this.pointsList.filter((point) => {
              return point.notes === tierData;
            });
            let rewardFilter = null;
            if (this.rewardDetail != null || this.rewardDetail != undefined) {
              rewardFilter = this.rewardDetail.filter((reward) => {
                return reward.tier === tierData;
              });
            }
            let foundReward = null;
            if (rewardFilter != null && rewardFilter.length > 0) {
              foundReward = rewardFilter[0];
            }
            let tottal_points = 0;
            let quantity = 0;
            pointFilter.forEach((pointFilter) => {
              tottal_points = tottal_points + pointFilter.points_redeemed;
              if (foundReward != null) {
                quantity =
                  quantity +
                  pointFilter.points_redeemed / foundReward.intouchPoints;
              } else {
                quantity = voucherList.vouchers.length;
              }
            });
            let transDate = voucherList.vouchers[0].issued;

            voucherList["total_points"] = tottal_points;
            voucherList["quantity"] = quantity;
            //voucherList['displayDate'] = moment(transDate).add(2.5, 'hours').format('DD MMM yyyy');
            voucherList["displayDate"] = transDate;
          });
          setTimeout(() => {
            this.voucherList.sort((a, b) =>
              moment(a.issuedDate, "DD-MM-YYYY").isBefore(
                moment(b.issuedDate, "DD-MM-YYYY")
              )
                ? 1
                : -1
            );
            this.spinner.hide();
          }, 1000);
          this.userVouchers.sort((a, b) => {
            const dateA = new Date(a.issued);
            const dateB = new Date(b.issued);
            return dateB.getTime() - dateA.getTime();
          });
          console.log("this.userVouchers =>",this.userVouchers);
          
          this.userVouchers.forEach((voucher)=>{
            console.log("voucher =>",voucher);
            if (this.isToday(voucher.issued)) {
              voucher.date = "Today";
            } else if (this.isYesterday(voucher.issued)) {
              voucher.date = "Yesterday";
            } else {
              voucher.date = moment(voucher.issued).format("DD/MM/YYYY");
            }

          })
         

          // this.vochers.push(obj);
          this.voucherList.forEach((vocher) => {
            let obj = {
              name: vocher["rewardDetails"].name,
              image: vocher["rewardDetails"].imageUrl,
              points: vocher.total_points,
              detail: vocher["vouchers"],
              date: moment(vocher.displayDate).format(),
              id: vocher["rewardDetails"].rewardId,
              quantity: vocher["quantity"],
              30: "",
              90: "",
              yearAgo: "",
            };
            obj[30] = this.isThirtyDaysAgo(obj.date);
            obj[90] = this.isNinetyDaysAgo(obj.date);
            obj["yearAgo"] = this.isYearAgo(obj.date);

            if (this.isToday(obj.date)) {
              obj.date = "Today";
            } else if (this.isYesterday(obj.date)) {
              obj.date = "Yesterday";
            } else {
              obj.date = moment(obj.date).format("DD/MM/YYYY");
            }

            this.vochers.push(obj);
            
            // this.vochers.push(
            //   {
            //     name: 'super-man',
            //     image: '',
            //     points: '230pts',
            //     date: '7-1-2022',
            //     id: 22,
            //     30: '30Days',
            //   },
            //   {
            //     name: 'spider-man',
            //     image: '',
            //     points: '230pts',
            //     date: '7-1-2022',
            //     id: 23,
            //     90: '90Days',

            //   },
            //   {
            //     name: 'hang-man',
            //     image: '',
            //     points: '230pts',
            //     date: '7-1-2022',
            //     id: 25,
            //     year: 'yearAgo'

            //   }

            // )
          });

          this.filteredVochersList = this.vochers;
          this.showVocherFilter = this.filteredVochersList.length > 0 ? true : false;
          console.log("filteredVochers: ", this.filteredVochersList);

          // this.voucherList = this.voucherList.push(this.pointsList );
          // this.pointsList.forEach((element:any) => {
          //   this.pointData = element?.points_redeemed
          //   console.log( this.pointData, 'this.pointData')
          // });
        }
      },
      (err) => {
        console.log(err, "err--");
        this.spinner.hide();
      }
    );
  }

  RewardDetails(vocherId, name) {
    console.log(vocherId);
    this.router.navigate(["similac/reward-details", { id: vocherId, name: name }]);
  }

  changeSelectionVochers(event) {
    if (event.target.checked) {
      this.checkedStatusListVochers.push(event.target.value);
      this.updateShowVoucherProductFilter()
    } else {
      this.checkedStatusListVochers = this.checkedStatusListVochers.filter(
        (e) => e != event.target.value
      );
      this.updateShowVoucherProductFilter()
    }
  }

  getCustomerCoupons() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    this.spinner.show();
    this.apiService.getCapCustomerCoupons(mobile, token).subscribe(
      (res) => {
        this.spinner.hide();
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.couponData = res["customers"]["customer"][0]?.coupons?.coupon;
          this.couponData?.forEach(coupon => {
            let seriesName = coupon.series_name.toLowerCase()
            if (!seriesName.startsWith('glucerna') && !seriesName.startsWith('pediasure') && !seriesName.startsWith('ensure')) this.coupons.push(coupon)
          });
          console.log("coupons: ", this.coupons);

          this.coupons.forEach((coupon: any) => {
            let obj = {
              code: coupon.code,
              date: moment(coupon.created_date).format(),
              name: coupon.series_name,
              30: "",
              90: "",
              yearAgo: "",
            }

            obj[30] = this.isThirtyDaysAgo(obj.date);
            obj[90] = this.isNinetyDaysAgo(obj.date);
            obj["yearAgo"] = this.isYearAgo(obj.date);

            if (this.isToday(obj.date)) {
              obj.date = "Today";
            } else if (this.isYesterday(obj.date)) {
              obj.date = "Yesterday";
            } else {
              obj.date = moment(obj.date).format("DD/MM/YYYY");
            }

            this.couponList.push(obj)
          })
          this.couponLenghth = res["customers"]["customer"][0]?.coupons;
          console.log(this.couponData, ' this.couponData ==========')
          console.log(this.couponLenghth.length, ' this.couponLenghth ==========')
          this.filteredCoupons = this.couponList
          console.log("filtered coupons: ", this.filteredCoupons)
          this.hideVoucherFilterButton =  this.filteredCoupons.length > 0 ? false : true;

        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  // onApplyVochers() {
  //   this.tempArrayVochers = [];
  //   this.newArrayVochers = this.vochers;
  //   console.log(this.checkedStatusListVochers);
  //   this.checkedStatusListVochers.forEach((value) => {
  //     this.newArrayVochers.forEach((vocher) => {
  //       if (
  //         vocher[30] == value ||
  //         vocher["yearAgo"] == value ||
  //         vocher[90] == value
  //       ) {
  //         this.tempArrayVochers.push(vocher);
  //       }
  //     });
  //   });

  //   this.filteredVochersList = this.tempArrayVochers;
  //   if (this.checkedStatusListVochers.length == 0) {
  //     this.filteredVochersList = this.vochers;
  //   }
  //   this.filteredVochersList = this.filteredVochersList.filter(
  //     (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  //   );
  // }

  onApplyVochers() {
    this.tempArrayVochers = [];
    this.newArrayVochers = this.couponList;
    console.log(this.checkedStatusListVochers);
    this.checkedStatusListVochers.forEach((value) => {
      this.newArrayVochers.forEach((coupon) => {
        if (
          coupon[30] == value ||
          coupon["yearAgo"] == value ||
          coupon[90] == value
        ) {
          this.tempArrayVochers.push(coupon);
        }
      });
    });

    this.filteredCoupons = this.tempArrayVochers;
    if (this.checkedStatusListVochers.length == 0) {
      this.filteredCoupons = this.couponList;
    }
    this.filteredCoupons = this.filteredCoupons.filter(
      (v, i, a) => a.findIndex((t) => t.code === v.code) === i
    );

    console.log('filtered: ', this.filteredCoupons)

  }

  onclickUploadReceipt(id) {
    let data = {
      data: id,
    };
    this.router.navigate(["/scan/camera", data]);
  }
  updateShowProductFilter() {
    if (this.checkedStatusList?.length > 0 || this.checkedDateFilterList?.length > 0) {
      this.showProductFilter = true;
      this.hideFilterButton = true
    } else {
      this.showProductFilter = false;
      this.hideFilterButton = false
    }
  }

  updateShowVoucherProductFilter() {
    if (this.checkedStatusListVochers?.length > 0) {
      this.hideVoucherFilterButton = true
    } else {
      this.hideVoucherFilterButton = false
    }
  }

}
