import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { first } from "rxjs/operators";
import { ApiService } from "src/app/service/api.service";
import { Location } from "@angular/common";
import { BugsnagService } from "src/app/services/bugsnag.service";

@Component({
  selector: "app-rewards-brand-selection",
  templateUrl: "./rewards-brand-selection.component.html",
  styleUrls: ["./rewards-brand-selection.component.scss"],
})
export class RewardsBrandSelectionComponent implements OnInit {
  productName: any;
  productList = [];
  rewardsList: any;
  count = 1;
  disabled = false;
  customerData: any;
  loyaltyPoints: any;
  rewardInfo: any;
  customerNamePresent: any = false;
  couponData: any;
  @ViewChild("popupRedeem", { static: true })
  popupRedeem: ModalDirective;

  @ViewChild("popupRewardAddedToCart", { static: true })
  popupRewardAddedToCart: ModalDirective;
  pointsRequired: any;
  additionalPointsNeeded: any;

  @ViewChild("popupRemoveItemFromCart", { static: true })
  popupRemoveItemFromCart: ModalDirective;
  customerName: string;
  customerLastName: any;
  rewardVisited: boolean = false;
  brand: any;
  couponLenghth: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private bsService: BugsnagService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.brand = sessionStorage.getItem("currentBrand").toLowerCase();
    // this.rewardInfo = sessionStorage.getItem("currentBrand");
    // console.log(this.rewardInfo);
    // this.count = parseInt(this.rewardInfo.quantity);
    // this.disabled = this.count <= 1 ? true : false;
    this.getCustomer();
    this.getCustomerCoupons();
    this.rewardVisited = true;
    this.getRewardsList();
  }
  onClickIicon() {
    this.popupRedeem.show();
  }
  onClickBack() {
    // this.location.back();
    this.router.navigate(["/dashboard", { name: this.brand }]);
  }
  onClickClose() {
    this.popupRedeem.hide();
    this.popupRewardAddedToCart.hide();
    this.popupRemoveItemFromCart.hide();
  }
  onClickGetThisRewardButton() {
    // this.popupRewardAddedToCart.show();
    // this.router.navigate(["/reward/checkout"]);
    this.popupRemoveItemFromCart.show();
  }

  getRewardsList() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    this.spinner.show();
    this.apiService
      .getMarvalRewardDetails(mobile, token)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res["status"].code == 200) {
            this.rewardsList = res["rewardList"];
            if (this.brand == "ensure") {
              this.brand = "Ensure";
              this.ensureProductList();
            } else if (this.brand == "pediasure") {
              this.brand = "PediaSure";
              this.pediasureProductList();
            } else if (this.brand == "glucerna") {
              this.brand = "Glucerna";
              this.glucernaProductList();
            } else if (this.brand == "similac") {
              this.brand = "Similac";
              this.similacProductList();
            }
          }
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
          this.bsService.notifyError(err);
        }
      );
  }

  ensureProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == "ensure") {
        reward.quantity = 1;
        reward.disabled = true;
        reward.category = "ensure";
        this.productList.push(reward);
      }
    });
    console.log("pro list: ", this.productList);
  }

  pediasureProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == "pediasure") {
        reward.quantity = 1;
        reward.disabled = true;
        reward.category = "pediasure";

        this.productList.push(reward);
      }
    });
  }

  similacProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == "similac") {
        reward.quantity = 1;
        reward.disabled = true;
        reward.category = "similac";

        this.productList.push(reward);
      }
    });
  }

  glucernaProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == "glucerna") {
        reward.quantity = 1;
        reward.disabled = true;
        reward.category = "glucerna";
        this.productList.push(reward);
      }
    });
  }

  onIncrement(index) {
    this.productList[index].quantity += 1;
    this.productList[index].disabled =
      this.productList[index].quantity <= 1 ? true : false;
  }

  onDecrement(index) {
    this.productList[index].quantity -= 1;
    this.productList[index].disabled =
      this.productList[index].quantity <= 1 ? true : false;
  }
  onClickViewLinkInNewTab(link) {
    window.open(link, "_blank");
  }
  onClickCopyLink(link) {
    navigator.clipboard.writeText(link);

    this.apiService.showToaster("Copied!");
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        this.spinner.hide();
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          let customerLast = this.customerData.lastname;
          customerLast = customerLast.split(" ");
          console.log(customerLast.length);
          if (customerLast.length > 1) {
            let customerLastName = customerLast[0] + " " + customerLast[1];
            this.customerLastName = customerLastName;
            this.customerName =
              this.customerData.firstname + " " + customerLastName;
          } else {
            this.customerName =
              this.customerData.firstname + " " + this.customerData.lastname;
          }

          this.loyaltyPoints = this.customerData.loyalty_points;
          console.log(this.loyaltyPoints);

          this.customerNamePresent = true;
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      }
    );
  }

  getCustomerCoupons() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    this.spinner.show();
    this.apiService.getCapCustomerCoupons(mobile, token).subscribe(
      (res) => {
        this.spinner.hide();
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.couponData = res["customers"]["customer"][0]?.coupons?.coupon;
          this.couponLenghth = res["customers"]["customer"][0]?.coupons;
          console.log(this.couponData, " this.couponData ==========");
          console.log(
            this.couponLenghth.length,
            " this.couponLenghth =========="
          );
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      }
    );
  }

  onClickGetReward(reward) {
    this.rewardInfo = reward;
    let totalIntouchPoints = reward.quantity * reward.intouchPoints;
    console.log(totalIntouchPoints);
    this.pointsRequired = totalIntouchPoints;
    this.additionalPointsNeeded = totalIntouchPoints - this.loyaltyPoints;
    console.log(reward);
    if (this.loyaltyPoints < this.pointsRequired) {
      this.popupRewardAddedToCart.show();
    } else {
      this.router.navigate([
        "/reward/checkout",
        {
          name: reward.name,
          intouchPoints: reward.intouchPoints,
          image: reward.imageUrl,
          quantity: reward.quantity,
          id: reward.id,
          category: reward.category,
          tier: reward.tier,
        },
      ]);
    }
  }
}
