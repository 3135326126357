import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { Location } from "@angular/common";
import { ModalDirective } from "ngx-bootstrap/modal";
import { DatePipe } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-daily-log-pediasure',
  templateUrl: './daily-log-pediasure.component.html',
  styleUrls: ['./daily-log-pediasure.component.scss'],
  providers: [DatePipe]
})




export class DailyLogPediasureComponent implements OnInit {

  @ViewChild("CheckAllFields", { static: true })
  CheckAllFields: ModalDirective;
  @ViewChild("notLoginOrSignup", { static: true })
  notLoginOrSignup: ModalDirective;
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  rewardInfo: any;
  count = 1;
  rewardVisited: boolean = false;
  dailyLogs: any[] = []
  brand: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private location: Location,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) { }
  myDate = new Date();
  inputs: any


  ngOnInit(): void {
    this.brand = 'pediasure'
    console.log(this.route.snapshot.params);
    this.rewardInfo = this.route.snapshot.params;

    console.log(this.rewardInfo);
    this.count = parseInt(this.rewardInfo.quantity);
    this.getCustomer();
    this.getDailyLogs()
    this.rewardVisited = true;
  }

  getDailyLogs() {
    this.spinner.show()
    this.apiService.getDailyLogs().subscribe((res) => {
      if (res['status'] == 200) {
        this.spinner.hide()
        console.log("res: ", res)
        this.dailyLogs = res['data'][0]['valueslinked']
        console.log("daily: ", this.dailyLogs)
        this.dailyLogs.forEach(log => log.checked = false)
      }
    }, err => {
      this.spinner.hide()
      console.log(err)
    })
  }


  onClickBack() {
    this.location.back();
  }


  onClickCheck(id: any) {
    this.inputs = document.querySelectorAll(".check4");
    this.dailyLogs[id].checked = this.inputs[id]['checked']
  }

  onClickCheckId(id: any) {
    let allChecked = true;
    this.inputs = document.querySelectorAll(".check4");
    this.dailyLogs[id].checked = true
    this.inputs[id].checked = true
    this.dailyLogs.forEach(log => {
      if (log.checked == false) allChecked = false
    })

    if (allChecked) this.CheckAllFields.hide()
  }

  onClickCheckFields() {
    let notChecked = false
    this.dailyLogs.forEach(log => {
      if (log.checked == false) notChecked = true
    })
    notChecked ? this.CheckAllFields.show() :
      this.customerNamePresent ? this.submitDailyLogs() : this.notLoginOrSignup.show()

  }

  onClickLoginSignup() {
    let showGlucernaPopup = sessionStorage.getItem('showGlucernaPopup')
    if (this.brand == 'glucerna') {
      if (showGlucernaPopup == 'true') this.router.navigate(['glucerna/announcements'])
      else this.router.navigate(['/login-signup'])
    } else {
      this.router.navigate(['/login-signup'])
    }
  }

  onClickCancel() {
    this.notLoginOrSignup.hide();
  }

  async submitDailyLogs() {
    if (this.isUserAlreadySubmittedLogs()) {
      this.toastr.error("You have already submitted The Daily log for today.");
      return;
    }
    let mobileNo = sessionStorage.getItem('mobileNo');
    let currentDate = moment().format("DD-MM-YYYY");
    let payload = {
      "event_name": "Daily_login",
      "mobile": mobileNo
    };
    this.spinner.show();
    this.apiService.submitDailyLogs(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === "success") {
        let token = sessionStorage.getItem('authToken');
        let formData = this.getuserForm(currentDate);
        this.apiService.updateCapCustomer(formData, mobileNo, token).subscribe(res => {
          if (res['status'].code == 200) {
            this.router.navigate(["pediasure", "daily-logs-points-confirmation"]);
          }
        })
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error(err.error.message);
      console.log("err", err);
    })
  }

  isUserAlreadySubmittedLogs() {
    let logSubmitted = this.customerData.custom_fields.field.filter(item => {
      return item.name === "lastdailysub";
    })
    if (logSubmitted.length === 0) {
      return false;
    }
    let currentDate = moment().format("DD-MM-YYYY");
    if (logSubmitted[0].value === currentDate) {
      return true;
    }
    return false;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;
          // Block the user
          let block = res["customers"]["customer"][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == "block_count";
          });
          console.log(blockUser, "blockUser===");
          if (blockUser[0]?.value == "1") {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(["/block-notice"]);
          }
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
        };
    }
  }

  onClickOk() {
    this.CheckAllFields.hide();
  }

  getuserForm(date) {
    return {
      root: {
        customer: [
          {
            custom_fields: {
              field: [
                {
                  name: 'lastdailysub',
                  value: date
                }
              ],
            },
          },
        ],
      },
    };
  }

}
