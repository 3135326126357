import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-points-added',
  templateUrl: './points-added.component.html',
  styleUrls: ['./points-added.component.scss']
})
export class PointsAddedComponent implements OnInit {
  categoryCode: any;
  selectedBrand: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.selectedBrand = data.selectedBrand
      console.log(this.selectedBrand)

    })
    this.categoryCode = this.selectedBrand

    if (this.categoryCode.startsWith('ensure')) this.categoryCode = 'ensure'
    else if (this.categoryCode.startsWith('glucerna')) this.categoryCode = 'glucerna'
    else if (this.categoryCode.startsWith('pediasure')) this.categoryCode = 'pediasure'
    else if (this.categoryCode.startsWith('similac')) this.categoryCode = 'similac'
    else this.categoryCode = ''



  }

}
