<div class="pageHeader row mx-0 px30">
    <div
      class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
    >
      <a (click)="onClickBack()">
        <i class="fa fa-angle-left"></i>
      </a>
      <h1>Product Features</h1>
    </div>
  </div>
  <section class="globalContainer">

    <section class="about-glucerna min-vh-100 bg">
        
      <div class="top-heading">
            <!-- <img class="why-should-i-choose" src="../../../assets/images/glucerna/WHY-SHOULD-I-CHOOSE.png" /> -->
            <span class="fs-20 fw-bold">WHY SHOULD I CHOOSE</span>
            <br>
            <div class="logo-div mt-2">
                <img class="glucerna-logo" src="../../../assets/images/glucerna/glucerna-logo.svg" />
                <span>?</span>
            </div> 
      </div>
      <div class="product-details d-flex mx-10">
        <div class="product-image col-5">
            <img src="../../../../assets/images/glucerna/ADVANCED-BLEND-BADGE.png" />
          </div>
          <div class="product-content col-7">
            <span class="color-white">
                What’s unique about Glucerna’s formula?
            </span>
          </div>

      </div>
      <div class="product-description">
        <div class="text-content-dark">
            <ul>
                <li>Advanced blend of low glycemic carbohydrates: Fibersol-2, sucromalt, isomaltulose, FOS and oat fiber to help support the dietary management of diabetics.</li>
                <li>Low Glycemic Index: 35</li>
                <li>Contains dual fiber blend (soluble and insoluble fibers), including the prebiotic FOS (fructo-oligosaccharides).</li>
                <li>Unique lipid blend with Polyunsaturated Fatty Acids (Omega-3 & Omega-6) and Monounsaturated Fatty Acids. Glucerna is cholesterol-free and is low in saturated fat.</li>
                <li>Now with 4x more Inositol*.</li>
                <li>High in trivalent Chromium1 which contributes to the maintenance of normal blood glucose levels.</li>
                <li>Provides 28 essential vitamins and minerals to help meet the nutritional needs of diabetics:</li>
                    <ul class="inner-ul">
                        <li>Now with increased levels of Vitamin D, Calcium, Zinc and Iron*</li>
                        <li>High in Vitamin D which helps support Calcium absorption and improve bone strength.</li>
                        <li>High in Zinc, Selenium, Vitamin A and is a source of Iron and Vitamin C which support the normal function of the immune system.</li>
                    </ul>
                <li>Low in lactose (0.09 g/100 mL), Gluten-free food</li>
            </ul>
            <p>* vs Previous Formulation (S434) which contains per serving, Inositol = 199 mg; Vitamin D = 2.6 mcg; Calcium = 168 mg; Zinc = 2.4 mg; Iron = 1.4 mg</p>
           
        </div>
        <div class="text-content-light">
            1 European Food Safety Authority Journal. 2010;8(12):1882.
        </div>
      </div>
    </section>
    <div class="bottom-bg"></div>
  </section>
  
  <app-glucerna-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="rewardInfo.name"
></app-glucerna-menu>
  