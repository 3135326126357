import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor( private http: HttpClient) { }
  
  addUser(formData:any){
    // let headers = new HttpHeaders({
    //   'Authorization': 'Basic ' + btoa(`abbott.sg.demo.admin:${Md5.hashStr('abbott123')}`)
    // });
    
    //  let headers = new HttpHeaders({
    //   'country_code': 'PH',
    //   'language':'en_us'
    // });
    return this.http.post(env.EMAIL_CHECK_API+"/sg/details",formData,);
  }
}
