<app-header title="Phone verification"></app-header>
<section class="otpContainer mt70">
  <div class="text-center mt-4 mx-4">
    <div class="font-darkblue mb20 fW600 fs20">OTP verification</div>
    <p>
      An authentication code has been sent to
      <span class="d-block font-darkblue fW600">+63 {{ groupedPhonNo }}</span>
    </p>
  </div>

  <div class="row justify-content-center">
    <div class="col-12">
      <form
        [formGroup]="registerOtpForm"
        (ngSubmit)="onSubmitRegOtpForm()"
        class="otpForm"
      >
        <div class="form-group text-center mt-3">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{ length: 6, allowNumbersOnly: true }"
            [ngClass]=""
            #ngOtpInput
          >
          </ng-otp-input>
          <div *ngIf="invalidOtp" class="error mt20">
            The OTP entered is incorrect
          </div>

          <div *ngIf="submitRegOtpForm && otp.length < 6" class="error">
            Please enter OTP
          </div>
          <div *ngIf="timeUp" class="mt20">
            <span class="red inline mt20">Time is up. </span
            ><span class="font-blue fW600" (click)="resendOTP()">
              Request another OTP</span
            >
          </div>
          <span *ngIf="!timeUp" class="px-2 text-center d-block mt-5">
            <div
              [class.red]="this.otpColor == 'red'"
              [class.black]="this.otpColor == 'black'"
            >
              <span class="contentSmall">
                <countdown
                  #cd
                  [config]="{ leftTime: 60, format: 'mm:ss', notify: 0 }"
                  (event)="handleEvent($event)"
                ></countdown> </span
              ><span> left </span>
            </div>
          </span>

          <span *ngIf="!timeUp" class="px-2 text-center d-block mt-4"
            >I didn't receive the OTP.
            <a class="font-blue fW600" (click)="resendOTP()"
              >Resend OTP</a
            ></span
          >
        </div>

        <div class="text-center mt50">
          <button class="btn btn-primary me-2" type="submit">Confirm</button>
        </div>
      </form>
    </div>
  </div>
</section>
