import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { first } from "rxjs/operators";
import { ApiService } from "src/app/service/api.service";
import { environment as env } from "../../../environments/environment";
import { v4 as uuidv4 } from "uuid";
import { stringify } from "querystring";
import { CountdownComponent } from "ngx-countdown";
import { BugsnagService } from "src/app/services/bugsnag.service";

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.scss"],
})
export class OtpComponent implements OnInit {
  registerOtpForm: FormGroup;
  invalidOtp: boolean = false;
  otp: string = '';
  submitRegOtpForm: boolean = false;
  timeLeft: any;
  formValue: any;
  mobileno: any;
  addUserRegisterForm: any = {};
  otpColor: string;
  beginTimer: boolean = false;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  showResendOtp: boolean;
  timeUp: boolean = false;
  scanCode: string;
  isScanCodeAvailable: any;
  groupedPhonNo: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { formValue: string };
    this.formValue = state.formValue;
  }

  ngOnInit(): void {
    this.mobileno = this.formValue.mobileNumber;
    this.beginTimer = true;
    let phoneNumberString = this.mobileno
    phoneNumberString = phoneNumberString.toString()
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    if (phoneNumberString.length == 8) {
      var match = cleaned.match(/^(\d{4})(\d{4})$/)
      this.groupedPhonNo = match[1] + ' ' + match[2];
    }
    else if (phoneNumberString.length == 9) {
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/)
      this.groupedPhonNo = match[1] + ' ' + match[2] + ' ' + match[3];
    }
    else {
      var match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/)
      this.groupedPhonNo = match[1] + ' ' + match[2] + ' ' + match[3];
    }

    this.registerOtpForm = this.formBuilder.group({
      otp1: ["", Validators.required],
      otp: ["", Validators.required],
    });
    // this.formatPhoneNumber(this.mobileno)
  }

  get regOtpFormVal() {
    return this.registerOtpForm.controls;
  }

  formatPhoneNumber(mob) {
    var cleaned = ('' + mob).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/)
  }

  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft <= 10000) {
      this.otpColor = "red";
    }
    if (this.timeLeft == 0) {
      this.timeUp = true;
    }
  }

  onOtpChange(otp) {
    console.log(otp);
    this.invalidOtp = false;
    this.otp = otp;
  }

  resendOTP() {
    this.timeUp = false;
    this.otpColor = "black"
    this.beginTimer = true;
    this.ngOtpInput.setValue("");
    this.countdown.restart()
    this.spinner.show();
    let getMobile = sessionStorage.getItem("mobileNo");
    let formData = {
      identifierType: "MOBILE",
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: this.formValue.password,
      confirmPassword: this.formValue.password,
    };
    let myuuid = uuidv4();
    let loggerFormData = {
      unique_id: myuuid,
      message: JSON.stringify(formData),
    };

    this.spinner.show();
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe(
        (res) => {
          let myuuid = uuidv4();
          let loggerFormData = {
            unique_id: myuuid,
            message: JSON.stringify(res),
          };

          this.spinner.hide();
          if (res["status"].code == 200) {
            let sessionId = res["user"].sessionId;
            sessionStorage.setItem("sessionID", JSON.stringify(sessionId));
          }
          this.showResendOtp = false;
          //this.ngOtpInput.setValue('');

          let sessionIdValue = JSON.parse(sessionStorage.getItem("sessionID"));
          //this.tokenReGenerate();
          let otpGenerateData = {
            mobile: getMobile,
            deviceId: env.DEVICE_ID,
            brand: env.BRAND,
            sessionId: sessionIdValue,
          };
          this.beginTimer = true;
          this.spinner.show();
          this.apiService
            .otpGenerate(otpGenerateData)
            .pipe(first())
            .subscribe(
              (res) => {
                //window.alert('OTP sent');
                this.spinner.hide();
                if (res["status"].code == 200) {
                } else if (res["status"].code == 401) {
                  this.apiService.logout();
                } else {
                  this.apiService.showToaster(res["status"].message);
                }
              },
              (err) => {
                this.spinner.hide();
                console.log(err, "=== OTP gererate error list =======");
                this.bsService.notifyError(err, otpGenerateData);
              }
            );
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
          this.bsService.notifyError(err, formData);
        }
      );
  }

  isEmpty(scanCode) {
    if (scanCode == "" || scanCode == null || scanCode == undefined)
      return false;
    else return true;
  }

  onSubmitRegOtpForm() {
    this.submitRegOtpForm = true;
    if (this.otp == undefined || this.otp.length < 6) {
      console.log("otp err");
      return;
    }
    if (this.formValue.reset == true) {
      let getMobile = sessionStorage.getItem("mobileNo");
      let token = sessionStorage.getItem("authToken");
      let sessionId = sessionStorage.getItem("sessionID");

      let otpSubmitData = {
        identifierType: "MOBILE",
        identifierValue: String(getMobile),
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: JSON.parse(sessionId),
        otp: this.otp,
      };

      this.spinner.show();
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res["status"].code == 200) {
              this.beginTimer = false;
              sessionStorage.setItem("authToken", res["auth"].token);
              sessionStorage.setItem("authKey", res["auth"].key);
            } else {
              this.invalidOtp = true;
            }
          },
          (err) => {
            console.log("err", err);
            this.bsService.notifyError(err, otpSubmitData);
          }
        );
    } else {
      let formValues = this.registerOtpForm.value;
      let sessionIdValue = JSON.parse(sessionStorage.getItem("sessionID"));
      let otpSubmitData = {
        identifierType: "MOBILE",
        identifierValue: String(this.formValue.mobileNumber),
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: sessionIdValue,
        otp: this.otp,
      };
      this.spinner.show();
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe((res) => {
          this.spinner.hide();
          if (res["status"].code == 200) {
            sessionStorage.setItem("authToken", res["auth"].token);
            sessionStorage.setItem("authKey", res["auth"].key);
            let token = res["auth"].token;

            this.apiService.getPosition().then(
              (pos) => {
                let lat = pos.lat;
                let lang = pos.lng;
                let latitude = JSON.stringify(lat);
                let longitude = JSON.stringify(lang);
                let authToken = sessionStorage.getItem("authToken");
                let authKey = sessionStorage.getItem("authKey");

                let filledFormValues = this.getuserForm(
                  this.formValue,
                  latitude,
                  longitude
                );
                console.log(filledFormValues, "filledFormValues");
                this.spinner.show();

                this.apiService
                  .addCapCustomer(
                    filledFormValues,
                    String(this.formValue.mobileNumber),
                    token
                  )
                  .pipe(first())
                  .subscribe(
                    (res: any) => {
                      this.spinner.hide();
                      if (res["status"].code == 200) {
                        sessionStorage.setItem("userPassword", this.formValue.password);
                        console.log("customer added");
                        this.scanCode = sessionStorage.getItem("scannedCode");
                        this.isScanCodeAvailable = this.isEmpty(this.scanCode);

                        //  this.router.navigate(['signup-success']);
                        if (this.isScanCodeAvailable) {
                          this.router.navigate(["signup-scan-success"]);
                        } else {
                          this.router.navigate(["select-brand"]);
                        }
                      }
                    },
                    (err) => {
                      this.spinner.hide();
                      console.log(err);
                      this.bsService.notifyError(err, filledFormValues);
                    }
                  );
              },
              (err) => {
                this.spinner.hide();
                console.log(err);
              }
            );
          } else if (res["status"].code == 1506) {
            this.spinner.hide();
            this.invalidOtp = true;
          }
        }),
        (err) => {
          console.log(err), this.spinner.hide();
          this.bsService.notifyError(err, otpSubmitData);
        };
    }
  }

  getuserForm(userData, latitude, longitude) {
    if (userData.interestedInAbbottBrands == "yes") {
      userData.interestedInAbbottBrands = true;
    } else {
      userData.interestedInAbbottBrands = false;
    }
    return (this.addUserRegisterForm = {
      root: {
        customer: [
          {
            mobile: userData.mobileNumber,
            email: userData.email,
            name: userData.fullName,
            custom_fields: {
              field: [
                {
                  name: "reglat",
                  value: latitude,
                },
                {
                  name: "reglong",
                  value: longitude,
                },
                {
                  name: "interestedbrand",
                  value: JSON.stringify(userData.interestedInAbbottBrands),
                },
                {
                  name: "selectedbrand",
                  value: userData.abbottBrands,
                },
              ],
            },
            extended_fields: {
              field: [
                // {
                //   name: 'dob',
                //   value: moment(userData.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
                // },
              ],
            },
            subscriptions: {
              subscription: [
                {
                  priority: "BULK",
                  scope: "all",
                  is_subscribed:
                    userData.contactByEmailOrSMS == true ? "1" : "0",
                  channel: "email",
                },
                {
                  priority: "BULK",
                  scope: "all",
                  is_subscribed:
                    userData.contactByEmailOrSMS == true ? "1" : "0",
                  channel: "sms",
                },
              ],
            },
          },
        ],
      },
    });
  }
}
