<!-- <h1 class="text-center">This is a test landing page for Pediasure Singapore</h1>
<img src="../assets/images/Pediasure Image.JPG" /> -->

<router-outlet></router-outlet>

<ngx-spinner
  template="<img src='../assets/images/Animation Loading Pediasure.png' />"
>
</ngx-spinner>
<!-- <section class="globalContainer pt-5 bg-Blue ">
  <div class="comingSoonContainer mt-5 px15">
    <div class="comingSoonCard text-center px15 text-white">
      <figure>
        <img class="main-logo" src="../assets/images/abbottlogo.png" alt="logo" />
      </figure>
      <h3>Coming Soon!</h3>
      <p>
        Thank you for your interest. Want to be informed when we launch? Please send us your email and we'll let you know.
      </p>

      <form
        [formGroup]="emailForm"
        (ngSubmit)="onSubmitEmailForm()"
        class="formContainer"
      >
        <div class="field">
          <input type="email" formControlName="email" placeholder="Email" />
        </div>

        <div
          *ngIf="submittedEmailForm && emailFormVal.email.errors"
          class="error"
        >
          <span *ngIf="emailFormVal.email.errors.required"
            >Email is required</span
          >
          <span *ngIf="emailFormVal.email.errors.email">Invalid Email</span>
        </div>

        <div class="field">
          <button type="submit" class="primaryBtn Btn btn-white">Submit</button>
        </div>
      </form>
    </div>
  </div>
  <div class="abbott-rewards">
    <div class="rewards-inner">
      <img src="../assets/images/Ensure-logo.png" />
      <div class="reward-content">
        <p>Per DOH-FDA CFRR Promo Permit XXXX s 2021</p>
      </div>
      <div class="dash"></div>
    </div>
  </div>
</section> -->