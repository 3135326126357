<app-header title="Progress Badges"></app-header>

<section class="globalContainer">
  <div class="progress-bar-container">
    <div class="badges mb-4">
      <img src="../../../assets/images/pediasure/progress-badges-profile-img.png"
        class="img-rounded"
      />

      <!-- <img *ngIf="finalUserImage" [src]="finalUserImage" class="img-rounded" /> -->
      <div class="ps-3">
        <h3 class="title">Miguel’s</h3>
        <span class="sub-title">Progress Badges</span>
      </div>
    </div>

    <p>
      Yay for growth pursuits! Earn badges here to enjoy even more fun rewards.
      <img
        (click)="openEarnModal()"
        src="../../../assets/images/pediasure/badges-info.png"
      />
    </p>

    <div class="badge-profile-container">
      <!-- =========== profile one =========== -->

      <div class="badge-profile">
        <img
          *ngIf="appetite == '3'"
          src="../../../assets/images/pediasure/badges/badge_a/badge_a_a.png"
          class="img-fluid"
        />
        <div class="">
          <img
            (click)="onClickNotScanned()"
            *ngIf="appetite == '0'"
            src="../../../assets/images/pediasure/badges/badge_a/badge_a_b.png"
            class="img-fluid"
          />
          <img
            (click)="onClickNotYetTimeToClaim('appetite')"
            *ngIf="appetite == '1'"
            src="../../../assets/images/pediasure/badges/badge_a/badge_a_c.png"
            class="img-fluid"
          />
          <img
            (click)="openBadgePopup('appetite')"
            *ngIf="appetite == '2'"
            src="../../../assets/images/pediasure/badges/badge_a/badge_a_c.png"
            class="img-fluid"
          />
        </div>
        <p><label>WEEK 4:</label><span>BETTER APPETITE</span></p>
      </div>

      <!-- =========== profile two =========== -->
      <div class="badge-profile">
        <img
          *ngIf="weightGain == '3'"
          src="../../../assets/images/pediasure/badges/badge_b/badge_b_a.png"
          class="img-fluid"
        />
        <div class="">
          <img
            (click)="onClickNotScanned()"
            *ngIf="weightGain == '0'"
            src="../../../assets/images/pediasure/badges/badge_b/badge_b_b.png"
            class="img-fluid"
          />
          <img
            (click)="onClickNotYetTimeToClaim('weightGain')"
            *ngIf="weightGain == '1'"
            src="../../../assets/images/pediasure/badges/badge_b/badge_b_c.png"
            class="img-fluid"
          />
          <img
            (click)="openBadgePopup('weightGain')"
            *ngIf="weightGain == '2'"
            src="../../../assets/images/pediasure/badges/badge_b/badge_b_c.png"
            class="img-fluid"
          />
        </div>
        <p><label>WEEK 8:</label><span>WEIGHT GAIN</span></p>
      </div>

      <!-- =========== profile three =========== -->
      <div class="badge-profile">
        <img
          *ngIf="heightGain == '3'"
          src="../../../assets/images/pediasure/badges/badge_c/badge_c_a.png"
          class="img-fluid"
        />
        <div class="">
          <img
            (click)="onClickNotScanned()"
            *ngIf="heightGain == '0'"
            src="../../../assets/images/pediasure/badges/badge_c/badge_c_b.png"
            class="img-fluid"
          />
          <img
            (click)="onClickNotYetTimeToClaim('heightGain')"
            *ngIf="heightGain == '1'"
            src="../../../assets/images/pediasure/badges/badge_c/badge_c_c.png"
            class="img-fluid"
          />
          <img
            (click)="openBadgePopup('heightGain')"
            *ngIf="heightGain == '2'"
            src="../../../assets/images/pediasure/badges/badge_c/badge_c_c.png"
            class="img-fluid"
          />
        </div>
        <p><label>WEEK 12:</label><span>HEIGHT GAIN</span></p>
      </div>

      <!-- =========== profile four =========== -->
      <div class="badge-profile">
        <img
          *ngIf="lessSickdays == '3'"
          src="../../../assets/images/pediasure/badges/badge_d/badge_d_a.png"
          class="img-fluid"
        />
        <div class="">
          <img
            (click)="onClickNotScanned()"
            *ngIf="lessSickdays == '0'"
            src="../../../assets/images/pediasure/badges/badge_d/badge_d_b.png"
            class="img-fluid"
          />
          <img
            (click)="onClickNotYetTimeToClaim('lessSickdays')"
            *ngIf="lessSickdays == '1'"
            src="../../../assets/images/pediasure/badges/badge_d/badge_d_c.png"
            class="img-fluid"
          />
          <img
            (click)="openBadgePopup('lessSickdays')"
            *ngIf="lessSickdays == '2'"
            src="../../../assets/images/pediasure/badges/badge_d/badge_d_c.png"
            class="img-fluid"
          />
        </div>
        <p><label>WEEK 16:</label><span>LESS SICK DAYS</span></p>
      </div>
    </div>
  </div>

  <!-- ===================== Earning Progress badges popup ========================= -->
  <div
    bsModal
    #progressInfoPopup="bs-modal"
    class="modal bg-modal-progress-badge bg-none px20"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content badge-modal-bg">
        <div class="modal-body p-4">
          <div class="">
            <div class="text-center content text-white">
              <h4 class="fs19 text-center fW900 badge-title-color">
                EARNING PROGRESS BADGES
              </h4>

              <div class="badges-pop">
                <div class="fs16">
                  <p>
                    To earn badges, you must see your child exhibiting these
                    milestones.
                  </p>
                  <p>
                    To earn badges, scanning of either of the following
                    purchased products in the last
                    <span class="txt-color">28 days</span> is also required:
                  </p>
                  <ul
                    class="badge-list-color fW600 badge-list-padding my-3 fs16"
                  >
                    <li>2 boxes of PediaSure Plus<sup>®</sup> 1.2kg</li>
                    <li>1 box of PediaSure Plus<sup>®</sup> 2.4kg</li>
                  </ul>
                </div>
              </div>
            </div>
            <button
              type="submit"
              (click)="closeInfoModal()"
              class="primaryBtn-lightBlue-bg progress-badge-btn fW500 Btn mt-4"
            >
              <strong>Close</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ======================== products not scanned popup ====================== -->
  <div
    bsModal
    #productNotYetScanned="bs-modal"
    class="modal bg-modal-progress-badge px20"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content badge-modal-bg">
        <div class="modal-body p-4">
          <div class="badges-pop">
            <div class="text-center content text-white">
              <div class="badges-pop">
                <div class="fs16">
                  <p>
                    Oops! You have not scanned any of the following in the last
                    <span class="txt-color">28 days:</span>
                  </p>
                  <ul
                    class="badge-list-color fW600 badge-list-padding my-3 fs16"
                  >
                    <li>2 boxes of PediaSure Plus<sup>®</sup> 1.2kg</li>
                    <li>1 box of PediaSure Plus<sup>®</sup> 2.4kg</li>
                  </ul>
                  <p>Scan now so you can claim this badge!</p>
                </div>
              </div>
            </div>
            <button
              [routerLink]="['/qr-scanner']"
              class="primaryBtn-lightBlue-bg progress-badge-btn fW500 Btn mt-4"
            >
              Scan Now
            </button>
            <button
              type="submit"
              (click)="closeInfoModal()"
              class="progress-badge-btn cancel-btn fW500 Btn mt-2"
            >
              <strong>Maybe later</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ======================== Progress Badges Not the time popup - Better Appetite ====================== -->
  <div
    bsModal
    #notYetTimeToClaim="bs-modal"
    class="modal bg-modal-progress-badge px20"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content badge-modal-bg">
        <div class="modal-body p-4">
          <div class="">
            <div class="text-center content text-white">
              <div class="fs16">
                <p class="d-block" *ngIf="statusAppetite">
                  Oops! <span class="txt-color">4 weeks (28 days)</span> have
                  NOT yet passed since your child started drinking PediaSure
                  Plus<sup>®</sup>.
                </p>

                <p class="d-block" *ngIf="statusWTGain">
                  Oops! <span class="txt-color">8 weeks (56 days)</span> have
                  NOT yet passed since your child started drinking PediaSure
                  Plus<sup>®</sup>.
                </p>

                <p class="d-block" *ngIf="statusHTGain">
                  Oops! <span class="txt-color">12 weeks (84 days)</span> have
                  NOT yet passed since your child started drinking PediaSure
                  Plus<sup>®</sup>.
                </p>

                <p class="d-block" *ngIf="statusLSD">
                  Oops! <span class="txt-color">16 weeks (112 days)</span> have
                  NOT yet passed since your child started drinking PediaSure
                  Plus<sup>®</sup>.
                </p>

                <p>Come back by then to claim this badge!</p>
              </div>
            </div>
            <button
              class="primaryBtn-lightBlue-bg progress-badge-btn fW500 Btn mt-4"
              (click)="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ======================= Confirm and Claim badge popup ======================== -->

  <div
    bsModal
    #badgePopup="bs-modal"
    class="modal px20"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content badge-modal-bg2">
        <div class="modal-body p-4">
          <div *ngIf="statusAppetite" class="text-center content text-white">
            <div
              class="
                d-flex
                flex-column
                fs19
                text-center
                fW900
                badge-title-color
                mb-3
              "
            >
              <label>WEEK 4:</label> <span>BETTER APPETITE</span>
            </div>
            <img
              src="../../../assets/images/pediasure/badges/less_sick_days_unlocked.png"
            />
            <div class="fs16 mt-3">
              <p>
                Please confirm that your child has a better appetite after
                <span class="txt-color">4 weeks (28 days)</span> of drinking
                <span class="txt-color">PediaSure Plus®.</span>
              </p>
            </div>
            <button
              (click)="claimBadge('appetite')"
              type="submit"
              class="progress-badge-btn fW500 Btn mt-4"
            >
              <strong>Confirm and claim this badge</strong>
            </button>
          </div>

          <div *ngIf="statusWTGain" class="text-center content text-white">
            <div
              class="
                d-flex
                flex-column
                fs19
                text-center
                fW900
                badge-title-color
                mb-3
              "
            >
              <label>WEEK 8:</label> <span>WEIGHT GAIN</span>
            </div>
            <img
              src="../../../assets/images/pediasure/badges/weight_gain_unlocked.png"
            />
            <div class="fs16 mt-3">
              <p>
                Please confirm that your child's weight has increased after
                <span class="txt-color">8 weeks (56 days)</span> of drinking
                <span class="txt-color fw-bold">PediaSure Plus®.</span>
              </p>
            </div>
            <button
              (click)="claimBadge('weightGain')"
              type="submit"
              class="progress-badge-btn fW500 Btn mt-4"
            >
              <strong>Confirm and claim this badge</strong>
            </button>
          </div>

          <div *ngIf="statusHTGain" class="text-center content text-white">
            <div
              class="
                d-flex
                flex-column
                fs19
                text-center
                fW900
                badge-title-color
                mb-3
              "
            >
              <label>WEEK 12:</label> <span>HEIGHT GAIN</span>
            </div>
            <img
              src="../../../assets/images/pediasure/badges/height_gain_unlocked.png"
            />
            <div class="fs16 mt-3">
              <p>
                Please confirm that your child's height has increased after
                <span class="txt-color">12 weeks (84 days)</span> of drinking
                <span class="txt-color fw-bold">PediaSure Plus®.</span>
              </p>
            </div>
            <button
              (click)="claimBadge('heightGain')"
              type="submit"
              class="progress-badge-btn fW500 Btn mt-4"
            >
              <strong>Confirm and claim this badge</strong>
            </button>
          </div>

          <div *ngIf="statusLSD" class="text-center content text-white">
            <div
              class="
                d-flex
                flex-column
                fs19
                text-center
                fW900
                badge-title-color
                mb-3
              "
            >
              <label>WEEK 16:</label> <span>HEIGHT GAIN</span>
            </div>
            <img
              src="../../../assets/images/pediasure/badges/better_appetite_unlocked.png"
            />
            <div class="fs16 mt-3">
              <p>
                Please confirm that your child has less sick days after
                <span class="txt-color">16 weeks (112 days)</span> of drinking
                <span class="txt-color fw-bold">PediaSure Plus®.</span>
              </p>
            </div>
            <button
              (click)="claimBadge('lessSickdays')"
              type="submit"
              class="progress-badge-btn fW500 Btn mt-4"
            >
              <strong>Confirm and claim this badge</strong>
            </button>
          </div>

          <button
            type="submit"
            (click)="closeBadge()"
            class="progress-badge-btn cancel-btn fW500 Btn mt-2"
          >
            <strong>Cancel</strong>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- ==================== Progress Badges Badge popup =================== -->

  <!-- <div bsModal #progressInfoPopup="bs-modal" class="modal px20" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content badge-modal-bg2">
        <div class="modal-body p-4">

          
          <div class="text-center content text-white">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 4:</label> <span>BETTER APPETITE</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/less_sick_days_unlocked.png" />
            <div class="fs16 mt-3">
              <p><img src="../../../assets/images/pediasure/claim_success_img.png" />You have claimed this badge</p>
            </div>
          </div>



          <div class="text-center content text-white">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 8:</label> <span>WEIGHT GAIN</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/weight_gain_unlocked.png" />
            <div class="fs16 mt-3">
              <p><img src="../../../assets/images/pediasure/claim_success_img.png" />You have claimed this badge</p>
            </div>
          </div>


          <div class="text-center content text-white">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 12:</label> <span>HEIGHT GAIN</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/height_gain_unlocked.png" />
            <div class="fs16 mt-3">
              <p><img src="../../../assets/images/pediasure/claim_success_img.png" />You have claimed this badge</p>
            </div>
          </div>


          <div class="text-center content text-white">
            <div class="d-flex flex-column fs19 text-center fW900 badge-title-color mb-3">
              <label>WEEK 16:</label> <span>LESS SICK DAYS</span>
            </div>
            <img src="../../../assets/images/pediasure/badges/less_sick_days_unlocked.png" />
            <div class="fs16 mt-3">
              <p><img src="../../../assets/images/pediasure/claim_success_img.png" />You have claimed this badge</p>
            </div>
          </div>
          


          
          <button type="submit" (click)="closeInfoModal()" class="progress-badge-btn fW500 Btn">
            <strong>Close</strong>
          </button>

        </div>
      </div>
    </div>
  </div> -->
</section>

<app-menu-pediasure
  [customerNamePresent]="customerNamePresent"
></app-menu-pediasure>
