<app-header title="Product Benefits"></app-header>
<section>
  <section class="product-benifits min-vh-100 pb70 bg-path">
    <div class="bg-path"></div>
    <div class="three-GoldLineBG mt10">
      <div class="pd">
        <div class="d-flex justify-content-around mt20">
          <!-- <div class="three-GoldLineBG "></div> -->
          <div class="col-4">
            <img src="../../../../assets/images/Group 5555.png" />
          </div>
          <div class="col-7 mt-auto d-flex justify-content-end mt30">
            <span class="text-right">
              <img
                class="benefit-1-img"
                src="../../../assets/images/Polygon6.png"
            /></span>
            <div class="mx-3">
              <h4 class="fs16 font-gold">
                Calcium HMB and high-quality triple protein complex
              </h4>
              <p class="color-white">Helps build and maintain muscle mass</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="benefits pd mt20">
      <div class="d-flex mt30">
        <span class="mt-4">
          <img src="../../../assets/images/Polygon6.png"
        /></span>
        <div>
          <h4 class="fs16 font-gold mt30">
            High in Calcium, Vitamin K and Vitamin D
          </h4>
          <p class="color-white">
            Helps build strong bones and support calcium absorption
          </p>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <span class="mt2">
            <img src="../../../assets/images/Polygon6.png"
          /></span>
          <div>
            <h4 class="fs16 font-gold">High in Iodine and Phosphorus</h4>
            <p class="color-white">Contributes to normal energy metabolism</p>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <span class="mt2">
            <img src="../../../assets/images/Polygon6.png"
          /></span>
          <div>
            <h4 class="fs16 font-gold">
              High in Vitamins B6, B12, C, D, and Minerals Iron, Zinc, and
              Selenium
            </h4>
            <p class="color-white">
              Necessary for normal immune system function
            </p>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <span class="mt2">
            <img src="../../../assets/images/Polygon6.png"
          /></span>
          <div>
            <h4 class="fs16 font-gold">
              Contains α-linolenic acid (Omega-3) and linoleic acid (Omega-6)
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-menu
    [customerNamePresent]="customerNamePresent"
    [brand]="rewardInfo.name"
  ></app-menu>
</section>
