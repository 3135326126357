<div class="pageHeader row mx-0 px30">
    <div class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex">
        <a (click)="onClickBack()">
            <i class="fa fa-angle-left"></i>
        </a>
        <h1>About PediaSure Plus<sup>®</sup></h1>
    </div>
</div>
<section class="globalContainer">

    <section class="about-pediasure min-vh-100 bg">
        <div class="div-one">
            <div class="top-heading d-flex justify-content-center pt-2 ">
                <div class="img-div">
                    <img class="pediasure_glasses" src="../../../assets/images/pediasure/pediasure_glasses.png" />
                </div>
                <div class="text-one">
                    <h2 class="pt-2">PediaSure Plus<sup>®</sup>

                        <span>with TripleSure System</span>
                    </h2>
                    <p class="pt-2">
                        <span class="pb-2 d-block">
                            Is your child hard to feed?
                        </span>
                        The term hard-to-feed refers to children who tend to eat small amounts, favor only certain foods
                        or avoid trying new foods. If prolonged, hard-to-feed behaviors may create risks of nutritional
                        gaps<sup>1</sup> in your child’s diet.
                    </p>
                </div>


            </div>

            <div class="text-two d-block mt-2">
                <span>PediaSure Plus<sup>®</sup></span> can be used as a nutritional supplement with or between meals.
                <span>Now with added Arginine and Natural Vitamin K2. </span>
            </div>
            <div class="text-three d-block mt-2"><sup>1</sup> Increased calories and protein may help promote growth</div>

            <div class="icons-div d-flex text-center align-items-center mt-5">
                <div class="item_div">
                    <img class="item-img" src="../../../assets/images/pediasure/triple_protein_icon.png" />
                    <span>Triple Protein Complex</span>
                </div>
                <div class="item_div">
                    <img class="item-img" src="../../../assets/images/pediasure/unique_synbiotic_icon.png" />
                    <span>Unique Synbiotic Blend</span>
                </div>
                <div class="item_div">
                    <img class="item-img" src="../../../assets/images/pediasure/natural_vitamin_icon.png" />
                    <span>Natural Vitamin K2 and Arginine</span>
                </div>
            </div>

        </div>
        <div class="div-two mt-5 mb-5">
            <img class="floting-img" src="../../../assets/images/pediasure/pediasure-product_img.png" />
            <div class="container pt-5 pb-5">
                <div class="row mb-2">
                    <div class="icon-div col-2">
                        <img src="../../../assets/images/pediasure/triple_protein_complex_icon.png" />
                    </div>
                    <div class="text-div col-8">
                        <span>Triple Protein Complex</span>A combination of whey, soy and casein, a source of Protein
                        which contributes to growth and maintenance of muscle mass.
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="icon-div col-2">
                        <img src="../../../assets/images/pediasure/growth_and_energy_icon.png" />
                    </div>
                    <div class="text-div col-10">
                        <span>Growth and Energy Support Nutrients</span>A source of Calcium, Zinc and Vitamin D. Calcium
                        helps build strong bones and Zinc is essential for growth. Vitamin D supports normal growth and
                        development of the bone. It also has carbohydrates that help provide energy and is a source of
                        B-Vitamins that contributes to normal energy-yielding metabolism.
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="icon-div col-2">
                        <img src="../../../assets/images/pediasure/immunity_support_nutrients_icon.png" />
                    </div>
                    <div class="text-div col-10">
                        <span>Immunity Support Nutrients</span> High source of Vitamin C and is a source of Zinc and
                        Vitamins A and D which contribute to the normal function of the immune system.
                    </div>
                </div>

                <div class="how-prepair-div text-center mt-2 mb-2">
                    <h3>HOW TO PREPARE</h3>
                    <p>To prepare one serving: Pour 190 mL of room temperature or cold water in a cup and gradually mix
                        in 5 leveled scoops or 48.6g of <span>PediaSure Plus<sup>®</sup></span> powder.</p>

                    <img src="../../../assets/images/pediasure/how_to_prepare.png" />
                </div>

                <div class="flavors-div text-center mb-2">
                    <h3>FLAVORS</h3>
                    <p><span>PediaSure Plus<sup>®</sup></span>comes in 4 different flavors:</p>

                    <div class="row">
                        <div class="flavor-item col-6">
                            <img src="../../../assets/images/pediasure/flavors_img.png" />
                            <p>Vanilla</p>
                        </div>
                        <div class="flavor-item col-6">
                            <img src="../../../assets/images/pediasure/flavors_img.png" />
                            <p>Strawberry</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="flavor-item col-6">
                            <img src="../../../assets/images/pediasure/flavors_img.png" />
                            <p>Choco</p>
                        </div>
                        <div class="flavor-item col-6">
                            <img src="../../../assets/images/pediasure/flavors_img.png" />
                            <p>Creamy Milk</p>
                        </div>
                    </div>

                </div>

                <div class="kids-approved-div text-center  mb-2">
                    <h3>KID APPROVED TASTE!</h3>
                    <p>And a Ready-to-Drink format!</p>
                    <img src="../../../assets/images/pediasure/pediasure-product_img.png" />
                </div>

            </div>
<!-- <i class="fa fa-arrow-up"></i> -->

<img
src="../../../assets/images/Scroll-UpWhiteBg.svg"
class="scroll-img"
(click)="onArrowUp()"
/>

        </div>



    </section>
    <div class="bottom-bg"></div>
</section>

<app-menu-pediasure   [customerNamePresent]="customerNamePresent"
[brand]="rewardInfo.name"></app-menu-pediasure>