<div
  bsModal
  #notLoginOrSignup="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto modal-dialog-centered px-3">
    <div
      class="modal-content modal-content-glucerna-bg text-center br10 px20 py-3"
    >
      <div class="text-center mt-3 mb-4">
        <img
          src="../../../assets/images/glucerna/abbott-logo.svg"
          class="reward-brand-logos"
        />
      </div>
      <div class="d-flex justify-content-between my-3 mt-3 px-4">
        <img
          class="reward-brand-logos"
          src="../../../assets/images/glucerna/glucerna-rewards-img.svg"
          style="width: 100px"
        />
        <img
          class="reward-brand-logos"
          src="../../../assets/images/glucerna/alaga-rewards-img.svg"
          style="width: 100px"
        />
      </div>
      <div class="text-white">
        <h4 class="sub-head my-4">
          Glucerna Rewards is now part of Abbott Alaga Rewards!
        </h4>
        <p>
          If you have an existing Glucerna Rewards account, your reward points
          have been automatically transferred to Abbott Alaga Rewards under
          Glucerna® reward points, and you can use your Glucerna Rewards mobile
          number and password to log in to Abbott Alaga Rewards. <br /><br />If
          you need any assistance, feel free to <br />
          <span
            ><a [routerLink]="['/contact-us']" class="btn-link-fce"
              >Contact Us</a
            ></span
          >.
        </p>
      </div>
      <div class="text-center">
        <button
          (click)="onClickContinue()"
          class="primaryBtn fW100 continue-btn mt-4"
          type="submit"
        >
          Continue
        </button>

        <div
          class="
            form-check
            mt-3
            px-4
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <input
            type="checkbox"
            class="form-check-input"
            class="check3 me-1"
            (change)="checkValue($event)"
          />
          <label class="form-input-label text-white"
            >Do not show this message again</label
          >
        </div>
      </div>
    </div>
  </div>
</div>
