import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, OnChanges {
  @ViewChild("notLoginOrSignup", { static: true })
  notLoginOrSignup: ModalDirective;
  @ViewChild("button") button!: ElementRef;
  isChecked: boolean = false

  isTransactionsVisited = false;
  isRewardsVisited = false;
  @Input() customerNamePresent: any;
  @Input() rewardVisited = false;
  @Input() transactionsVisited = false;
  url: string;
  @Input() brand: any;
  scanCode: string;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes["rewardVisited"]) {
      this.rewardVisited = changes["rewardVisited"].currentValue;
      console.log("reward: ", this.rewardVisited);
    }
    if (changes["transactionsVisited"]) {
      this.transactionsVisited = changes["transactionsVisited"].currentValue;
      console.log("transactions: ", this.transactionsVisited);
    }
    if (changes["brand"]) {
      this.brand = changes["brand"].currentValue;
      console.log("brand: ", this.brand);
    }
  }

  checkValue(event) {
    var inputs = document.querySelectorAll(".check3");
    console.log("event: ", event.target.value);
    console.log("input: ", inputs[0]['checked'])
    if (inputs[0]['checked'] == true) sessionStorage.setItem('showGlucernaPopup', 'false')
    else sessionStorage.setItem('showGlucernaPopup', 'true')
  }

  ngOnInit(): void {
    console.log(this.brand);
    this.route.url.subscribe((url) => {
      this.url = url[0].path;
      console.log(this.url);
    });
    this.notLoginOrSignup.show();
  }

  onClickProfile() {
    if (this.customerNamePresent) {
      this.router.navigate(["profile"]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickScan() {
    if (this.customerNamePresent) {
      this.isRewardsVisited = false;
      this.isTransactionsVisited = false;
      this.router.navigate(["qr-scanner"]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickRewards() {
    if (this.customerNamePresent) {
      this.brand = sessionStorage.getItem('currentBrand').toLowerCase();
      // this.isRewardsVisited = this.brand ? true : false;
      // this.isTransactionsVisited = false;
      this.router.navigate(["rewards/brand", { brand: this.brand }]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickTransactions() {
    this.brand = sessionStorage.getItem('currentBrand').toLowerCase();
    console.log(this.brand);
    if (this.customerNamePresent) {
      // this.isRewardsVisited = false;
      // this.isTransactionsVisited = true;
      this.router.navigate([
        "profile/transaction-history",
        { brand: this.brand },
      ]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickCancel() {
    this.notLoginOrSignup.hide();
  }

  onClickContinue() {
    this.scanCode = sessionStorage.getItem('scannedCode')
    if (this.scanCode == null || this.scanCode == undefined || this.scanCode == '') this.router.navigate(['/login-signup'])
    else this.router.navigate(['/welcome'])
  }

}
