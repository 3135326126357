import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-scanner',
  templateUrl: './select-scanner.component.html',
  styleUrls: ['./select-scanner.component.scss']
})
export class SelectScannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
