import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-badge-claimed-pediasure',
  templateUrl: './progress-badge-claimed-pediasure.component.html',
  styleUrls: ['./progress-badge-claimed-pediasure.component.scss']
})
export class ProgressBadgeClaimedPediasureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
