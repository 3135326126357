<app-header title="Sign up"></app-header>

<div
  bsModal
  #tncModal="bs-modal"
  class="modal fade customModalContainer px30 mt40"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content signup-terms br10">
      <div class="modal-body pt-3 content">
        <h3 class="font-blue text-center mt-3 mb-0">Abbott Alaga Rewards:</h3>
        <h4 class="font-blue text-center">Terms of Use</h4>

        <ul class="px15 disc-inside">
          <p class="subheading mb-0 font-blue">
            Effective Date: [1 January 2022]
          </p>
          <li>
            These Online Terms of Use govern your access to websites controlled
            by Abbott Laboratories, including its subsidiaries and affiliates
            (together referred to as "Abbott"), which link to these Online Terms
            of Use (together referred to as "Abbott websites"). These Online
            Terms of Use do not apply to Abbott websites that do not link to
            these Online Terms of Use, to residents of the United States, or to
            third-party websites to which Abbott websites may link. Your use of
            Abbott websites is subject to these Online Terms of Use and the
            Privacy Policy.
          </li>
          <li>
            Without prejudice to your rights under applicable law, Abbott
            reserves the right to amend these Online Terms of Use to reflect
            technological advancements, legal and regulatory changes and good
            business practices. If Abbott changes these Online Terms of Use, an
            updated version of these Online Terms of Use will reflect those
            changes and we will notify you of such changes by updating the
            effective date at the top of these Online Terms of Use. By accessing
            or using the Abbott websites, you agree that you have read,
            understand and agree to be bound to the current version of these
            Online Terms of Use which you may view when accessing the Abbott
            websites. If you disagree with these Online Terms of Use or are
            dissatisfied with the Abbott websites, your sole and exclusive
            remedy is to discontinue using this website.
          </li>
        </ul>
      </div>
      <div class="t-and-c-closeBtn text-center mt12 mb25 mx-3">
        <button
          (click)="onClickClose()"
          class="btn btn-primary button"
          type="submit"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #privacyPolicy="bs-modal"
  class="modal fade customModalContainer px30 mt40"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content br10">
      <div class="modal-body pt-3">
        <h3 class="font-blue text-center mt-3 mb-0">Abbott Alaga Rewards:</h3>
        <h4 class="font-blue text-center">Privacy Policy</h4>
        <ul class="pl15 disc-inside pb70">
          <!-- <li class="pb70"> -->

          <!-- <p class="mb-2 mt-4">
            <span class="subheading font-blue mb-0"
              >Effective Date of this Privacy Policy: 5/17/2013</span
            >
          </p> -->

          <p>
            This Privacy Policy explains how Abbott handles personal information
            that we collect online, through our websites and applications (apps)
            and through our offline interactions with you (collectively, the
            “Abbott Services”). This Privacy Policy does not apply to
            information collected from residents of countries other than
            Philippines, or to third-party websites to which Abbott may link.
            Your use of Abbott Services which link to this Privacy Policy are
            subject to this Privacy Policy and the Terms of Use.
          </p>

          <p>
            Please read this Privacy Policy before using the Abbott Services or
            submitting personal information to us.
          </p>

          <p class="fW900">
            On occasion, Abbott may offer apps, special programs, activities,
            events or promotions (“Programs”) that have unique or additional
            specific terms, privacy notices and/or consent forms that explain
            how any information you provide will be processed in connection with
            the Programs. You should review the applicable terms before
            interacting or participating in the Programs.
          </p>

          <p>
            <span class="subheading font-blue">PERSONAL INFORMATION</span>
          </p>

          <p>
            “Personal information” is information that identifies you as an
            individual or relates to an identifiable individual. Examples of
            personal information include your name, your postal address and your
            telephone number.
          </p>
          <!-- </li> -->
        </ul>
      </div>

      <div class="text-center mt12 mb20 mx-3 t-and-c-closeBtn">
        <button
          (click)="onPnpClose()"
          class="btn btn-primary button"
          type="submit"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<section class="signup-container mt20 pb70 px30">
  <h4 class="text-center fW600">Sign up for an account</h4>
  <div class="text-center">Enter your details to continue</div>
  <form
    [formGroup]="signupForm"
    class="signup-Form mt40"
    (ngSubmit)="onSubmitSignUpForm()"
  >
    <div class="position-relative">
      <div class="form-group">
        <label>Mobile number</label>
        <div class="position-relative">
          <input
            type="number"
            formControlName="mobileNumber"
            class="form-control mobcode ml20"
            placeholder="9xx-xxxx-xxxx"
            [readOnly]="isParamMobileNumber"
            [ngClass]="{
              'is-invalid':
                submittedSignUpForm && signUpFormVal.mobileNumber.errors
            }"
          />

          <div class="icon-box">
            <img
              class="icon-box__flag"
              src="../../../assets/images/Flag-Philippines.png"
            />
            <span class="mobileCode"
              >+63
              <img class="mb10" src="../../../assets/images/down-arrow.png" />
            </span>
          </div>
          <img
            *ngIf="signUpFormVal.mobileNumber.status == 'VALID'"
            class="correct-icon"
            src="../../../assets/images/Group 4868.svg"
          />
        </div>
      </div>
    </div>
    <div
      *ngIf="submittedSignUpForm && signUpFormVal.mobileNumber.errors"
      class="error"
    >
      <span
        *ngIf="
          submittedSignUpForm && signUpFormVal.mobileNumber.errors.required
        "
        >Mobile number is required</span
      >
      <span *ngIf="signUpFormVal.mobileNumber.errors.pattern"
        >Invalid number</span
      >
    </div>

    <div class="position-relative mt20">
      <div class="form-group">
        <label>Password</label>
        <input
          (ngModelChange)="onPasswordCheckPattern($event)"
          [type]="showPasswordText ? 'text' : 'password'"
          formControlName="password"
          class="form-control position-relative"
          placeholder="Enter your password"
          [ngClass]="{
            'is-invalid': submittedSignUpForm && signUpFormVal.password.errors
          }"
        />
        <ng9-password-strength-bar
          [passwordToCheck]="signupForm.value.password"
          [customThresholds]="thresholds"
          [strengthLabels]="strengthLabels"
          (onStrengthChanged)="strengthChanged($event)"
        >
        </ng9-password-strength-bar>

        <img
          class="eye-icon"
          *ngIf="showEyeCloseIcon"
          src="../../../assets/images/Group 5035.svg"
          (click)="onClickEyeCloseIcon()"
        />
        <img
          class="eye-icon-open"
          *ngIf="showEyeOpenIcon"
          src="../../../assets/images/visibility.png"
          (click)="onClickEyeOpenIcon()"
        />

        <div class="error" *ngIf="isPasswordPatternCorrect">
          Password does not meet requirement.
        </div>

        <span class="fs13 color-lightgrey p-10 d-block"
          >Password must be at least six characters, and contain one of the
          following: lower-case letter, upper-case letter, number and special
          character.</span
        >
      </div>
    </div>

    <div
      *ngIf="submittedSignUpForm && signUpFormVal.password.errors"
      class="error"
    >
      <span *ngIf="signUpFormVal.password.errors.required"
        >Password is required</span
      >
      <!-- <span *ngIf="signUpFormVal.password.errors.pattern"
        >Password does not meet requirement.Please try again</span
      > -->
    </div>

    <div class="position-relative mt20">
      <div class="form-group">
        <label>Confirm password</label>
        <input
          (ngModelChange)="onPasswordConfirmChanged($event)"
          [type]="showConfirmPasswordtext ? 'text' : 'password'"
          formControlName="confirmPassword"
          class="form-control position-relative"
          placeholder="Enter your password"
          [ngClass]="{
            'is-invalid':
              submittedSignUpForm && signUpFormVal.confirmPassword.errors
          }"
        />
        <img
          class="eye-icon"
          *ngIf="showEyeCloseIconConfirm"
          src="../../../assets/images/Group 5035.svg"
          (click)="onClickShowConfirmPassword()"
        />
        <img
          class="eye-icon-open"
          *ngIf="showEyeOpenIconConfirm"
          src="../../../assets/images/visibility.png"
          (click)="onClickShowConfirmPassword()"
        />
      </div>
    </div>
    <span class="error" *ngIf="passwordNotMaching"
      >Password does not match</span
    >
    <div
      *ngIf="submittedSignUpForm && signUpFormVal.confirmPassword.errors"
      class="error"
    >
      <span *ngIf="signUpFormVal.confirmPassword.errors.required"
        >Password is required</span
      >
      <!-- <span *ngIf="signUpFormVal.confirmPassword.errors.pattern"
        >Invalid password</span
      > -->
    </div>

    <div class="position-relative">
      <div class="form-group mt20">
        <label>Full name</label>
        <input
          type="text"
          appAlphabetOnly
          formControlName="fullName"
          class="form-control"
          placeholder="Enter your full name"
          [ngClass]="{
            'is-invalid': submittedSignUpForm && signUpFormVal.fullName.errors
          }"
        />
      </div>
    </div>

    <div
      *ngIf="submittedSignUpForm && signUpFormVal.fullName.errors"
      class="error"
    >
      <span *ngIf="signUpFormVal.fullName.errors.required"
        >Name is required</span
      >
    </div>

    <div class="position-relative">
      <div class="form-group mt20">
        <label
          >Email <span class="fs12 color-lightgrey">(optional)</span></label
        >
        <input
          type="email"
          formControlName="email"
          class="form-control"
          placeholder="Enter your email"
          [ngClass]="{
            'is-invalid': submittedSignUpForm && signUpFormVal.email.errors
          }"
        />
      </div>
    </div>

    <div
      *ngIf="submittedSignUpForm && signUpFormVal.email.errors"
      class="error"
    >
      <span *ngIf="signUpFormVal.email.errors.pattern">Email is invalid</span>
    </div>

    <div class="checkbox">
      <div class="input-radio checkbox my-4">
        <label>Interested in knowing about Abbott products?</label>
        <div class="row mt-3">
          <div class="col-6">
            <input
              type="radio"
              value="yes"
              formControlName="interestedInAbbottBrands"
            />
            <label>Yes</label>
          </div>
          <div class="col-6">
            <input
              type="radio"
              value="no"
              formControlName="interestedInAbbottBrands"
            />
            <label>No</label>
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-relative my-4"
      *ngIf="signupForm.value.interestedInAbbottBrands == 'yes'"
    >
      <label class="mb-2">Select all that apply</label>
      <div
        class="row mx-0 py-3 border-bot border-eee"
        *ngFor="let brand of brandsList"
      >
        <div class="col-8 pl-0 d-flex align-items-center">
          <label>{{ brand.name }}</label>
        </div>

        <div
          class="
            col-4
            pr-0
            checkbox
            d-flex
            align-items-center
            justify-content-end
          "
        >
          <input
            type="checkbox"
            [value]="brand.id"
            (change)="onCheckboxChange($event)"
          />
        </div>
      </div>
    </div>
    <div *ngIf="submittedSignUpForm">
      <div
        class="error"
        *ngIf="
          signupForm.value.interestedInAbbottBrands == 'yes' &&
          abbottBrandsList.length == 0
        "
      >
        Please select atleast one brand.
      </div>
    </div>
    <div class="checkbox my-4">
      <input
        type="checkbox"
        class="orm-check-input mr-2 position-relative ml-0"
        value=""
        formControlName="termsConditions"
      />
      <label
        >Yes I am 18 years old and agree to the
        <a (click)="onClickTNC()" class="text-blue blue"
          >Terms and Conditions</a
        ></label
      >
    </div>
    <div
      *ngIf="submittedSignUpForm && signUpFormVal.termsConditions.errors"
      class="error"
    >
      <span *ngIf="signUpFormVal.termsConditions.errors.required"
        >Please check the checkbox</span
      >
    </div>

    <!-- <div class="checkbox my-4">
      <input
        type="checkbox"
        class="orm-check-input mr-2 position-relative ml-0"
        value=""
        formControlName="privacyPolicy"
      />
      <label>
        Yes, by submitting the information above, I agree that the information
        will be governed by the
        <a (click)="onClickTNC()" class="text-blue blue"
          >Terms and Conditions</a
        >
        and this site's
        <a (click)="onClickPrivacyPolicy()" class="text-blue blue"
          >Privacy Policy</a
        >.
      </label>
    </div> -->

    <div
      *ngIf="submittedSignUpForm && signUpFormVal?.privacyPolicy?.errors"
      class="error"
    >
      <span *ngIf="signUpFormVal.privacyPolicy.errors.required"
        >Please check the checkbox</span
      >
    </div>

    <div class="checkbox my-4">
      <input
        type="checkbox"
        class="orm-check-input mr-2 position-relative ml-0"
        value=""
        formControlName="contactByEmailOrSMS"
      />
      <label>
        Yes, I authorize Abbott to contact me by mail, email, phone, SMS and
        WhatsApp regarding Abbott's products and services. I agree to Abbott’s
        collection and use of the details provided in this form for marketing
        purposes in accordance with its
        <a (click)="onClickPrivacyPolicy()" class="text-blue blue"
          >Privacy Policy</a
        >,which I have read and understood. I can withdraw my consent at any
        time by contacting Abbott
      </label>
    </div>

    <div class="text-center mt40">
      <button class="btn btn-primary me-2" type="submit">Submit</button>
    </div>
  </form>

  <div class="text-center mt30">
    <span
      >Already have an account?
      <a href="#" [routerLink]="['/login-signup']" class="blue"
        >Sign in</a
      ></span
    >
  </div>
</section>
