<app-header title="Privacy Policy"></app-header>
<section class="termsContainer pb70 px30">
  <h1 class="fs20 font-darkblue mt40">PRIVACY POLICY</h1>
  <ul class="disc-inside px-0 my-4 pb70">
    <!-- <li class="pb70"> -->

    <!-- <p class="mb-2 mt-4">
            <span class="subheading font-blue mb-0"
              >Effective Date of this Privacy Policy: 5/17/2013</span
            >
          </p> -->

    <p>
      This Privacy Policy explains how Abbott handles personal information that
      we collect online, through our websites and applications (apps) and
      through our offline interactions with you (collectively, the “Abbott
      Services”). This Privacy Policy does not apply to information collected
      from residents of countries other than Philippines, or to third-party
      websites to which Abbott may link. Your use of Abbott Services which link
      to this Privacy Policy are subject to this Privacy Policy and the Terms of
      Use.
    </p>

    <p>
      Please read this Privacy Policy before using the Abbott Services or
      submitting personal information to us.
    </p>

    <p class="fW900">
      On occasion, Abbott may offer apps, special programs, activities, events
      or promotions (“Programs”) that have unique or additional specific terms,
      privacy notices and/or consent forms that explain how any information you
      provide will be processed in connection with the Programs. You should
      review the applicable terms before interacting or participating in the
      Programs.
    </p>

    <p>
      <span class="subheading">PERSONAL INFORMATION</span>
    </p>

    <p>
      “Personal information” is information that identifies you as an individual
      or relates to an identifiable individual. Examples of personal information
      include your name, your postal address and your telephone number.
    </p>
    <!-- </li> -->
  </ul>
  <div class="arrow-up">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
