<section class="globalContainer pt-5 bg-White">
  <div class="welcome-container">
    <div
      class="
        text-center
        d-flex
        justify-content-center
        align-items-center
        topSec
      "
    >
      <!-- <div class="reward-header">
        <img src="../../assets/images/rewards.png" alt="Rewards" />
      </div> -->
      <div class="rewards-header">
        <img
          src="../../../assets/images/ABBOTT LOGO-01.png"
          class="mb-2"
          alt="Rewards"
        />
        <img
          src="../../../assets/images/Group 5170 (2).svg"
          class="w-70"
          alt="Rewards"
        />
      </div>
    </div>
    <div class="welcome-card text-center mt-1">
      <!-- <img src="../../assets/images/pediasure.png" alt="PediaSure" /> -->
      <p class="font-darkblue fs24 mt-4 mb-1">Thank you for your purchase!</p>

      <div class="btnSection mt-2">
        <p>
          To start earning points, sign up for or log in to your
          <span (click)="onClickAlagaRewards()" class="font-darkblue">Abbott Alaga Rewards</span>
          account.
        </p>

        <button
          type="submit"
          class="primaryBtn Btn mt-3"
          (click)="onClickLoginOrAcc()"
        >
          Create account / Log in
        </button>
        <button
          type="submit"
          class="primaryBtn mt-2 btn-white Btn"
          routerLink="/about/family"
        >
          Learn about Abbott Alaga Rewards
        </button>
      </div>
    </div>
  </div>
</section>
