import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { BugsnagService } from '../services/bugsnag.service';
import { first } from 'rxjs/operators';
declare let Email: any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup
  submittedContactForm: boolean = false;
  emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private formBuilder: FormBuilder, private router: Router,private apiService: ApiService,private bsService: BugsnagService) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      privacyPolicy: [false, Validators.requiredTrue],
    })
  }

  get contactFormVal() {
    return this.contactForm.controls;
  }

  onSubmitContactForm() {
    this.submittedContactForm = true
    if (this.contactForm.invalid) {
      return;
    } else {

      console.log(this.contactForm.value)
      let formData = {
        "to":this.contactForm.controls.email.value,
        "subject":this.contactForm.controls.subject.value,
        "body":this.contactForm.controls.message.value
      }

    console.log("formData =>",formData);
    
      // this.spinner.show();
      this.apiService.emailSend(formData).pipe(first())
        .subscribe((res: any) => {

          console.log("res =>",res);
          
          // this.contactForm.reset()
          // alert('Your email has been sent, Thankyou for contacting')
          // this.router.navigate(['/dashboard'])
          // alert(res?.status)
          // this.spinner.hide();
          console.log(res, '======= resp =====');
        }, err => {
          console.log(err, '=== error list =======')
         
          // this.spinner.hide();
          this.bsService.notifyError(err, formData);
          alert('There is error at sending message')
        });
    }
      // Email.send({
      //   Host: 'smtp.elasticemail.com',
      //   Username: 'mamathakavety29@gmail.com',
      //   Password: '69D58625A9DA98DB83B660B67E11979CA295',
      //   // receive enquires on this email
      //   To: 'mamatha.k@spurtreetech.in',
      //   // email id entered by user
      //   From: this.contactForm.controls.email.value,
      //   Subject: this.contactForm.controls.subject.value,
      //   Body: this.contactForm.controls.message.value
      // }).then(message => {
      //   if (message == 'OK') {
      //     this.contactForm.reset()
      //     alert('Your email has been sent, Thankyou for contacting')
      //     this.router.navigate(['/dashboard'])
      //   } else {
      //     alert('There is error at sending message')
      //   }
      // });


      // onSubmitEmailForm() {
      //   this.submittedEmailForm = true
      //   if (this.emailForm.invalid) {
      //     return
      //   } else {
      //     console.log(this.emailForm.value)
      //     let formData = {
      //       "email": this.emailForm.value.email
      //     }
    
      //     pinner.show();
      //     this.apiService.addUser(formData).pipe(first())
      //       .subscribe((res: any) => {
      //         // this.successBtn = true;
      //         // this.initialBtn = false;
      //         alert(res?.status)
      //         this.spinner.hide();
      //         this.router.navigate(['site-switcher'])
      //         console.log(res, '======= resp =====');
      //       }, err => {
      //         console.log(err, '=== error list =======')
      //         this.spinner.hide();
      //         this.bsService.notifyError(err, formData);
      //       });
      //   }
      // }

    }
  }

