<div class="pageHeader row mx-0 px30">
  <div
    class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
  >
    <a>
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>Scan</h1>
  </div>
</div>
<section class="globalContainer">
  <div class="px20">
    <div class="row">
      <div class="col-12 question">
        <p>Where did you buy the products from?</p>
      </div>
      <div class="col-12">
        <select (change)="selectOption($event.target.value)" class="select-store browser-default custom-select">
          <option value="" selected disabled>Select store type</option>
          <option>lol</option>
          <option>lmao</option>
          <option>ddtt</option>
        </select>
      </div>
      <div class="col-12">
        <input class="select-store" placeholder="Type to search store name"/>
      </div>
      <div class="col-12">
        <select (change)="selectOption($event.target.value)" class="select-store browser-default custom-select">
          <option value="" selected disabled>Select region</option>
          <option>lol</option>
          <option>lmao</option>
          <option>ddtt</option>
        </select>
      </div>

      <div class="col-12 button-container">
        <button class="next-button"> Next</button>
      </div>
    </div>
  </div>
</section>

<!-- <app-similac-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="currentBrand"
  ></app-similac-menu> -->
