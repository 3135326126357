import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { BugsnagService } from "src/app/services/bugsnag.service";

@Component({
  selector: 'app-eligible-ensure-gold',
  templateUrl: './eligible-ensure-gold.component.html',
  styleUrls: ['./eligible-ensure-gold.component.scss']
})
export class EligibleEnsureGoldComponent implements OnInit {
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  rewardInfo: any;
  count = 1;
  rewardVisited: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {}

  ngOnInit(): void {
    console.log(this.route.snapshot.params);
    this.rewardInfo = this.route.snapshot.params;
    console.log(this.rewardInfo);
    this.count = parseInt(this.rewardInfo.quantity);
    this.getCustomer();
    this.rewardVisited = true;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;
          // Block the user
          let block = res["customers"]["customer"][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == "block_count";
          });
          console.log(blockUser, "blockUser===");
          if (blockUser[0]?.value == "1") {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(["/block-notice"]);
          }
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }
}

