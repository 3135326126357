<app-header title="PediaSure<sup>®</sup>   range"></app-header>
<section class="globalContainer bg-lightBlue">
  <div class="product-detail-page px15">
    <div class="bannner-section">
      <div class="imgSection">
        <img style="    position: relative;
        top: 20px;" src="../../../assets/images/prodimg/4.png" />
      </div>
      <div class="titleSection v-top">
        <h2 class="fs20 color-yellow mb-0">PediaSure<sup>®</sup>   GOLD Ready-to-Drink</h2>
        <span class="fs13">1 - 10 years old</span>
      </div>
    </div>
    <div class="product-detail-container bg-white btl20 btr20 pt-4 pb70">
      <div class="content-card">
        <h4 class="fs20 font-darkblue mt30 mb-3">
          Complete, balanced nutrition on-the-go!
        </h4>
        <p class="mb-3">
          PediaSure<sup>®</sup>'s Triple Sure System contains 34 essential nutrients,
          scientifically designed to address nutritional needs of growing
          children to achieve full growth and development potential.
        </p>
        <p>
          Never miss a growing moment with PediaSure<sup>®</sup>  . PediaSure<sup>®</sup>   RTD can be used
          as a snack or a nutritional supplement with or in between meals for a
          complete and balanced nutrition.
        </p>
        <p>
          For children with picky eating behaviours, sub-optimal growth, poor
          appetite, and increased nutrient needs.
        </p>
        <h4 class="fs20 font-darkblue mt-5 mb-3">Product information</h4>
        <p class="mb-3">PediaSure<sup>®</sup>  Gold’s Triple Sure System</p>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img
              src="../../../assets/images/Bone-muscle-development_tcm150-91130.jpg"
              alt="Growth"
            />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Bone Development</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >Calcium, vitamins D and K for development of strong bones.
                </span>
              </li>
              <!-- <li>
                <span
                  >Provides at least 50% of daily requirements for key nutrients
                  to support growth</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img
              src="../../../assets/images/Immunity-strength_tcm150-91132.jpg"
              alt="Growth"
            />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Immunity Support</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >Vitamins A, B6, B12, C, iron, zinc and selenium for normal
                  immune function.</span
                >
              </li>
              <!-- <li>
                <span
                  >100% daily requirement of Vitamin D; supports Calcium
                  absorption & improves bone strength</span
                >
              </li>
              <li>
                <span
                  >Triple Protein Complex (Soy, Whey & Casein); 25% more
                  high-quality protein that provides essential amino acids to
                  support muscle tissue building and growth</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="row mb-3 imgIco">
          <div class="col-3">
            <img src="../../../assets/images/growth-3.png" alt="Growth" />
          </div>
          <div class="col-9 pl-0">
            <h5 class="fs16 font-darkblue mb-3">Muscle Development</h5>
            <ul class="disc-inside pl-0">
              <li>
                <span
                  >Protein provides amino acids needed for tissue building and
                  growth.
                </span>
              </li>
              <li>
                <span
                  >Vitamin D for maintenance of normal muscle function.</span
                >
              </li>
              <!-- <li>
                <span
                  >Vitamins A, B6, B12, C, Iron, Zinc & Selenium for normal
                  immune function</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <h4 class="fs20 font-darkblue mt-5 mb-4">Nutritional Table</h4>
        <div class="table-section">
          <table class="nutrition-table mb-4">
            <thead>
              <tr>
                <th>Nutrient</th>
                <th>Per 100ml</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Energy(Kcal)</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Protein (g)</td>
                <td>3.00</td>
              </tr>
              <tr>
                <td>Fat (g)</td>
                <td>3.93</td>
              </tr>
              <!-- <tr>
                <td class="pl-4">Saturated fatty acids (g)</td>
                <td>2.3</td>
              </tr>
              <tr>
                <td class="pl-4">Polyunsaturated fatty acids (g)</td>
                <td>5.15</td>
              </tr> -->
              <tr>
                <td class="pl45">Linoleic acid (mg)</td>
                <td>900</td>
              </tr>
              <tr>
                <td class="pl45">α-Linolenic acid (mg)</td>
                <td>90</td>
              </tr>
              <tr>
                <td class="pl45">DHA (mg)</td>
                <td>4.2</td>
              </tr>
              <tr>
                <td class="pl45">ARA (mg)</td>
                <td>1.4</td>
              </tr>

              <tr>
                <td>Carbohydrate (g)</td>
                <td>13.25</td>
              </tr>
              <!-- <tr>
                <td class="pl-4">Total sugar (g)</td>
                <td>32.8</td>
              </tr> -->
              <tr>
                <td class="pl45">Lactose (g)</td>
                <td>0.07</td>
              </tr>
              <tr>
                <td>FOS (g)</td>
                <td>0.44</td>
              </tr>
              <tr>
                <td>Water (g)</td>
                <td>85</td>
              </tr>
              <tr>
                <td>Taurine (mg)</td>
                <td>7.2</td>
              </tr>
              <tr>
                <td>Carnitine (mg)</td>
                <td>1.7</td>
              </tr>
              <!-- <tr>
                <td>Arginine (mg)</td>
                <td>1000</td>
              </tr> -->
              <tr>
                <td>Inositol (mg)</td>
                <td>8</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Vitamins</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vitamin A (IU)</td>
                <td>200</td>
              </tr>
              <tr>
                <td>Vitamin D3 (IU)</td>
                <td>80</td>
              </tr>
              <tr>
                <td>Vitamin E (IU)</td>
                <td>2.3</td>
              </tr>
              <tr>
                <td>Vitamin K1 (mcg)</td>
                <td>5.9</td>
              </tr>
              <!-- <tr>
                <td class="pl-4">Vitamin K1 (phylloquinone) (mcg)</td>
                <td>1.36</td>
              </tr> -->
              <!-- <tr>
                <td class="pl-4">Vitamin K2 (menaquinone) (mcg)</td>
                <td>1173</td>
              </tr> -->
              <tr>
                <td>Vitamin C (ascorbic acid) (mg)</td>
                <td>10</td>
              </tr>
              <!-- <tr>
                <td>Vitamin E</td>
                <td>1173</td>
              </tr> -->
              <tr>
                <td>Folic acid (mcg)</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Vitamin B1 (thiamin) (mcg)</td>
                <td>270</td>
              </tr>
              <tr>
                <td>Vitamin B2 (riboflavin) (mcg)</td>
                <td>210</td>
              </tr>
              <tr>
                <td>Vitamin B6 (mcg)</td>
                <td>260</td>
              </tr>
              <tr>
                <td>Vitamin B12 (mcg)</td>
                <td>0.3</td>
              </tr>
              <tr>
                <td>Niacin (mg)</td>
                <td>0.82</td>
              </tr>
              <tr>
                <td>Pantothenic acid (mcg)</td>
                <td>700</td>
              </tr>
              <tr>
                <td>Biotin (mcg)</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Choline (mg)</td>
                <td>30</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Minerals</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sodium (mg)</td>
                <td>38</td>
              </tr>
              <tr>
                <td>Potassium (mg)</td>
                <td>131</td>
              </tr>
              <tr>
                <td>Chloride (mg)</td>
                <td>101</td>
              </tr>
              <tr>
                <td>Calcium (mg)</td>
                <td>96</td>
              </tr>
              <tr>
                <td>Phosphorus (mg)</td>
                <td>83</td>
              </tr>
              <tr>
                <td>Magnesium (mg)</td>
                <td>19.8</td>
              </tr>
              <tr>
                <td>Iron (mg)</td>
                <td>1.4</td>
              </tr>
              <tr>
                <td>Zinc (mg)</td>
                <td>0.67</td>
              </tr>
              <tr>
                <td>Manganese (mcg)</td>
                <td>150</td>
              </tr>

              <tr>
                <td>Copper (mcg)</td>
                <td>65</td>
              </tr>
              <tr>
                <td>Iodine (mcg)</td>
                <td>9.7</td>
              </tr>
              <tr>
                <td>Selenium (mcg)</td>
                <td>3.2</td>
              </tr>
              <tr>
                <td>Chromium (mcg)</td>
                <td>3</td>
              </tr>

              <tr>
                <td>Molybdenum (mcg)</td>
                <td>3.9</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="arrow-up-fixed">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
