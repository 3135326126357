import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-store',
  templateUrl: './select-store.component.html',
  styleUrls: ['./select-store.component.scss']
})
export class SelectStoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

   
  }

  selectOption(value: any) {
    console.log(value);
  }

}
