<div class="pageHeader row mx-0 px30">
    <div
      class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex"
    >
      <a (click)="onClickBack()">
        <i class="fa fa-angle-left"></i>
      </a>
      <h1>How Much</h1>
    </div>
  </div>
  <section class="globalContainer">

    <section class="about-glucerna min-vh-100 bg">
        
      <div class="top-heading">
            <!--<img class="why-should-i-choose" src="../../../assets/images/glucerna/DOES.png" />-->
            <span class="fs-20 fw-bold me-2">DOES</span>
            <img class="glucerna-logo" src="../../../assets/images/glucerna/glucerna-logo.svg" />
            <br>
           
   
            
            <!--<img  src="../../../assets/images/glucerna/GIVE-VALUE-FOR-MONEY.png" />-->
            <span class="fs-20 fw-bold">GIVE VALUE FOR MONEY?</span>
      </div>
      <div class="product-details d-flex mx-10">
        <div class="product-image col-7">
            <img class="vanilla-img-57" src="../../../../assets/images/glucerna/GLUCERNA-900G-VANILLA-NEW.png" />
          </div>
          <div class="product-content col-5">
            
          </div>

      </div>
      <div class="product-description d-flex">
        <div class="col-7">
            <div class="left-tilediv">
                <div class="content-div">
                    <p>For the dietary management of diabetes​</p>
                    <p>Low Glycemic Index​</p>
                    <p>Advanced blend of low glycemic carbohydrates</p>
                    <p>Dual Fiber Blend</p>
                    <p>With Omega-3 and Omega-6</p>
                    <p>4x more Inositol vs. Previous formulation</p>
                    <p>High in trivalent Chromium</p>
                    <p>35 Nutrients</p>
                    <p>Low in Lactose and Gluten-Free</p>
                </div>
                
            </div>
             
        </div>
        <div class="col-5">
            <div class="right-tilediv">
               <p>Glucerna features an advanced blend of ingredients to support the dietary management of diabetics.</p> 
            </div>
             
        </div>
      
      </div>
    </section>
    <div class="bottom-bg"></div>
  </section>
  
  <app-glucerna-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="rewardInfo.name"
></app-glucerna-menu>
  