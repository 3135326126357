<div class="menuContainer glucerna">
  <div class="menu-list">
    <a (click)="onClickTransactions()">
      <div *ngIf="transactionsVisited">
        <img src="../../../assets/images/Group 5563.svg" /><span
          style="color: #009cde"
          >Transactions</span
        >
      </div>

      <div *ngIf="!transactionsVisited">
        <img src="../../../assets/images/new/transico.png" />
        <span>Transactions</span>
      </div>
    </a>
  </div>

  <div class="menu-list">
    <a (click)="onClickScan()">
      <img src="../../../assets/images/new/scanico.png" />
    </a>
  </div>
  <div class="menu-list">
    <a (click)="onClickRewards()">
      <div *ngIf="!rewardVisited">
        <img src="../../../assets/images/gift.png" /> <span> Rewards</span>
      </div>

      <div *ngIf="rewardVisited">
        <img src="../../../assets/images/Group 5562.svg" />
        <span style="color: #009cde">Rewards</span>
      </div>
    </a>
  </div>
  <div class="bar"></div>
</div>

<!-- <div class="about-menu-container pb70">
    <div class="about-program-card text-center">
      <button
        #button
        [class.toggleBtn]="url == 'about'"
        [class.toggleBtnCollapsed]="url != 'about'"
        class="collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#about-Programme"
        aria-expanded="false"
        aria-controls="about-Programme"
      >
        <img class="closed" src="../../../assets/images/arrow-up.png" />
        <span class="closed closed mt-2 fs13 font-blue">About program</span>
        <span class="opened">
          <img class="mb-3" src="../../../assets/images/arrow-down.png" />
          <img src="../../../assets/images/rewards-blue.png" />
        </span>
      </button>
      <div class="collapse" id="about-Programme">
        <div class="about-collapse-menu">
          <ul>
            <li>
              <a href="#" [routerLink]="['/about/family']"
                >About Abbott Family Rewards
                <span><img src="../../../assets/images/arrow-right.png" /></span>
              </a>
            </li>
            <li>
              <a href="#" [routerLink]="['/about/faq']"
                >Frequently Asked Questions
                <span><img src="../../../assets/images/arrow-right.png" /></span>
              </a>
            </li>
            <li>
              <a href="#" [routerLink]="['/about/terms']"
                >Terms and Conditions
                <span><img src="../../../assets/images/arrow-right.png" /></span>
              </a>
            </li>
            <li>
              <a href="#" [routerLink]="['/about/privacy-policy']"
                >Privacy Policy
                <span><img src="../../../assets/images/arrow-right.png" /></span>
              </a>
            </li>
            <li>
              <p class="color-lightgrey fs12 mb-0">SG.2021.21900.PDS.1 (v1.0)</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div> -->

<div
  bsModal
  #notLoginOrSignup="bs-modal"
  class="modal fade customModalContainer px30 mt-30vh"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content br10 px15 py-3">
      <div class="modal-body pt-3 text-center">
        To access this feature, please log in or sign up to
        <a
          href="#"
          [routerLink]="['/about/family']"
          class="col-blue underline fW600"
        >
          Abbott Alaga Rewards</a
        >
      </div>
      <div class="text-center m-3">
        <button
          (click)="onClickLoginSignup()"
          class="primaryBtn mb-2 fW100"
          type="submit"
        >
          Log in / sign up now
        </button>
        <button
          (click)="onClickCancel()"
          class="primaryBtn btn-white fW100"
          type="submit"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
